import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Input, Select, DatePicker } from 'antd';
import { SearchButton } from 'components/common'

import styles from './index.less'
const Option = Select.Option;
const { RangePicker } = DatePicker;

const Filter = ({ exchangeStore }) => {
  const {
    exHistory,
    updateState,
    requestExHistoryList,
  } = exchangeStore;
  const {
    filter,
  } = exHistory;
  const {
    goodsName,
    startCTime,
    endCTime,
  } = filter;
  const [
    goodsNameVal,
    setGoodsNameVal,
  ] = useState(goodsName);
  const [
    startCTimeVal,
    setStartCTimeVal,
  ] = useState(startCTime);
  const [
    endCTimeVal,
    setEndCTimeVal,
  ] = useState(endCTime);
  const handleGoodsNameChange = (evt) => {
    const { value } = evt.target;
    setGoodsNameVal(value);
  }
  const handleSearch = () => {
    updateState('exHistory.pager.pageNo', 1);
    updateState('filter', Object.assign(filter, {
      goodsName: goodsNameVal,
      startCTime: startCTimeVal,
      endCTime: endCTimeVal,
    }));
    requestExHistoryList();
  }
  const classItem = [
    {
      label: '积分兑换',
      value: '积分兑换'
    },
  ]
  const renderOptions = () => {
    return classItem.map((option, optionIndex)=>{
      return (
        <Option
          key={optionIndex}
          value={option.value}
        >
          {option.label}
        </Option>
      )
    })
  }
  const changeDate = (date) => {
    setStartCTimeVal(date[0]);
    setEndCTimeVal(date[1]);
  }
  return (
    <div className={styles.wrap} >
      <Input
        className={styles.searchInput}
        placeholder="输入商品名称"
        onChange={handleGoodsNameChange}
        value={goodsNameVal}
      />
      <RangePicker
        className={styles.rangePicker}
        placeholder={['兑换开始时间', '兑换结束时间']}
        onChange={changeDate}
        value={[startCTimeVal, endCTimeVal]}
        />
      <Select
        className={styles.select}
        value="积分兑换"
      >
        {renderOptions()}
      </Select>
      <SearchButton
        onClick={handleSearch}
      />
    </div>
  );
}

export default inject('exchangeStore')(observer(Filter));
