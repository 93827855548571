import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Content,
} from 'components/common';

import {
  List,
} from 'components/org/message';

@inject('messageStore')
@observer
class Message extends React.Component {
  componentDidMount() {
    const {
      requestGetMessageList,
    } = this.props.messageStore;
    requestGetMessageList();
  }
  readMsg = (msgId) => {
    const {
      requestModifyMsgToRead,
    } = this.props.messageStore;
    requestModifyMsgToRead(msgId);
  }
  render() {
    const {
      messageListIsReady,
      messageList,
      messageError,
      messagePager,
    } = this.props.messageStore;
    const listProps = {
      loading: !messageListIsReady,
      data: messageList,
      error: messageError,
      pager: messagePager,
      onRead: this.readMsg,
    };
    return (
      <Content style={{paddingTop: 0}}>
        <List {...listProps} />
      </Content>
    );
  }
}
export default Message;
