import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';
import history from 'router/history';

import {
  Content,
} from 'components/common';
import {
  Detail,
} from 'components/org/event/common';
import {
  TitleInput,
  Editor,
  Cover,
  Footer,
} from 'components/org/event/eventPublish';

@withRouter
@inject('eventPublishStore')
@Form.create()
@observer
class EventPublish extends React.Component {
  state = {
    previewData: {},
    visibleOfPreview: false,
  }
  componentDidMount() {
    const {
      match,
    } = this.props;
    // 地址栏带有id说明是修改动态
    const { eventId } = match.params;
    if (eventId) {
      this.getEventDetail(eventId);
    }
  }
  componentWillUnmount() {
    const {
      eventPublishStore
    } = this.props;
    const {
      resetState,
    } = eventPublishStore;
    resetState();
  }
  getEventDetail = (eventId) => {
    const {
      eventPublishStore
    } = this.props;
    const {
      requestGetEventDetail,
    } = eventPublishStore;
    requestGetEventDetail(eventId);
  }
  publishEvent = (data) => {
    const {
      event,
      requestPublishEvent,
    } = this.props.eventPublishStore;
    const postData = Object.assign({
      operation: 1,
    }, event, data);
    requestPublishEvent(postData)
      .then(() => {
        this.backToList();
      });
  }
  saveEvent = (data) => {
    return new Promise((resolve, reject) => {
      const {
        event,
        updateState,
        requestSaveEvent,
      } = this.props.eventPublishStore;
      const postData = Object.assign({}, event, data);
      requestSaveEvent(postData)
        .then(({ id }) => {
          resolve(id);
          updateState('event', postData);
        })
        .catch(err => {
          reject(err);
        })
    })
  }
  previewEvent = (data) => {
    const {
      event,
    } = this.props.eventPublishStore;
    const previewData = Object.assign({}, event, data);
    this.updateDetailState(true, previewData);
  }
  updateDetailState = (visible = false, data = {}) => {
    this.setState({
      visibleOfPreview: visible,
      previewData: data,
    });
  }
  modifyEvent = (data) => {
    const {
      event,
      requestModifyEvent
    } = this.props.eventPublishStore;
    const putData = Object.assign({
      operation: 1,
    }, event, data);
    requestModifyEvent(putData)
      .then(() => {
        this.backToList();
      });
  }
  cancelModify = () => {
    this.backToList();
  }
  backToList = () => {
    history.toEventList();
  }
  render() {
    const {
      visibleOfPreview,
      previewData,
    } = this.state;
    const {
      form,
      match,
      eventPublishStore,
    } = this.props;
    const {
      eventIsReady,
      event,
    } = eventPublishStore;
    // 判断为编辑动态且动态详情还未返回时不显示界面
    const { eventId } = match.params;
    if (eventId && !eventIsReady) {
      return null;
    }
    const titleInputProps = {
      form,
      defaultValue: eventId ? event.title : '',
    }
    const editorProps = {
      form,
      defaultValue: eventId ? event.content : '',
    }
    const coverProps = {
      form,
      defaultValue: eventId ? event.imgUrls : '',
    }
    const footerProps = {
      form,
      onPublish: this.publishEvent,
      onPreview: this.previewEvent,
      onModify: this.modifyEvent,
      onCancelModify: this.cancelModify,
    };
    const detailProps = {
      visible: visibleOfPreview,
      onCancel: this.updateDetailState.bind(this, false),
      data: previewData,
    };
    return (
      <Content>
        <TitleInput {...titleInputProps} />
        <Editor {...editorProps} />
        <Cover {...coverProps} />
        <Footer {...footerProps} />
        <Detail {...detailProps} />
      </Content>
    );
  }
}
export default EventPublish;
