import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import history from 'router/history';
import { getMatchRoute } from 'utils/route';

import styles from './index.less';

function BackTitle({ location }) {
  const { pathname } = location;
  const match = getMatchRoute(pathname);
  if (!match) {
    return null;
  }
  const routeBack = (path) => {
    history.push(path);
  }
  const {
    title,
    backPath,
    backTitle,
  } = match;
  return (
    <div className={styles.wrap}>
      {/* 路由有配置返回则显示返回按钮 */}
      {backPath && (
        <span
          className={styles.backBtn}
          onClick={routeBack.bind(null, backPath)}
        >
          {backTitle}
        </span>
      )}
      {title && (
        <h1 className={styles.title}>{title}</h1>
      )}
    </div>
  );
}

export default withRouter(observer(BackTitle));
