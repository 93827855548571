import { message } from 'antd';
import initStore from 'utils/initStore';
import { activityApi } from 'api';

const ActivityPublishStore = initStore({
  state() {
    return {
      detailLoading: true,
      detail: {},
      detailError: null,

      saveLoading: false,
      auditLoading: false,
    };
  },
  actions: {
    /**
     * 获取活动信息
     * @param {*} id 活动id
     */
    requestGetDetail(id) {
      this.detailLoading = true;
      activityApi.getDetail(id)
        .then(res => {
          this.detail = res.data.data || {};
          this.detailError = null;
          this.detailLoading = false;
        })
        .catch(err => {
          console.log(err, '<===获取活动信息失败');
          this.detailError = err;
          this.detail = {};
          this.detailLoading = false;
        });
    },
    /**
     * 保存活动信息
     * @param {Object} data 活动信息
     * @return {Promise}
     */
    requestSaveActivity(data) {
      this.saveLoading = true;
      return new Promise((resolve) => {
        activityApi.saveActivity(data)
          .then(() => {
            message.success('保存成功');
            this.saveLoading = false;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===保存活动失败');
            message.error(err.response.data.msg || '保存失败');
            this.saveLoading = false;
        });
      })
    },
    /**
     * 提交审核
     * @param {Object} data 活动信息
     * @return {Promise}
     */
    requestAuditActivity(data) {
      this.auditLoading = true;
      return new Promise((resolve) => {
        activityApi.auditActivity(data)
          .then(() => {
            message.success('提交审核成功');
            this.auditLoading = false;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===提交审核失败');
            message.error(err.response.data.msg || '提交审核失败');
            this.auditLoading = false;
        });
      })
    },
  },
})

export default new ActivityPublishStore();