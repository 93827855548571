import React from 'react';
import { observer } from 'mobx-react';
import styles from './index.less'
function KeyValue({label, value, render}) {
  return (
    <div className={styles.row}>
      <div className={styles.key}>{label}</div>
      <div className={styles.value}>{render ? render(value) : value}</div>
    </div>
  )
}
export default observer(KeyValue)