import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Select, Input } from 'antd';
import { Modal } from 'components/common';
import { FormItem } from 'components/activity/common';

import styles from './index.less';

const Option  = Select.Option;
const TextArea = Input.TextArea;

function CancelAudit({form, ...props}) {
  const reasonType = [
    {label: '信息填写不完善（包括不限于手机号、微信号、昵称等填写不对 OR 填写有问题）', value: '1', desc: '【报名失败】嘿，很抱歉，由于您信息填写失误，请审核后再提交哦~'},
    {label: '必填项未填写', value: '2', desc: '【提交失败】Hi，活动报名必填项要填写全面哦~就差一步了，加油……'},
    {label: '报名人数已满，审核不通过', value: '3', desc: '【报名失败】Hi，因为场地有限，活动人数已满，下次活动再见吧~'},
    {label: '自定义问题模板', value: 'custom', desc: ''},
  ];
  const getDescByType = (value) => {
    return reasonType.find(item => item.value === value).desc;
  }
  const initialType = '1';
  const [type, setType] = useState(initialType);
  const [loading, setLoading] = useState(false);
  const handleTypeChange = (value) => {
    setType(value);
    form.setFieldsValue({
      auditRemark: getDescByType(value),
    });
  }
  const handleConfirm = () => {
    form.validateFields((errors, values) => {
      if (!errors) {
        // 自定义问题模板需要加上前缀
        if (type === 'custom') {
          values.auditRemark = "【报名失败】" + values.auditRemark.trim();
        }
        setLoading(true);
        props.onOk(values)
          .then(() => {
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    })
  }
  const handleCancel = () => {
    props.onCancel();
    // 重置数据
    handleTypeChange('1');
    setLoading(false);
  }
  const renderOptions = (options) => {
    return options.map(option => (
      <Option
        key={option.value}
        title={option.label}
        value={option.value}
      >{option.label}</Option>
    ))
  }
  const okButtonProps = Object.assign({
    loading,
  }, props.okButtonProps);
  return (
    <Modal
      width="41.25rem"
      okText="确定驳回"
      cancelText="取消"
      {...props}
      onCancel={handleCancel}
      onOk={handleConfirm}
      destroyOnClose
      okButtonProps={okButtonProps}
    >
      <h2 className={styles.title}>请编辑驳回原因</h2>
      <FormItem label="情况选择">
        <Select
          className={styles.reasonType}
          value={type}
          onChange={handleTypeChange}
        >
          {renderOptions(reasonType)}
        </Select>
      </FormItem>
      <FormItem label="话术" vertical error={form.getFieldError('auditRemark')}>
        {form.getFieldDecorator('auditRemark', {
          initialValue: getDescByType(type),
          rules: [
            {required: true, message: '话术不能为空'},
            {
              validator(rule, value) {
                if (type === 'custom') {
                  return value.length >= 10;
                }
                return true;
              },
              message: '话术不能少于10个字符',
            },
          ],
        })(
          <TextArea
            className={styles.desc}
            autoSize={false}
            maxLength="20"
            placeholder={type === 'custom' ? '请输入话术，最多20字' : ''}
            disabled={type !== 'custom'}
          />
        )}
      </FormItem>
    </Modal>
  );
}

export default Form.create()(observer(CancelAudit));
