import { message } from 'antd';
import initStore from 'utils/initStore';
import { orgApi, commonApi } from 'api';
import { getPathValue } from 'pathval';
// import history from 'router/history';

const AccountStore = initStore({
  state() {
    return {
      summary: {
        loading: false,
        error: false,
        data: {}
      },
      exStatus: {
        loading: false
      },
      applyStatus: {
        loading: false
      },
      // 添加公益金消费公示相关操作状态
      publicApply: {
        visible: false,
        loading: false
      },
      // 申请提现记录
      cashRecord: {
        loading: false,
        data: [],
        error: null,
        pager: {
          index: 1,
          size: 10,
          total: 0,
          onChange: (current) => {
            this.cashRecord.pager.index = current;
            this.requestGetCashRecord();
          },
        },
      },
      // 花费公示期数
      costPublicityPeriod: {
        loading: true,
        data: [],
        error: null,
        onChange: (id) => {
          this.costPublicityPeriod.value = id;
          this.costPublicity.pager.index = 1;
          this.requestGetCostPublicity(id);
        },
        value: '',
        pager: {
          index: 1,
          size: 10,
          total: 0,
          onChange: (current) => {
            this.costPublicityPeriod.pager.index = current;
            this.requestGetCostPublicityPeriod();
          },
        },
      },
      // 花费公示
      costPublicity: {
        loading: false,
        data: [],
        error: null,
        pager: {
          index: 1,
          size: 10,
          total: 0,
          onChange: (current) => {
            this.costPublicity.pager.index = current;
            const period = this.costPublicityPeriod.value;
            this.requestGetCostPublicity(period);
          },
        },
      },
      // 公益金明细
      welfareDetail: {
        loading: false,
        data: [],
        error: null,
        pager: {
          index: 1,
          size: 10,
          total: 0,
          onChange: (current) => {
            this.welfareDetail.pager.index = current;
            this.requestGetWelfareDetail();
          },
        },
      },
      // 咖啡豆明细
      coffeeBeanDetail: {
        loading: false,
        data: [],
        error: null,
        pager: {
          index: 1,
          size: 10,
          total: 0,
          onChange: (current) => {
            this.coffeeBeanDetail.pager.index = current;
            this.requestGetBeanDetail();
          },
        },
      },
      // 拒绝原因
      refusedReason: '',
    };
  },
  actions: {
    requestGetSummary() {
      this.summary.loading = true;
      orgApi.getSummary()
        .then(( {data} )=>{
          this.summary.data = data.data;
          this.summary.loading = false;
          this.summary.error = false;
        })
        .catch(()=>{
          this.summary.loading = false;
          this.summary.data = {};
          this.summary.error = true;
        })
    },
    // 咖啡豆兑换公益金
    requestExBeansToMoney(params) {
      this.exStatus.loading = true;
      return new Promise((resolve, reject)=>{
        orgApi.exchangeBeansToMoney(params)
        .then(()=>{
          message.success('兑换成功')
          this.exStatus.loading = false;
          resolve();
        })
        .catch((error)=>{
          this.exStatus.loading = false;
          const msg = getPathValue(error, 'response.data.msg') || '兑换失败！'
          message.error(msg)
          reject(error);
        })
      });
    },
    // 获取收款信息
    requestGetRecipientInfo() {
      return orgApi.getRecipientInfo();
    },
    // 保存收款信息
    requestSaveRecipientInfo(data) {
      return orgApi.saveRecipientInfo(data);
    },
    // 公益金提现
    requestApplyMoney(params) {
      this.applyStatus.loading = true;
      return new Promise((resolve, reject) => {
        orgApi.drawApplay(params)
          .then(()=>{
            message.success('公益金兑换成功！')
            this.applyStatus.loading = false;
            this.requestGetSummary();
            resolve();
          })
          .catch((error)=>{
            this.applyStatus.loading = false;
            const msg = getPathValue(error, 'response.data.msg') || '公益金失败！'
            message.error(msg)
            reject(error);
          });
      });
    },
    // 上传图片
    requestUploadImg(file, type) {
      const formData = new FormData();
      formData.append('upfile', file);
      return new Promise((resolve, reject) => {
        commonApi.uploadImg(formData, type)
          .then(({data})=>{
            if (data.data.viewPath) {
              resolve(data.data.viewPath);
            } else {
              message.error('上传图片失败!');
              reject(new Error('上传图片失败!'));
            }
          })
          .catch((error)=>{
            const msg = getPathValue(error, 'response.data.msg') || '上传图片失败!';
            message.error(msg);
            reject(error);
          });
      });
    },
    // 添加公益金消费公示
    requestAddPublicApply(params) {
      this.publicApply.loading = true;
      return new Promise((resolve, reject) => {
        orgApi.addPublicApply(params)
          .then(()=>{
            message.success('公益金消费公示添加成功');
            this.requestGetCostPublicity(this.costPublicityPeriod.value);
            this.publicApply.loading = false;
            resolve();
          })
          .catch((error)=>{
            const msg = getPathValue(error, 'response.data.msg') || '公益金消费公示添加失败!';
            this.publicApply.loading = false;
            message.error(msg);
            reject(error);
          });
      });
    },
    // 打开添加公益金消费公示弹窗
    openPublicApplyModal() {
      this.publicApply.visible = true;
    },
    // 关闭添加公益金消费公示弹窗
    closePublicApplyModal() {
      this.publicApply.visible = false;
    },
    // 获取提现记录
    requestGetCashRecord() {
      const {
        index,
        size,
      } = this.cashRecord.pager;
      const params = {
        pageNo: index,
        pageSize: size,
        type: 1,
      };
      this.cashRecord.loading = true;
      orgApi.getCashRecord(params)
        .then(resp => {
          this.cashRecord.loading = false;
          this.cashRecord.data = resp.data.data;
          this.cashRecord.error = null;
          this.cashRecord.pager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取提现记录失败');
          this.cashRecord.loading = false;
          this.cashRecord.data = [];
          this.cashRecord.error = err;
        });
    },
    // 获取提现记录拒绝原因
    requestCashRefusedReason(cashId) {
      return new Promise((resolve, reject) => {
        orgApi.getCashRefusedReason(cashId)
          .then(resp => {
            this.refusedReason = resp.data.data.msg;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===获取提现记录拒绝原因失败');
            const msg = err.response.data.msg || '获取提现记录拒绝原因失败';
            message.error(msg);
            reject(err);
          });
      });
    },
    // 获取花费公示期数
    requestGetCostPublicityPeriod() {
      const {
        index,
        size,
      } = this.costPublicityPeriod.pager;
      const params = {
        pageNo: index,
        pageSize: size,
        type: 2,
      };
      this.costPublicityPeriod.loading = true;
      return new Promise((resolve, reject) => {
        orgApi.getCashRecord(params)
          .then(resp => {
            const { data } = this.costPublicityPeriod;
            this.costPublicityPeriod.loading = false;
            this.costPublicityPeriod.data = data.concat(resp.data.data);
            this.costPublicityPeriod.error = null;
            this.costPublicityPeriod.pager.total = resp.data.total;
            resolve(resp.data.data);
          })
          .catch(err => {
            console.log(err, '<===获取花费公示期数失败');
            this.costPublicityPeriod.loading = false;
            this.costPublicityPeriod.error = err;
            reject(err);
          })
      });
    },
    // 获取花费公示
    requestGetCostPublicity(id) {
      this.costPublicityPeriod.value = id;
      const {
        index,
        size,
      } = this.costPublicity.pager;
      const params = {
        pageNo: index,
        pageSize: size,
        id,
      };
      this.costPublicity.loading = true;
      orgApi.getCostPublicity(params)
        .then(resp => {
          this.costPublicity.loading = false;
          this.costPublicity.data = resp.data.data;
          this.costPublicity.error = null;
          this.costPublicity.pager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取提现记录失败');
          this.costPublicity.loading = false;
          this.costPublicity.data = [];
          this.costPublicity.error = err;
        });
    },
    // 获取花费公示拒绝原因
    requestCostRefusedReason(costId) {
      return new Promise((resolve, reject) => {
        orgApi.getCostRefusedReason(costId)
          .then(resp => {
            this.refusedReason = resp.data.data.msg;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===获取花费公示拒绝原因失败');
            const msg = err.response.data.msg || '获取花费公示拒绝原因失败';
            message.error(msg);
            reject(err);
          });
      });
    },
    // 获取公益金明细
    requestGetWelfareDetail() {
      const {
        index,
        size,
      } = this.welfareDetail.pager;
      const params = {
        pageNo: index,
        pageSize: size,
      };
      this.welfareDetail.loading = true;
      orgApi.getWelfareDetail(params)
        .then(resp => {
          const dict = resp.data.dict || {};
          const userInfos = dict.userInfos || [];
          this.welfareDetail.loading = false;
          this.welfareDetail.data = resp.data.data.map(
            item => Object.assign(
              item,
              userInfos.find(
                user => user.id === item.userId
              ),
            ),
          );
          this.welfareDetail.error = null;
          this.welfareDetail.pager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取提现记录失败');
          this.welfareDetail.loading = false;
          this.welfareDetail.data = [];
          this.welfareDetail.error = err;
        });
    },
    // 获取咖啡豆明细
    requestGetBeanDetail() {
      const {
        index,
        size,
      } = this.coffeeBeanDetail.pager;
      const params = {
        pageNo: index,
        pageSize: size,
      };
      this.coffeeBeanDetail.loading = true;
      orgApi.getBeanDetail(params)
        .then(resp => {
          const dict = resp.data.dict || {};
          const userInfos = dict.userInfos || [];
          this.coffeeBeanDetail.loading = false;
          this.coffeeBeanDetail.data = resp.data.data.map(
            item => Object.assign(
              item,
              userInfos.find(
                user => user.id === item.userId
              ),
            ),
          );
          this.coffeeBeanDetail.error = null;
          this.coffeeBeanDetail.pager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取提现记录失败');
          this.coffeeBeanDetail.loading = false;
          this.coffeeBeanDetail.data = [];
          this.coffeeBeanDetail.error = err;
        });
    },
  },
});

export default new AccountStore();
