import React from 'react';
import { inject, observer } from 'mobx-react';
import { routeRequiresLogin } from 'utils/app';

/**
 * mapPropsToData返回字段说明
 * @prop {string} pathname 当前路由
 */

/**
 * 校验登录，如果是需要登录的路由，则需要先获取到用户信息再渲染页面
 * @param {func} mapPropsToData 从props中获取数据
 */
function checkLogin(mapPropsToData) {
  return (WrappedComponent) => {
    class CheckLoginHOC extends React.Component {
      componentDidMount() {
        // 每次重新加载需要登录的页面必须先获取用户信息
        const {
          requestGetUserInfo,
        } = this.props.loginStore;
        if (this.shouldCheckLogin()) {
          requestGetUserInfo();
        }
      }
      // 判断当前路由是否需要检查登录
      shouldCheckLogin() {
        const {
          pathname,
        } = mapPropsToData(this.props);
        return routeRequiresLogin(pathname);
      }
      render() {
        const { userInfoIsReady } = this.props.loginStore;
        const shouldCheckLogin = this.shouldCheckLogin();
        // 不需要登录的页面直接渲染
        // 需要登录的页面先判断是否已登录，若已登录则渲染页面
        if (!shouldCheckLogin || userInfoIsReady) {
          return <WrappedComponent {...this.props} />
        }
        return null;
      }
    }
    return inject('loginStore')(observer(CheckLoginHOC));
  }
}

export default checkLogin;
