
import axios from 'axios';
// 爱心订单-列表
export const getLoveOrder = (params) =>
  axios.get(`/api/v2/shop/order/searchLoveOrder`, { params });
// 爱心订单-详情
export const getLoveOrderDetail = (params) =>
  axios.get(`/api/v2/shop/order/loveOrderDetail`, { params });

// 兑换列表
export const getGoodsList = () =>
  axios.get(`/api/v2/shop/exchange/exchangeable`);

// ngo兑换咖啡券
export const ngoExchangeCoupon = (params) =>
axios.post(`/api/v2/shop/exchange/ngoExchangeCoupon`, params);

// 兑换咖啡券历史明细
export const getExchangeHistory= (params) =>
  axios.post(`/api/v2/shop/exchange/exchangeHistory`, params);

// 在售商品列表
export const getGoodsStock= (params) =>
  axios.post(`/api/v2/shop/exchange/queryStock`,  params);

// 商品上架下架
export const upOrDownGoods = (params) =>
axios.put(`/api/v2/shop/exchange/upOrDown`, params);
