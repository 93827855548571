import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import { UPLOAD_URL_PREFIX } from 'config/upload';
import defaultAvatar from 'imgs/org/orgInfo/defauHeadImg.png';
import styles from './index.less';

function Detail({ data, ...modalProps }) {
  let {
    newsType,
    content,
  } = data;
  // 纯图文章数据为json串，需要格式化为富文本
  if (newsType === 4) {
    const jsonContent = JSON.parse(content) || [];
    content = jsonContent.map(item => {
      return `<div class=${styles.imgContentRow}><img src=${item.img}><p>${item.content}</p></div>`;
    }).join('');
  }
  return (
    <Modal
      width="41.25rem"
      footer={null}
      {...modalProps}
      destroyOnClose
    >
      <h3 className={styles.title}>{data.title}</h3>
      <div className={styles.info}>
        <div className={styles.left}>
          <img
            className={styles.avatar}
            src={data.userAvatarUrl ? `${UPLOAD_URL_PREFIX.MEMBER_HEAD_PREFIX}${data.userAvatarUrl}` : defaultAvatar} 
            alt=""
          />
          <span className={styles.name}>{data.sourceUsername}</span>
          <span className={styles.icon}></span>
        </div>
        <span className={styles.time}>{data.sourcePublishTime}</span>
      </div>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{__html: content}}
      />
    </Modal>
  );
}

export default observer(Detail);
