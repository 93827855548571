import React from 'react';
import { observer } from 'mobx-react';

import {
  Tab as CommonTab
} from 'components/common';

import styles from './index.less';

function Tab({ onChange, value }) {
  const options = [
    {label: '申请提现记录', value: 'CASH'},
    {label: '花费公示', value: 'COST'},
    {label: '公益金明细', value: 'WELFARE'},
    {label: '咖啡豆明细', value: 'BEAN'},
  ];
  return (
    <div className={styles.wrap}>
      <CommonTab
        options={options}
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export default observer(Tab);
