import React from 'react';
import classnames from 'classnames';
import Upload from '../Upload';
import { Button } from 'antd';
import * as commonApi from 'api/common';
import success_icon from 'imgs/common/upload_success.png';
import fail_icon from 'imgs/common/upload_fail.png';

import styles from './index.less';

export default class ImageUpload extends React.Component {
  static defaultProps = {
    tips: [
      {text: '支持JPG/PNG格式，', type: 'normal'},
      {text: '建议尺寸1:1，大小不要超过1M', type: 'normal'},
      {text: '图片底色不要纯白色，否则影响美观', type: 'warn'},
    ],
    preview: null,
    pathMode: 'absolute',
    onSuccess() {},
    onFail() {},
    onRemove() {
      return Promise.resolve();
    },
  };

  static initialState = (context) => ({
    // 上传状态 initial|empty|process|success|fail
    status: context.props.preview ? 'initial' : 'empty',
    previewUrl: context.props.preview || null,
    process: 0,
    successTipIsVisible: false,
    removeDisabled: false,
  })

  state = ImageUpload.initialState(this);
  processITimer = null;
  successTTimer = null;

  componentWillUnmount() {
    this.clear();
  }
  
  // 在这里做清理工作
  clear() {
    clearInterval(this.processITimer);
    clearTimeout(this.successTTimer);
  }

  // 重置
  reset() {
    this.setState(ImageUpload.initialState(this));
  }

  uploadToServer = (files) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('upfile', files[0]);
      const { uploadType } = this.props;
      commonApi.uploadImg(formData, uploadType)
        .then(res => {
          const { viewPath, path } = res.data.data;
          const imageUrl = this.props.pathMode === 'absolute' ? viewPath : path;
          resolve(imageUrl);
        })
        .catch(err => {
          reject((err.response && err.response.data) || err);
        });
    });
  }

  onUpload = (files) => {
    // 更改上传状态
    this.updateStatus('process');
    // 展示预览图片
    this.setPreview(files);
    // 显示上传进度
    this.showProcess();
    // 上传到服务器
    this.uploadToServer(files)
      .then(url => {
        // 更改上传状态
        this.updateStatus('success');
        // 进度条完成
        this.finishProcess();
        // 显示上传成功提示
        this.showSuccessTip(2500);
        this.props.onSuccess(url);
      })
      .catch(err => {
        // 更改上传状态
        this.updateStatus('fail');
        // 移除进度条
        this.clearProcess();
        this.props.onFail(err);
      });
  }

  // 展示预览图片
  setPreview(files) {
    const previewReader = new FileReader();
    previewReader.readAsDataURL(files[0]);
    previewReader.onload = (evt) => {
      this.updatePreview(evt.target.result);
    }
  }

  updateStatus(val) {
    this.setState({
      status: val,
    });
  }

  updateProcess(val) {
    this.setState({
      process: val,
    });
  }

  showProcess() {
    this.processITimer = setInterval(() => {
      const { process } = this.state;
      if (process < 90) {
        this.updateProcess(process + 1);
      }
    }, 1000 / 16);
  }

  finishProcess() {
    this.updateProcess(100);
    clearInterval(this.processITimer);
  }

  clearProcess() {
    this.updateProcess(0);
    clearInterval(this.processITimer);
  }

  /**
   * 显示成功提示
   * @param {number} duration 多少毫秒后被关闭
   */
  showSuccessTip(duration) {
    this.setState({successTipIsVisible: true});
    this.successTTimer = setTimeout(() => {
      this.setState({successTipIsVisible: false});
    }, duration);
  }

  updatePreview(img) {
    this.setState({
      previewUrl: img,
    })
  }
  /**
   * 删除图片
   */
  remove = () => {
    // 禁用删除按钮
    this.setState({removeDisabled: true});
    this.props.onRemove()
      .then(() => {
        this.clear();
        this.reset();
        this.setState({removeDisabled: false});
      })
      .catch(() => {
        this.setState({removeDisabled: false});
      });
  }

  render() {
    const { status, previewUrl, process, successTipIsVisible, removeDisabled } = this.state;
    const { tips, tipClassName } = this.props;
    const commonUploadConfig = {
      accept: 'image/*',
      onChange: this.onUpload,
    };
    return (
      <Upload
        {...commonUploadConfig}
        disabled={status !== 'empty'}>
        <div style={{backgroundImage: `url(${previewUrl})`}} className={styles.wrapper}>
          {/* 初始状态 */}
          <div
            className={classnames(styles.emptyBox, {
              [styles.show]: status === 'empty',
              [styles.hide]: status !== 'empty',
            })}>
            <span className={styles.icon}>点击添加封面</span>
            {tips.map((tip, tipIndex) => {
              return (
                <span
                  className={classnames(styles.tipText, tipClassName, {
                    [styles.normal]: !tip.type || tip.type === 'normal',
                    [styles.warn]: tip.type === 'warn',
                  })}
                  key={tipIndex}>
                  {tip.text}
                </span>
              );
            })}
          </div>
          {/* 上传中 */}
          <div
            style={{height: `${process}%`}}
            className={classnames(styles.processBox, {
              [styles.show]: status === 'process',
              [styles.hide]: status !== 'process',
            })}
          />
          {/* 上传成功 */}
          <span
            className={classnames(styles.successBox, {
              [styles.show]: successTipIsVisible,
              [styles.hide]: !successTipIsVisible,
            })}>
            <img src={success_icon} alt="" />
            <span>上传成功</span>
          </span>
          {/* 上传失败 */}
          <span
            className={classnames(styles.failBox, {
              [styles.show]: status === 'fail',
              [styles.hide]: status !== 'fail',
            })}>
            <img src={fail_icon} alt="" />
            <span>上传失败，请重新上传</span>
            <Upload
              {...commonUploadConfig}
              disabled={status !== 'fail'}>
              <Button>重新上传</Button>
            </Upload>
          </span>
          {/* 删除按钮 */}
          <span
            className={classnames(styles.removeIcon, {
              [styles.hide]: !['success', 'initial'].includes(status),
              [styles.disabled]: removeDisabled,
            })}
            onClick={this.remove}
          ></span>
        </div>
      </Upload>
    );
  }
}
