
import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';

import { List as CommonList } from 'components/common';

import { UPLOAD_URL_PREFIX } from 'config/upload';

import styles from './index.less'

const List = ({ exchangeStore }) => {
  const {
    exHistory,
    updateState,
    requestExHistoryList,
  } = exchangeStore;
  const {
    filter,
    list,
    pager,
  } = exHistory;
  const {
    loading,
    data,
    error
  } = list;
  const {
    pageNo,
    pageSize,
    total,
  } = pager;
  const renderGoodsInfo = (value, record) => {
    return (
      <div className={styles.goods}>
        <img src={value[0].couponImg ? `${UPLOAD_URL_PREFIX.COUPON_PREFIX}${value[0].couponImg}` : null} alt="" />
        <span className={styles.goodsName}>{value[0].couponName}</span>
      </div>
    );
  }
  const renderPrice = (value, record) => {
    return (
      <div>{record.item[0].price}</div>
    );
  }
  const listProps = {
    rowKey: "orderId",
    columns: [
      {
        key: 'item',
        dataIndex: 'item',
        title: '商品',
        width: '25%',
        render: renderGoodsInfo
      },
      {
        key: 'price',
        dataIndex: 'price',
        title: '单价',
        width: '10%',
        render: renderPrice
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: '兑换数量',
        width: '10%',
      },
      {
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        title: '总价',
        width: '10%',
      },
      {
        key: 'ctime',
        dataIndex: 'ctime',
        title: '兑换时间',
        width: '10%'
      }
    ],
    loading,
    params: Object.values(filter).some(val => val),
    data,
    error,
    onChange: (pagination) => {
      updateState('exHistory.pager.pageNo', pagination.current);
      requestExHistoryList();
    },
    pagination: {
      current: pageNo,
      pageSize: pageSize,
      total: total,
      showQuickJumper: true,
    },
  }
  useEffect(()=>{
    requestExHistoryList();
  }, [requestExHistoryList])
  return (
    <div className={styles.table}>
      <CommonList {...listProps}/>
    </div>
  );
}

export default inject('exchangeStore')(observer(List));
