import React from 'react';
import { observer } from 'mobx-react';
import { List } from 'components/common';
import { State } from 'components/activity/common';
import {
  getTypeText,
  getRangeTimeText,
  getAddressText,
}  from 'components/activity/common/utils';

import styles from './index.less';

function Table(props) {
  const {actionRender, ...restProps} = props;
  const tableProps = {
    columns: [
      {
        key: 'no',
        title: '序号',
        width: '8%',
        renderType: 'index',
      },
      {
        key: 'type',
        title: '活动类型',
        dataIndex: 'type',
        width: '10%',
        render(value) {
          return getTypeText(value);
        },
      },
      {
        key: 'title',
        title: '活动名称',
        dataIndex: 'title',
        width: '16%',
      },
      {
        key: 'time',
        title: '活动时间',
        dataIndex: 'time',
        width: '18%',
        render(value, record) {
          return getRangeTimeText(record);
        },
      },
      {
        key: 'addr',
        title: '活动地址',
        dataIndex: 'addr',
        width: '20%',
        render(value, record) {
          return getAddressText(record);
        }
      },
      {
        key: 'state',
        title: '活动状态',
        dataIndex: 'state',
        width: '10%',
        render(value) {
          return <State value={value} />;
        },
      },
      {
        key: 'operation',
        title: '操作',
        width: '20%',
        render: actionRender,
      },
    ],
    rowKey: 'id',
    actionAlign: 'right',
    ...restProps,
  };
  return (
    <div className={styles.wrapper}>
      <List {...tableProps} />
    </div>
  );
}

export default observer(Table);