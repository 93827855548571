import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from './index.less';

function ControlItem({ className, children, label, ...restProps }) {
  return (
    <div
      className={classnames(styles.wrap, className)}
      {...restProps}
    >
      {label && <span className={styles.label}>{label}：</span>}
      {children}
    </div>
  );
}

export default observer(ControlItem);
