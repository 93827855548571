import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Input, message } from 'antd';

import { Modal } from 'components/common';
import { thousandsSplit } from 'utils/common';

import styles from './index.less';

const ApplyModal = ({ visible,  balance, onCancel, loading, onOk }) => {
  const [money, setMoney] = useState();
  const numPat = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
  const changeMoney = (evt) => {
    let { value } = evt.target;
    if (!value || numPat.test(value)) {
      if (value > balance) {
        value = balance;
      }
      evt.target.value = value;
      setMoney(value);
    }
  }
  const confirmApply = () => {
    if (!money) {
      return message.error('请输入需要提现的金额');
    }
    onOk({
      amount: Number(money),
    }).then(() => {
      closeModal();
    });
  }
  const closeModal = () => {
    setMoney('');
    onCancel();
  }
  return (
    <Modal
      visible={visible}
      title="申请提现金额"
      width="41.25rem"
      okText="确认提现"
      onCancel={closeModal}
      onOk={confirmApply}
      okButtonProps={{ loading } }>
      <div className={styles.box}>
        <label>提现金额</label>
        <Input
          value={money}
          onChange={changeMoney}
          placeholder="请输入需要提现的金额"
        />
        <div className={styles.tip}>
          最多可提现<span>{thousandsSplit(balance)}元</span>
        </div>
        <hr  className={styles.hr}/>
      </div>
    </Modal>
  );
}

export default observer(ApplyModal);
