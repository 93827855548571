import React from 'react';
import { observer } from 'mobx-react';
import { Button, Icon } from 'antd';

import { thousandsSplit } from 'utils/common';
import {
  List as CommonList,
  OperateBtn,
} from 'components/common';
import Statistics from './Statistics';

import styles from './index.less';

function CostPublicity({
  statistics,
  loading,
  data,
  error,
  pager = {},
  onAdd = () => {},
  onViewRefusedReason,
}) {
  const statusMap = {
    0: '审核被拒绝',
    1: '审核中',
    2: '已公示',
  };
  const renderTitle = () => {
    return (
      <div className={styles.titleWrap}>
        <Button
          className={styles.secondaryBtn}
          onClick={onAdd}
        >
          <Icon
            className={styles.plusIcon}
            type="plus-circle"
          />
          <span>添加一笔花费</span>
        </Button>
        <Statistics data={statistics} />
      </div>
    );
  }
  const renderAmount = (val) => {
    return `¥${thousandsSplit(val)}`;
  }
  const renderStatus = (val) => {
    return <span className={styles[`status_${val}`]}>{statusMap[val] || '审核中'}</span>;
  }
  const handleViewRefusedReason = (record) => {
    onViewRefusedReason(record.id);
  }
  const renderOperate = (val, record) => {
    return (
      <React.Fragment>
        <OperateBtn
          show={record.state === 0}
          text="查看拒绝原因"
          onClick={handleViewRefusedReason.bind(null, record)}
        />
      </React.Fragment>
    );
  }
  const listProps = {
    title: renderTitle,
    columns: [
      {
        key: 'event',
        title: '事件',
        dataIndex: 'event',
        width: '15%',
      },
      {
        key: 'eventTime',
        title: '产生时间',
        dataIndex: 'eventTime',
        width: '15%',
      },
      {
        key: 'spend',
        title: '花费金额',
        dataIndex: 'spend',
        width: '27%',
        render: renderAmount,
      },
      {
        key: 'state',
        title: '状态',
        dataIndex: 'state',
        width: '13%',
        render: renderStatus,
      },
      {
        key: 'operate',
        title: '操作',
        dataIndex: 'operate',
        width: '15%',
        render: renderOperate,
      },
    ],
    rowKey: 'id',
    loading,
    dataSource: data,
    error,
    pager,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(CostPublicity);
