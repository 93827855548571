import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Button } from 'antd';

import styles from './index.less';

function Period({ loading, data, pager, onChange, value }) {
  if (data.length === 0) {
    return null;
  }
  const {
    index,
    size,
    total,
  } = pager;
  const getDate = (val) => {
    if (val) {
      return val.slice(0, 10);
    }
    return '';
  }
  const handlePeriodChange = (period) => {
    if (period !== value) {
      onChange(period);
    }
  }
  const handlePageChange = () => {
    pager.onChange(index + 1);
  }
  const isCurrent = (itemIndex) => index === 1 && itemIndex === 0;
  const renderItems = () => {
    return data.map((item, itemIndex) => {
      const { id, periods } = item;
      return (
        <div
          key={itemIndex}
          onClick={handlePeriodChange.bind(null, id)}
          className={classnames(styles.item, {
            [styles.active]: id === value,
          })}
        >
          {isCurrent(itemIndex) ? '本期' : (
            <React.Fragment>
              <span className={styles.period}>第{periods}期</span>
              <span className={styles.time}>{getDate(item.startTime)}~{getDate(item.endTime)}</span>
            </React.Fragment>
          )}
        </div>
      );
    });
  }
  const isLastPage = index * size >= total;
  return (
    <div className={styles.wrap}>
      <div className={styles.list}>
        {renderItems()}
        {!isLastPage && (
          <Button
            className={styles.moreBtn}
            onClick={handlePageChange}
            loading={loading}
          >
            查看更多期
          </Button>
        )}
      </div>
    </div>
  );
}

export default observer(Period);
