import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import styles from './index.less';

function CancelAudit(props) {
  return (
    <Modal
      width="41.25rem"
      okText="确定取消"
      cancelText="关闭"
      {...props}
      destroyOnClose
    >
      <div className={styles.icon} />
      <h2 className={styles.title}>确定取消审核？</h2>
      <p className={styles.text}>取消审核后文章会变为取消审核状态，确定要取消审核吗？</p>
      <hr className={styles.horHr} />
    </Modal>
  );
}

export default observer(CancelAudit);
