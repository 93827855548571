import React from 'react';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { message } from 'antd';

import history from 'router/history';
import BackTitle from './BackTitle';

import { UPLOAD_URL_PREFIX } from 'config/upload';
import defauHeadImg from 'imgs/org/orgInfo/defauHeadImg.png';
import styles from './index.less';

class AppHeader extends React.Component {
  msgIntervalTimer = null;
  componentDidMount() {
    const {
      requestGetUnreadMsgCount,
    } = this.props.messageStore;
    requestGetUnreadMsgCount();
    this.msgIntervalTimer = setInterval(() => {
      requestGetUnreadMsgCount();
    }, 5000);
  }
  componentWillUnmount() {
    clearInterval(this.msgIntervalTimer);
  }
  handleLogout = () => {
    const {
      requestLogout,
    } = this.props.loginStore;
    requestLogout()
      .then(() => {
        history.toLoginPage();
      })
      .catch(() => {
        message.error('退出失败');
      });
  }
  routeToMessage = () => {
    history.toMessagePage();
  }
  render() {
    const {
      loginStore,
      messageStore,
    } = this.props;
    const {
      userInfo,
    } = loginStore;
    const {
      unreadMsgCount,
    } = messageStore;
    return (
      <div className={styles.wrap}>
        <div className={styles.left}>
          <BackTitle />
        </div>
        <div className={styles.right}>
          <span
            className={classnames(styles.notice, {
              [styles.unread]: unreadMsgCount > 0,
            })}
            onClick={this.routeToMessage}
          />
          <div className={styles.user}>
            <img
              className={styles.avatar}
              src={userInfo.avatarUrl ? `${UPLOAD_URL_PREFIX.MEMBER_HEAD_PREFIX}${userInfo.avatarUrl}` : defauHeadImg}
              alt=""
            />
            <span className={styles.name}>
              {userInfo.username}
            </span>
          </div>
          <span
            className={styles.logout}
            onClick={this.handleLogout}
          >
            退出登录
          </span>
        </div>
      </div>
    );
  }
}

export default inject('messageStore', 'loginStore')(observer(AppHeader));
