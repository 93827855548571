import React from 'react'
import { Modal } from 'components/common';
import { State } from 'components/live/onlineLive/common';
import { UPLOAD_URL_PREFIX } from 'config/upload';
import {
  convertS2H,
} from '../utils';

import styles from './index.less';

export default function DetailModal({ onCancel, visible, data }) {
  console.log(data)
  if (!data) {
    return null;
  }
  const items = [
    {name: '直播名', dataIndex: 'liveName'},
    {
      name: '直播封面',
      dataIndex: 'cover',
      render(value) {
        return <img className={styles.cover} alt="" src={`${UPLOAD_URL_PREFIX.LIVE}${value}`} />;
      },
    },
    {
      name: '直播状态',
      dataIndex: 'state',
      render(value) {
        return <State value={value} />;
      }
    },
    {name: '主播昵称', dataIndex: 'nickname'},
    {name: '机构名称', dataIndex: 'ngoName'},
    {name: '观看人数', dataIndex: 'watchs'},
    {name: '点赞人数', dataIndex: 'enjoys'},
    {name: '推流时长', dataIndex: 'pushTime', render: convertS2H},
    {name: '直播时长', dataIndex: 'planTime', render: convertS2H},
    {name: '平均停留时间', dataIndex: 'stayAvgTime', render: convertS2H},
  ];
  return (
    <Modal
      width="41.25rem"
      visible={visible}
      onCancel={onCancel}
      footer={null}>
      <h2 className={styles.title}>直播详情</h2>
      <div className={styles.content}>
        {items.map(item => (
          <div key={item.dataIndex} className={styles.item}>
            <span className={styles.name}>{item.name}</span>
            <span className={styles.value}>{item.render ? item.render(data[item.dataIndex]) : data[item.dataIndex]}</span>
          </div>
        ))}
      </div>
    </Modal>
  )
}
