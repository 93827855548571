import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal } from 'components/common'
import { Input, message, Form, DatePicker } from 'antd';
import UploadImg from './UploadImg'
import styles from './index.less';
import moment from 'moment';

const PublicApplyModal = ({ form, accountStore }) => {
  const { getFieldDecorator, getFieldValue } = form;
  // 花费的输入框自己控制，因为要控制只能输入金额
  // 保存上传的图片
  const [imgs, setImgs] = useState([]);
  const eventValue = getFieldValue('event') || '';
  const {
    requestUploadImg,
    requestAddPublicApply,
    publicApply,
    closePublicApplyModal
  } = accountStore;
  const addImg = (file) => {
    requestUploadImg(file, 31)
      .then((url)=>{
        imgs.push(url);
        setImgs([...imgs]);
      });
  }
  const delImg = (idx) => {
    imgs.splice(idx, 1);
    setImgs([...imgs]);
  }
  const getParams = (values) => {
    values.eventTime = moment(values.eventTime).format('YYYY-MM-DD HH:mm:ss');
    return {
      ...values,
      billUrls: imgs.toString(),
    }
  }
  const confirmSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        if (imgs.length < 1) {
          message.error('请至少上传一张票据照片');
        }　else {
          const params = getParams(values);
          requestAddPublicApply(params)
            .then(() => {
              closeModal();
            });
        }
      }
    });
  }
  const resetStatus = () => {
    setImgs([]);
    form.resetFields();
  }
  const closeModal = () => {
    closePublicApplyModal();
    resetStatus();
  }
  return (
    <Modal
      visible={publicApply.visible}
      title="添加花费公示"
      text="事件标题描述文字不要超过30个字符"
      width="41.25rem"
      okText="上传"
      onCancel={closeModal}
      onOk={confirmSubmit}
      okButtonProps={{ loading: publicApply.loading } }
    >
      <div　className={styles.box}>
      <div className={styles.eventBox}>
        <Form.Item label="事件">
          {getFieldDecorator('event', {
            rules: [
              {
                required: true,
                message: '请输入标题描述',
              }
            ]
          })(
            <Input
              placeholder="请输入标题描述"
              autoComplete="false"
              maxLength={30}
            />
          )}
        </Form.Item>
        <label className={styles.tip}>{eventValue.length}/30</label>
        </div>
        <div　className={styles.formRow}>
          <Form.Item　label="产生时间">
            {getFieldDecorator('eventTime', {
              rules: [
                {
                  required: true,
                  message: '请选择时间',
                }
              ]
            })(
              <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                placeholder="请选择时间"
              />
            )}
          </Form.Item>
          <Form.Item label="票据金额" required>
            {getFieldDecorator('spend', {
              rules: [
                {required: true, message: '票据金额必填'},
                {pattern: /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/, message: '必须为数字'},
              ],
            })(
              <Input
                placeholder="请输入票据金额"
                autoComplete="false"
              />
            )}
          </Form.Item>
        </div>
        <UploadImg
          delImg={delImg}
          addImg={addImg}
          imgs={imgs}
        />
        <hr className={styles.hr} />
      </div>
    </Modal>
  );
}

export default Form.create({name: 'publicApply'})(observer(PublicApplyModal));
