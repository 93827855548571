import moment from 'moment';

// 活动状态
const ACTIVITY_STATE_TEXT = {
  0: '未提交',
  1: '取消审核', // 取消审核
  2: '审核中',
  3: '驳回',
  4: '审核通过',
  5: '进行中',
  6: '已结束',
};

// 报名审核状态
const ACTIVITY_REGISTER_STATE_TEXT = {
  0: '取消审核',
  1: '待审核',
  2: '审核未通过',
  3: '审核通过',
}

// 活动类型
const ACTIVITY_TYPE_TEXT = {
  OTHER: '其他',
  VOLUNTEER: '志愿者招募类',
  ON_LINE_CLS: '线上课程类',
  OFF_LINE_SHARE: '线下分享类',
};

// 处理活动状态
export const getStateText = (state) => {
  return ACTIVITY_STATE_TEXT[state] || '';
}

// 处理活动类型
export const getTypeText = (type) => {
  return ACTIVITY_TYPE_TEXT[type] || '';
}

// 格式化时间格式
export const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return '/';
  }
  return moment(timestamp * 1000).format('YYYY-MM-DD');
}

// 处理活动时间
export const getRangeTimeText = (record) => {
  return `${formatTimestamp(record.startTime)} 至 ${formatTimestamp(record.expireTime)}`;
}

// 处理活动地址
export const getAddressText = (record) => {
  return `${record.province}${record.city}${record.district}${record.street}`;
}

// 处理报名审核状态
export const getRegisterStateText = (auditState) => {
  return ACTIVITY_REGISTER_STATE_TEXT[auditState];
}