import React, { Component } from 'react';
import { Content } from 'components/common';
import { observer, inject } from 'mobx-react';
import {
  OrgBody
} from 'components/org/orgInfo'

@inject('orgInfoStore')
@observer
class OrgInfo extends Component {
  componentDidMount() {
    this.props.orgInfoStore.requestMyInfo()
  }
  render() {
    return (
      <Content>
        <OrgBody />
      </Content>
    );
  }
}
export default OrgInfo;