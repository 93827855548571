import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Content,
} from 'components/common';

import {
  List,
} from 'components/org/faq';

@inject('faqStore')
@observer
class FAQ extends React.Component {
  componentDidMount() {
    const {
      requestGetFaqList,
    } = this.props.faqStore;
    requestGetFaqList();
  }
  render() {
    const {
      faqListIsReady,
      faqList,
      faqError,
      faqPager,
    } = this.props.faqStore;
    const listProps = {
      loading: !faqListIsReady,
      data: faqList,
      error: faqError,
      pager: faqPager,
    };
    return (
      <Content style={{paddingTop: 0}}>
        <List {...listProps} />
      </Content>
    );
  }
}
export default FAQ;
