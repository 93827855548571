/*订单详情的商品列表*/
import React from 'react';
import { observer } from 'mobx-react';

import { List as CommonList } from 'components/common';
import KeyValue from '../common/KeyValue';
import { modifyMoney } from 'utils/common'

import styles from './index.less';

function Goods({ loading, data, error }) {
  const listProps = {
    rowKey: "orderId",
    columns: [
      {
        key: 'couponName',
        dataIndex: 'couponName',
        title: '商品',
        width: '15%',
      },
      {
        key: 'price',
        dataIndex: 'price',
        title: '单价',
        width: '10%',
        renderType: 'money',
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: '数量',
        width: '10%',
      },
      {
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        title: '支付金',
        width: '10%',
        render: () => modifyMoney(data.totalPrice)
      },
      {
        key: 'realIncome',
        dataIndex: 'realIncome',
        title: '实收',
        width: '10%',
        render: () => modifyMoney(data.realIncome)
      },
      {
        key: 'rate',
        dataIndex: 'rate',
        title: '费率',
        width: '15%',
        render: () => data.rate
      }
    ],
    loading,
    data: data.item,
    error,
    pagination: false,
  }
  return (
    <div className={styles.goodsBox}>
      <div className={styles.info}>
        <KeyValue
          className={styles.orderId}
          label="订单编号"
          value={data.orderId}
        />
        <KeyValue
          label="购买时间"
          value={data.ctime}
        />
      </div>
      <div className={styles.table}>
        <CommonList {...listProps}/>
      </div>
    </div>
  )
}
export default observer(Goods)
