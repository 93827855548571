import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Form } from 'antd';
import history from 'router/history';

import {
  Content,
} from 'components/common';
import {
  Detail,
} from 'components/article/common';
import {
  TitleInput,
  Editor,
  ContentType,
  Cover,
  Footer,
} from 'components/article/articlePublish';

@withRouter
@inject('articlePublishStore', 'loginStore')
@Form.create()
@observer
class ArticleMixinPublish extends React.Component {
  state = {
    previewData: {},
    visibleOfPreview: false,
    publishIsReady: true,
  }
  componentDidMount() {
    const {
      match,
    } = this.props;
    // 地址栏带有id说明是修改文章
    const { articleId } = match.params;
    if (articleId) {
      this.getArticleDetail(articleId);
    }
  }
  componentWillUnmount() {
    const {
      articlePublishStore
    } = this.props;
    const {
      resetState,
    } = articlePublishStore;
    resetState();
  }
  getArticleDetail = (articleId) => {
    const {
      articlePublishStore
    } = this.props;
    const {
      requestGetArticleDetail,
    } = articlePublishStore;
    requestGetArticleDetail(articleId);
  }
  publishArticle = (data) => {
    const {
      article,
      requestPublishArticle,
    } = this.props.articlePublishStore;
    const postData = Object.assign({}, article, data);
    // 需要先保存再发表
    this.updatePublishIsReady(false);
    this.saveArticle(postData, true)
      .then(id => {
        requestPublishArticle(id)
          .then(() => {
            this.backToList();
            this.updatePublishIsReady(true);
          })
          .catch(() => {
            this.updatePublishIsReady(true);
          });
      })
      .catch(() => {
        this.updatePublishIsReady(true);
      });
  }
  updatePublishIsReady = (val) => {
    this.setState({
      publishIsReady: val,
    });
  }
  saveArticle = (data, silent) => {
    return new Promise((resolve, reject) => {
      const {
        article,
        updateState,
        requestSaveArticle,
      } = this.props.articlePublishStore;
      const postData = Object.assign({}, article, data);
      requestSaveArticle(postData, silent)
        .then(({ id }) => {
          resolve(id);
          updateState('article', postData);
        })
        .catch(err => {
          reject(err);
        })
    })
  }
  previewArticle = (data) => {
    const {
      article,
    } = this.props.articlePublishStore;
    const previewData = Object.assign({}, article, data);
    this.updateDetailState(true, previewData);
  }
  updateDetailState = (visible = false, data = {}) => {
    this.setState({
      visibleOfPreview: visible,
      previewData: data,
    });
  }
  modifyArticle = (data) => {
    const {
      article,
      requestModifyArticle
    } = this.props.articlePublishStore;
    const putData = Object.assign({}, article, data);
    requestModifyArticle(putData)
      .then(() => {
        this.backToList();
      });
  }
  backToList = () => {
    history.toArticleList();
  }
  render() {
    const {
      visibleOfPreview,
      previewData,
      publishIsReady,
    } = this.state;
    const {
      form,
      match,
      articlePublishStore,
      loginStore,
    } = this.props;
    const {
      articleIsReady,
      article,
    } = articlePublishStore;
    const {
      isOfficialAuthor,
    } = loginStore.userInfo;
    // 判断为编辑文章且文章数据还未返回时不显示界面
    const { articleId } = match.params;
    if (articleId && !articleIsReady) {
      return null;
    }
    const titleInputProps = {
      form,
      defaultValue: articleId ? article.title : '',
    }
    const editorProps = {
      form,
      defaultValue: articleId ? article.content : '',
    }
    const contentTypeProps = {
      form,
      defaultValue: articleId ? article.isOrigin : true,
      contentSource: article.contentSource,
      contentAuthor: article.contentAuthor,
      contentPublishTime: article.contentPublishTime,
      contentUrl: article.contentUrl,
    }
    const coverProps = {
      form,
      defaultValue: articleId ? article.coverImages : [],
      multi: false, // v1.2关闭封面数量选择
      // 官方作者封面比例为3:4其他为3:2
      ratio: {x: 3, y: !isOfficialAuthor ? 4 : 2},
    }
    const footerProps = {
      form,
      publishIsReady,
      article,
      onPublish: this.publishArticle,
      onSave: this.saveArticle,
      onPreview: this.previewArticle,
      onModify: this.modifyArticle,
    };
    const detailProps = {
      visible: visibleOfPreview,
      onCancel: this.updateDetailState.bind(this, false),
      data: previewData,
    };
    return (
      <Content>
        <TitleInput {...titleInputProps} />
        <Editor {...editorProps} />
        <ContentType {...contentTypeProps} />
        <Cover {...coverProps} />
        <Footer {...footerProps} />
        <Detail {...detailProps} />
      </Content>
    );
  }
}
export default ArticleMixinPublish;
