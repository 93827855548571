import React from 'react';
import { observer } from 'mobx-react';
import { Input, Select, DatePicker } from 'antd';
import moment from 'moment';

import ControlItem from './ControlItem';
import BtnGroup from './BtnGroup';

import styles from './index.less';

const { Option } = Select;
const { RangePicker } = DatePicker;

// 数据格式转换方法
const convertUtils = {
  split: (value) => value.split(','),
};

class Filter extends React.Component {
  static defaultProps = {
    controls: [],
    onChange: () => {},
    onSearch: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: this.getInitialFormData(),
    };
  }

  /**
   * 获取表单数据（供外部通过ref调用）
   */
  getParams = () => {
    return this.createFormData(this.state.formData);
  }
  /**
   * 初始化表单值
   * @return {void}
   */
  getInitialFormData = () => {
    const { controls } = this.props;
    const formData = {};
    controls.forEach(control => {
      formData[control.name] = control.defaultValue;
    });
    return formData;
  }

  /**
   * 设置表单值
   * @param {String} fieldName 字段名
   * @param {any} fieldValue 字段值
   * @return {void}
   */
  setFieldValue = (fieldName, fieldValue) => {
    const newFormData = Object.assign({}, this.state.formData, {
      [fieldName]: fieldValue,
    });
    this.setState({
      formData: newFormData,
    });
  }
  /**
   * 输入框输入事件
   * @param {Object} control 控件配置项
   * @param {Event} event 输入框输入事件
   * @return {void}
   */
  handleInputChange = (control, event) => {
    const name = control.name;
    const value = event.target.value;
    this.setFieldValue(name, value);
  }
  /**
   * 下拉框选项变更事件
   * @param {Object} control 控件配置项
   * @param {any} value 选中的值
   * @return {void}
   */
  handleSelectChange = (control, value) => {
    const name = control.name;
    this.setFieldValue(name, value);
  }
  /**
   * 事件返回选择器选项变更事件
   * @param {Object} control 控件配置项
   * @param {any} value 选中的值
   * @return {void}
   */
  handleTimeRangeChange = (control, value) => {
    const name = control.name;
    const formatter = 'YYYY-MM-DD HH:mm:ss';
    let [startTime, endTime] = value || [];
    startTime = startTime ? startTime.format(formatter) : null;
    endTime = endTime ? endTime.format(formatter) : null;
    const values = startTime || endTime ? [startTime, endTime] : undefined;
    this.setFieldValue(name, values);
  }
  /**
   * 生成筛选参数
   */
  createFormData = (data) => {
    const source = JSON.parse(JSON.stringify(data));
    this.convertFormData(source);
    return source;
  }
  /**
   * 转换数据
   * convert支持返回DELETE_FIELD，用以删除该字段
   */
  convertFormData = (data) => {
    this.props.controls.forEach(control => {
      const { name, convert } = control;
      if (data[name] !== undefined && convert) {
        switch(typeof convert) {
          case 'string':
            const convertFunc = convertUtils[convert];
            if (convertFunc) {
              data[name] = convertFunc(data[name], data);
            }
            break;
          case 'function':
            const result = convert(data[name], data);
            if (result === 'DELETE_FIELD') {
              delete data[name];
            } else {
              data[name] = result;
            }
            break;
          default:
            data[name] = convert;
        }
      }
    })
  }
  /**
   * 点击搜索
   */
  handleSearch = () => {
    const formData = this.createFormData(this.state.formData);
    this.props.onSearch(formData, 'search');
  }
  /**
   * 点击刷新
   */
  handleRefresh = () => {
    const formData = this.createFormData(this.state.formData);
    this.props.onSearch(formData, 'refresh');
  }
  /**
   * 点击重置
   */
  handleReset = () => {
    const initialFormData = this.getInitialFormData();
    this.setState({
      formData: initialFormData,
    });
    const formData = this.createFormData(initialFormData);
    this.props.onSearch(formData, 'reset');
  }
  /**
   * 转换时间格式
   */
  formatTimeRange = (time) => {
    let [startTime, endTime] = time || [];
    startTime = startTime ? moment(startTime) : null;
    endTime = endTime ? moment(endTime) : null;
    return [startTime, endTime];
  }
  // 渲染下拉选项
  renderOptions = (options) => {
    return options.map((option, optionIndex) => {
      return (
        <Option
          key={optionIndex}
          value={option.value}
        >
          {option.label}
        </Option>
      );
    });
  }
  // 渲染表单控件
  renderControls = (controls) => {
    const { formData } = this.state;
    return controls.map(control => {
      switch(control.type) {
        // 输入框
        case 'input':
          return (
            <ControlItem key={control.name} label={control.label}>
              <Input
                className={styles.searchInput}
                allowClear
                value={formData[control.name]}
                placeholder={control.placeholder}
                onChange={this.handleInputChange.bind(this, control)}
              />
            </ControlItem>
          );
        // 下拉选择
        case 'select':
          return (
            <ControlItem key={control.name} label={control.label}>
              <Select
                className={styles.select}
                allowClear
                value={formData[control.name]}
                placeholder={control.placeholder}
                onChange={this.handleSelectChange.bind(this, control)}
              >
                {this.renderOptions(control.options)}
              </Select>
            </ControlItem>
          );
        // 时间范围选择
        case 'rangeTime':
          return (
            <ControlItem key={control.name} label={control.label}>
              <RangePicker
                className={styles.datePicker}
                allowClear
                showTime
                value={this.formatTimeRange(formData[control.name])}
                placeholder={control.placeholder}
                onChange={this.handleTimeRangeChange.bind(this, control)}
              />
            </ControlItem>
          );
        default:
          return null;
      }
    })
  }
  render() {
    // 按钮位置可以选择bottom和right
    const { controls, btnPlacement = 'bottom' } = this.props;
    const btnGroup = (
      <BtnGroup
        onSearch={this.handleSearch}
        onRefresh={this.handleRefresh}
        onReset={this.handleReset}
      />
    );
    return (
      <div className={styles.form}>
        <div className={styles.controlGroup}>
          {this.renderControls(controls)}
          {btnPlacement === 'right' && btnGroup}
        </div>
        {btnPlacement === 'bottom' && btnGroup}
      </div>
    );
  }
}

export default observer(Filter);
