import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import styles from './index.less';

export default class Clipper extends PureComponent {
  state = {
    crop: {
      unit: '%',
      // width: 100,
      height: 100,
      aspect: this.props.aspect,
    },
  };

  imgExt = 'png';
  clippedResult = null;

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imgExt = image.src.match(/image\/(.*?);/)[1];
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    if (crop.width < this.props.minSize) {
      return false;
    }
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(
        this.imageRef,
        crop,
      ).then(file => {
        this.clippedResult = new File([file], `clip.${this.imgExt}`, {type: file.type});
      });
    }
  }

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  render() {
    const { crop } = this.state;
    const { src } = this.props;

    return (
      <div className={styles.clipper}>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
      </div>
    );
  }
}

Clipper.defaultProps = {
  minSize: 10,
  aspect: 4 / 3,
}
