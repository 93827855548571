import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';
import { UPLOAD_URL_PREFIX } from 'config/upload';
import orgIcon from 'imgs/activity/preview/org_icon.png';

import styles from './index.less';

function Preview({ visible, onCancel, dataSource, orgInfo }) {
  const typeDict = {
    VOLUNTEER: '志愿者招募类',
    ON_LINE_CLS: '线上课程类',
    OFF_LINE_SHARE: '线下分享类',
    OTHER: '其他',
  };
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered>
      {dataSource && <div className={styles.wrapper}>
        <h2 className={styles.title}>活动预览</h2>
        <div className={styles['ignore2rem-mobile']}>
          <img className={styles.actCover} src={UPLOAD_URL_PREFIX.ACTIVITY + dataSource.cover} alt="" />
          <h3 className={styles.actTitle}>{dataSource.title}</h3>
          <div className={styles.actInfo}>
            <div className={styles.flexBetween}>
              <span className={styles.actType}>{typeDict[dataSource.type]}</span>
              <span className={styles.actTotalLook}>浏览量 0</span>
            </div>
            <div className={styles.flexBetween}>
              <span className={styles.actTime}>{dataSource.startTime.slice(0, 10)} 至 {dataSource.expireTime.slice(0, 10)}</span>
              <span className={styles.actCalendar}>加入日历</span>
            </div>
          </div>
          <div className={styles.orgBox}>
            <div className={styles.orgInfo}>
              <div className={styles.orgHeader}>
                <div className={styles.orgAvatar}>
                  <img src={UPLOAD_URL_PREFIX.MEMBER_HEAD_PREFIX + orgInfo.avatarUrl} alt="" />
                  <img className={styles.orgIcon} src={orgIcon} alt="" />
                </div>
                <div className={styles.orgRight}>
                  <div className={styles.orgName}>{orgInfo.username}</div>
                  <div className={styles.orgExtra}>
                    <span className={styles.orgFans}>
                      粉丝
                      <span>{orgInfo.fans || 0}</span>
                    </span>
                    <span className={styles.orgLine}></span>
                    <span className={styles.orgActNum}>
                      活动
                      <span>{orgInfo.activities || 0}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.orgWatch}>+关注</div>
              {orgInfo.introduce && <div className={styles.orgDesc}>
                {orgInfo.introduce}
              </div>}
            </div>
          </div>
          <h3 className={styles.actConTitle}>活动内容</h3>
          <div className={styles.actContent} dangerouslySetInnerHTML={{__html: dataSource.content}}></div>
        </div>
      </div>}
    </Modal>
  )
}

export default observer(Preview);
