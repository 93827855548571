import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Input, Select } from 'antd';

import { SearchButton } from 'components/common'
import styles from './index.less'

const Option = Select.Option;

const Filter = ({ orderStore }) => {
  const {
    filter,
    updateState,
    requestLoveOrder,
  } = orderStore;
  const {
    goodsName,
    orderNo,
    startPrice,
    endPrice,
  } = filter;
  const [
    goodsNameVal,
    setGoodsNameVal,
  ] = useState(goodsName);
  const [
    orderNoVal,
    setOrderNoVal,
  ] = useState(orderNo);
  const [
    startPriceVal,
    setStartPriceVal,
  ] = useState(startPrice);
  const [
    endPriceVal,
    setEndPriceVal,
  ] = useState(endPrice);
  const handleGoodsNameChange = (evt) => {
    const { value } = evt.target;
    setGoodsNameVal(value);
  }
  const handleOrderNoChange = (evt) => {
    const { value } = evt.target;
    setOrderNoVal(value);
  }
  const handleStartPriceChange = (evt) => {
    const { value } = evt.target;
    setStartPriceVal(value);
  }
  const handleEndPriceChange = (evt) => {
    const { value } = evt.target;
    setEndPriceVal(value);
  }
  const handleSearch = () => {
    updateState('filter', Object.assign(filter, {
      goodsName: goodsNameVal,
      orderNo: orderNoVal,
      startPrice: startPriceVal,
      endPrice: endPriceVal,
    }))
    updateState('pager.pageNo', 1);
    requestLoveOrder();
  }
  const classItem = [
    {
      label: '积分兑换',
      value: '积分兑换',
    },
  ]
  const renderOptions = () => {
    return classItem.map((option, optionIndex)=>{
      return (
        <Option
          key={optionIndex}
          value={option.value}
        >
          {option.label}
        </Option>
      )
    })
  }
  return (
    <div className={styles.wrap} >
      <Input
        className={styles.searchInput}
        placeholder="输入商品名称"
        onChange={handleGoodsNameChange}
        value={goodsNameVal}
      />
      <Input
        className={styles.searchInput}
        placeholder="输入订单编号"
        onChange={handleOrderNoChange}
        value={orderNoVal}
      />
      <div className={styles.priceBox}>
        <Input
          className={styles.searchInput}
          placeholder="价格起"
          onChange={handleStartPriceChange}
          value={startPriceVal}
        />
        <span>至</span>
        <Input
          className={styles.searchInput}
          placeholder="价格至"
          onChange={handleEndPriceChange}
          value={endPriceVal}
        />
      </div>
      {/* 只做展示，不用传值给后端 */}
      <Select
        className={styles.select}
        value="积分兑换"
      >
        {renderOptions()}
      </Select>
      <SearchButton
        onClick={handleSearch}
      />
    </div>
  );
}

export default inject('orderStore')(observer(Filter));
