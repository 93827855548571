import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Form, Checkbox, Input } from 'antd';

import { Modal } from 'components/common';

import styles from './index.less';

function AddNotice({ form, onOk, ...restProps }) {
  const {
    getFieldDecorator,
    getFieldValue,
    getFieldError,
    validateFields,
  } = form;
  const content = getFieldValue('content') || '';
  const handleOk = () => {
    validateFields((errors, values) => {
      if (!errors) {
        onOk({
          title: '公告', // 暂时写死
          contents: values.content,
          atOnce: values.atOnce,
        });
      }
    });
  }
  return (
    <Modal
      width="41.25rem"
      okText="创建"
      onOk={handleOk}
      cancelText="关闭"
      {...restProps}
      destroyOnClose
    >
      <div className={styles.infoBox}>
        <h3>添加公告</h3>
        <p>公告内容最多不能超过200个字符</p>
      </div>
      {getFieldDecorator(
        'content',
        {
          rules: [
            {required: true, message: '内容不能为空'},
          ],
        }
      )(
      <Input.TextArea
        className={classnames(styles.textArea, {
          [styles.error]: getFieldError('content'),
        })}
        maxLength={200}
      />)}
      <div className={styles.tipBox}>
        {getFieldDecorator(
          'atOnce',
          {
            initialValue: false,
            valuePropName: 'checked',
          }
        )(<Checkbox>立即生效</Checkbox>)}
        <span>{content.length}/200</span>
      </div>
    </Modal>
  );
}

export default Form.create()(observer(AddNotice));
