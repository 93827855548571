import React from 'react';
import { observer, inject } from 'mobx-react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import appConfig from 'config/app';
import { routeRequiresNavbar } from 'utils/app';
import { checkLogin } from 'components/hoc';
import {
  AppNavbar,
  AppMain,
  AppHeader,
  AppContent,
  ICP,
} from 'components/app';

import routes from 'router/routes';

import './index.less';

@withRouter
@inject('loginStore')
@checkLogin(props => {
  const { location } = props;
  return {
    pathname: location.pathname,
  };
})
@observer
class App extends React.Component {
  // 渲染路由组件
  renderRoute() {
    const { checkAuth } = this.props.loginStore;
    return routes.map((route, routeIndex) => {
      const {
        path,
        authIds,
        exact,
        component: Comp,
      } = route;
      const noAuth = authIds && !authIds.some(authId => checkAuth(authId));
      return (
        <Route
          key={routeIndex}
          path={path}
          exact={exact}
        >
          {path === '*' || noAuth ? (
            <Redirect to={appConfig.homepageUrl} />
          ) : (
            <Comp {...this.props} />
          )}
        </Route>
      );
    });
  }
  render() {
    const { pathname } = this.props.location;
    // 匹配不需要导航的页面
    if (!routeRequiresNavbar(pathname)) {
      return (
        <React.Fragment>
          <Switch>
            {this.renderRoute()}
          </Switch>
          <ICP />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <AppNavbar {...this.props} />
        <AppMain>
          <AppHeader />
          {/* 路由 */}
          <AppContent>
            <div>
              <Switch>
                {this.renderRoute()}
              </Switch>
            </div>
            <ICP />
          </AppContent>
        </AppMain>
      </React.Fragment>
    );
  }
}

export default App;
