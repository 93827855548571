import React from 'react';
import { observer } from 'mobx-react';
import { Input, Form, Row, Col } from 'antd';
import { Modal } from 'components/common';

import styles from './index.less';

function RecipientModal({ form, visible, onCancel, dataSource, loading, onOk }) {
  const {
    getFieldDecorator,
  } = form;
  const handleOk = () => {
    form.validateFields((errors, values) => {
      if (!errors) {
        onOk(values);
      }
    });
  }
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      cancelText="关闭"
      onOk={handleOk}
      centered
      okButtonProps={{ loading } }>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>收款信息</h2>
        <Form layout="vertical">
          <Form.Item label="收款方信息">
            {getFieldDecorator('payee', {
              initialValue: dataSource.payee,
              rules: [
                { required: true, message: '收款方信息不能为空' },
              ],
            })(
              <Input placeholder="请输入收款人姓名" />
            )}
          </Form.Item>
          <Row gutter={43}>
            <Col span={12}>
              <Form.Item label="开户银行">
                {getFieldDecorator('openBank', {
                  initialValue: dataSource.openBank,
                  rules: [
                    { required: true, message: '开户银行不能为空' },
                  ],
                })(
                  <Input placeholder="请输入开户银行" />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="银行卡号信息">
                {getFieldDecorator('bankNum', {
                  initialValue: dataSource.bankNum,
                  rules: [
                    { required: true, message: '银行卡号不能为空' },
                  ],
                })(
                  <Input placeholder="请输入银行卡卡号" />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}

export default observer(Form.create({})(RecipientModal));
