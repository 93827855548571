import React from 'react';
import { inject, observer } from 'mobx-react';

import history from 'router/history';
import {
  Content,
} from 'components/common';
import {
  Detail,
} from 'components/org/event/common';
import {
  Filter,
  List,
  CancelAudit,
  RefusedReason,
  Offline,
  Delete,
} from 'components/org/event/eventManage';

import {
  EVENT_PUBLISH_STATUS,
  EVENT_AUDIT_STATUS,
} from 'api/enum';

@inject('eventManageStore', 'eventPublishStore')
@observer
class EventManage extends React.Component {
  state = {
    // 详情弹框
    visibleOfDetail: false,
    // 取消审核弹框
    visibleOfCancelAudit: false,
    // 拒绝原因弹窗
    visibleOfRefusedReason: false,
    // 下线弹框
    visibleOfOffline: false,
    // 删除弹框
    visibleOfDelete: false,
    // 是否是批量操作
    batch: false,
  }
  componentDidMount() {
    const {
      eventManageStore
    } = this.props;
    const {
      requestGetEventList,
    } = eventManageStore;
    // 获取动态列表
    requestGetEventList();
  }
  // 勾选动态,checked=true则勾选,否则移除
  onCheck = (checked, record) => {
    const {
      checkedEvents,
      updateState,
    } = this.props.eventManageStore;
    let newCheckedEvents;
    if (checked) {
      newCheckedEvents = [...checkedEvents, record];
    } else {
      newCheckedEvents = checkedEvents.filter(item => item.id !== record.id);
    }
    updateState('checkedEvents', newCheckedEvents);
  }
  // 判断删除的动态是否已勾选,若已勾选,需要从勾选列表移除
  removeDelEventFromCheck = (event) => {
    const {
      checkedEvents,
    } = this.props.eventManageStore;
    const isChecked = checkedEvents.some(item => item.id === event.id);
    if (isChecked) {
      this.onCheck(false, event);
    }
  }
  // 更新store中当前操作的动态
  updateEventInStore = (event) => {
    const {
      updateState,
    } = this.props.eventManageStore;
    updateState('currOptEvent', event);
  }
  // 更新查看详情弹框
  updateDetailState = (visible = false) => {
    this.setState({
      visibleOfDetail: visible,
    });
  }
  // 发表
  handlePublish = (data) => {
    const {
      requestPublishEvent,
    } = this.props.eventPublishStore;
    requestPublishEvent(Object.assign({operation: 1}, data))
      .then(() => {
        this.refreshEventList();
      });
  }
  // 点击查看详情
  handleViewDetail = (event) => {
    const {
      requestGetEventDetail,
    } = this.props.eventManageStore;
    const { id } = event;
    requestGetEventDetail(id)
      .then(() => {
        this.updateDetailState(true);
      });
  }
  // 更新取消审核弹框
  updateCancelAuditState = (visible = false) => {
    this.setState({
      visibleOfCancelAudit: visible,
    });
  }
  // 点击取消审核
  handleCancelAudit = (event) => {
    this.updateEventInStore(event);
    this.updateCancelAuditState(true);
  }
  // 动态取消审核
  cancelAuditEvent = () => {
    const {
      filterParams,
      currOptEvent,
      requestCancelAudit,
    } = this.props.eventManageStore;
    const { id } = currOptEvent;
    requestCancelAudit(id)
      .then(() => {
        this.updateCancelAuditState();
        this.updateEventInStore(null);
        // 如果tab筛选状态为已发表,列表页码需要重新计算
        const effectNum = (
          filterParams.auditState === EVENT_AUDIT_STATUS.key('审核中')
        ) ? 1 : 0;
        this.refreshEventList(effectNum);
      });
  }
  // 编辑动态
  handleModify = (event) => {
    const {
      id,
    } = event;
    history.push(`/org/event/modify/${id}`);
  }
  // 更新拒绝原因弹框
  updateRefusedReasonState = (visible = false) => {
    this.setState({
      visibleOfRefusedReason: visible,
    });
  }
  // 点击查看拒绝原因
  handleViewReason = (event) => {
    const {
      requestGetRefusedReason,
    } = this.props.eventManageStore;
    const { id } = event;
    requestGetRefusedReason(id)
      .then(() => {
        this.updateRefusedReasonState(true);
      });
  }
  // 更新下线弹框状态
  updateOfflineState = (visible = false) => {
    this.setState({
      visibleOfOffline: visible,
    });
  }
  // 点击下线
  handleOffline = (event) => {
    this.updateEventInStore(event);
    this.updateOfflineState(true);
  }
  // 动态下线
  offlineEvent = () => {
    const {
      filterParams,
      currOptEvent,
      requestOfflineEvent,
    } = this.props.eventManageStore;
    const { id } = currOptEvent;
    requestOfflineEvent(id)
      .then(() => {
        this.updateOfflineState();
        this.updateEventInStore(null);
        // 如果tab筛选状态为已发表,列表页码需要重新计算
        const effectNum = (
          filterParams.publishState === EVENT_PUBLISH_STATUS.key('已发表')
        ) ? 1 : 0;
        this.refreshEventList(effectNum);
      });
  }
  // 更新删除弹框状态
  updateDeleteState = (visible = false, batch = false) => {
    this.setState({
      visibleOfDelete: visible,
      batch,
    });
  }
  // 点击单个删除
  handleSingleDelete = (event) => {
    this.updateEventInStore(event);
    this.updateDeleteState(true);
  }
  // 点击批量删除
  handleBatchDelete = (event) => {
    this.updateEventInStore(event);
    this.updateDeleteState(true, true);
  }
  // 动态删除
  deleteEvent = () => {
    const {
      currOptEvent,
      checkedEvents,
      updateState,
      requestDeleteEvent,
    } = this.props.eventManageStore;
    const {
      batch,
    } = this.state;
    const delIds = [];
    // 不管是单个删除还是批量删除都调用批量删除接口
    if (batch) {
      checkedEvents.forEach(event => delIds.push(event.id));
    } else {
      delIds.push(currOptEvent.id);
    }
    requestDeleteEvent(delIds)
      .then(() => {
        // 关闭弹框
        this.updateDeleteState();
        if (!batch) {
          this.updateEventInStore(null);
          this.removeDelEventFromCheck();
          this.refreshEventList(1);
        } else {
          // 清空勾选列表
          const delNum = checkedEvents.length;
          updateState('checkedEvents', []);
          this.refreshEventList(delNum);
        }
      });
  }
  // 如果当前不在第一页,删除/修改状态后需要重新计算当前所在页码
  refreshEventList = (effectNum) => {
    const {
      eventList,
      eventPager,
      updateState,
      requestGetEventList,
    } = this.props.eventManageStore;
    const { index, size } = eventPager;
    let prevIndex = index;
    if (index !== 1) {
      while (effectNum >= eventList.length) {
        prevIndex--;
        effectNum -= size;
      }
    }
    updateState('eventPager.index', prevIndex);
    requestGetEventList();
  }
  onSearch = formData => {
    const {
      updateState,
      resetState,
      requestGetEventList,
    } = this.props.eventManageStore;
    updateState('filterParams', formData);
    resetState('eventPager');
    requestGetEventList();
  }
  handleAddClick = () => {
    history.toAddEventPage();
  }
  hasFilterParams = (filter) => {
    return Object.values(filter).some(val => {
      if (typeof val === 'string') {
        return val;
      }
      if (typeof val === 'object') {
        return this.hasFilterParams(val);
      }
      return val !== '';
    });
  }
  render() {
    const {
      visibleOfDetail,
      visibleOfCancelAudit,
      visibleOfRefusedReason,
      visibleOfOffline,
      visibleOfDelete,
      batch,
    } = this.state;
    const {
      eventManageStore,
    } = this.props;
    const {
      filterParams,
      eventListIsReady,
      eventList,
      eventListError,
      eventPager,
      checkedEvents,
      eventDetail,
      refusedReason,
    } = eventManageStore;
    const filterProps = {
      ...filterParams,
      onSearch: this.onSearch,
      onAdd: this.handleAddClick,
    };
    const listProps = {
      loading: !eventListIsReady,
      data: eventList,
      checkedData: checkedEvents,
      error: eventListError,
      pager: eventPager,
      params: this.hasFilterParams(filterParams),
      onCheck: this.onCheck,
      onPublish: this.handlePublish,
      onViewDetail: this.handleViewDetail,
      onViewReason: this.handleViewReason,
      onModify: this.handleModify,
      onCancelAudit: this.handleCancelAudit,
      onOffline: this.handleOffline,
      onDelete: this.handleSingleDelete,
      onBatchDel: this.handleBatchDelete,
    };
    const detailProps = {
      visible: visibleOfDetail,
      onCancel: this.updateDetailState.bind(this, false),
      data: eventDetail,
    };
    const cancelAuditProps = {
      visible: visibleOfCancelAudit,
      onCancel: this.updateCancelAuditState.bind(this, false),
      onOk: this.cancelAuditEvent,
    };
    const refusedReasonProps = {
      visible: visibleOfRefusedReason,
      onCancel: this.updateRefusedReasonState.bind(this, false),
      reason: refusedReason,
    };
    const offlineProps = {
      visible: visibleOfOffline,
      onCancel: this.updateOfflineState.bind(this, false),
      onOk: this.offlineEvent,
    };
    const deleteProps = {
      visible: visibleOfDelete,
      onCancel: this.updateDeleteState.bind(this, false),
      onOk: this.deleteEvent,
      batch,
      count: checkedEvents.length,
    }
    return (
      <Content>
        <Filter {...filterProps} />
        <List {...listProps} />
        <Detail {...detailProps} />
        <CancelAudit {...cancelAuditProps} />
        <Offline {...offlineProps} />
        <Delete {...deleteProps} />
        <RefusedReason {...refusedReasonProps} />
      </Content>
    );
  }
}
export default EventManage;
