import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Content } from 'components/common';

import {
  Summary,
  Tab,
  Content as ListContent,
  PublicApplyModal,
  RefusedReason,
} from 'components/org/account'

@inject('accountStore')
@observer
class Account extends Component {
  state = {
    tab: 'CASH',
    visibleOfRefusedReason: false,
  }
  componentDidMount() {
    const {
      requestGetSummary,
      requestGetCashRecord,
      requestGetCostPublicityPeriod,
      requestGetCostPublicity,
      requestGetWelfareDetail,
      requestGetBeanDetail,
    } = this.props.accountStore;
    requestGetSummary();
    requestGetCashRecord();
    requestGetCostPublicityPeriod()
      .then(periods => {
        const firstItem = periods[0] || {};
        if (firstItem.id) {
          requestGetCostPublicity(firstItem.id);
        }
      });
    requestGetWelfareDetail();
    requestGetBeanDetail();
  }
  componentWillUnmount() {
    const {
      resetState,
    } = this.props.accountStore;
    resetState();
  }
  onTabChange = (value) => {
    this.setState({
      tab: value,
    });
  }
  handleAddCost = () => {
    const {
      openPublicApplyModal,
    } = this.props.accountStore;
    openPublicApplyModal();
  }
  updateRefuseReasonState = (visible = false) => {
    this.setState({
      visibleOfRefusedReason: visible,
    });
  }
  viewCashRefusedReason = (cashId) => {
    const {
      requestCashRefusedReason,
    } = this.props.accountStore;
    requestCashRefusedReason(cashId)
      .then(() => {
        this.updateRefuseReasonState(true);
      });
  }
  viewCostRefusedReason = (costId) => {
    const {
      requestCostRefusedReason,
    } = this.props.accountStore;
    requestCostRefusedReason(costId)
      .then(() => {
        this.updateRefuseReasonState(true);
      });
  }
  render() {
    const {
      tab,
      visibleOfRefusedReason,
    } = this.state;
    const {
      cashRecord,
      costPublicityPeriod,
      costPublicity,
      welfareDetail,
      coffeeBeanDetail,
      refusedReason,
    } = this.props.accountStore;
    const summaryProps = {
      onTabChange: this.onTabChange,
    };
    const tabProps = {
      onChange: this.onTabChange,
      value: tab,
    };
    const {
      data: periodsData,
      value: periodsId,
    } = costPublicityPeriod;
    const contentProps = {
      tab,
      cashProps: {
        ...cashRecord,
        onViewRefusedReason: this.viewCashRefusedReason,
      },
      costProps: {
        periodProps: {
          ...costPublicityPeriod,
        },
        listProps: {
          ...costPublicity,
          statistics: periodsData.find(item => item.id === periodsId),
          onAdd: this.handleAddCost,
          onViewRefusedReason: this.viewCostRefusedReason,
        },
      },
      welfareProps: {
        ...welfareDetail,
      },
      beanProps: {
        ...coffeeBeanDetail,
      },
    };
    const refusedReasonProps = {
      visible: visibleOfRefusedReason,
      onCancel: this.updateRefuseReasonState.bind(this, false),
      reason: refusedReason,
    };
    return (
      <React.Fragment>
        <Summary {...summaryProps} />
        <Content style={{paddingTop: 40}}>
          <Tab {...tabProps} />
          <ListContent {...contentProps} />
        </Content>
        <PublicApplyModal
          accountStore={this.props.accountStore}
        />
        <RefusedReason {...refusedReasonProps} />
      </React.Fragment>
    );
  }
}
export default Account;
