import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import styles from './index.less';

function Delete(props) {
  return (
    <Modal
      width="41.25rem"
      okText="确定删除"
      cancelText="关闭"
      {...props}
      destroyOnClose
    >
      <div className={styles.icon} />
      <h2 className={styles.title}>确定删除该公告？</h2>
      <p className={styles.text}>公告删除后不可恢复，确定要删除吗？</p>
      <hr className={styles.horHr} />
    </Modal>
  );
}

export default observer(Delete);
