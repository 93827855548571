import { message } from 'antd';
import initStore from 'utils/initStore';
import { orgApi } from 'api';
// import history from 'router/history';


const NoticeStore = initStore({
  state() {
    return {
      // 公告列表筛选参数
      filterParams: {
        contents: '',
        startTime: '',
        endTime: '',
      },
      // 公告列表
      noticeListIsReady: false,
      noticeList: [],
      noticeError: null,
      // 公告列表分页
      noticePager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.noticePager.index = current;
          this.requestGetNoticeList();
        },
      },
      // 详情数据
      noticeDetail: {},
    };
  },
  actions: {
    requestAddNotice(postData) {
      return new Promise((resolve, reject) => {
        orgApi.addNotice(postData)
          .then(() => {
            message.success('添加公告成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===添加公告失败');
            const { msg } = err.response.data;
            message.error(msg || '添加公告失败');
            reject(err);
          });
      });
    },
    requestGetNoticeList() {
      const {
        index,
        size,
      } = this.noticePager;
      const postData = Object.assign({
        pageNo: index,
        pageSize: size,
      }, this.filterParams);
      this.noticeListIsReady = false;
      orgApi.getNoticeList(postData)
        .then(resp => {
          const { data } = resp.data || {};
          if (!data || data.length === 0) {
            return Promise.reject(new Error('暂无数据'));
          }
          this.noticeList = data;
          this.noticeListIsReady = true;
          this.noticeError = null;
          this.noticePager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取公告列表失败');
          this.noticeListIsReady = true;
          this.noticeList = [];
          this.noticeError = err;
        });
    },
    requestGetNoticeDetail(noticeId) {
      return new Promise((resolve, reject) => {
        const params = {
          ids: noticeId,
        }
        orgApi.getNoticeDetail(params)
          .then(resp => {
            this.noticeDetail = resp.data.data[0];
            resolve(this.noticeDetail);
          })
          .catch(err => {
            console.log(err, '<===获取公告详情失败');
            const { msg } = err.response.data;
            message.error(msg || '获取详情失败');
            reject(err);
          });
      });
    },
    requestModifyNoticeStatus(putData) {
      return new Promise((resolve, reject) => {
        orgApi.modifyNoticeStatus(putData)
          .then(() => {
            message.success('修改成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===修改公告状态失败');
            const { msg } = err.response.data;
            message.error(msg || '修改失败');
            reject(err);
          });
      });
    },
    requestDeleteNotice(noticeId) {
      return new Promise((resolve, reject) => {
        orgApi.deleteNotice(noticeId)
          .then(() => {
            message.success('删除成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===删除公告失败');
            const { msg } = err.response.data;
            message.error(msg || '删除失败');
            reject(err);
          });
      });
    },
  },
});

export default new NoticeStore();
