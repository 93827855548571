import axios from 'axios';

// 获取活动列表
export const getActivityList = (params) => 
  axios.get('/api/v1/act/ngo', {params});

// 修改/新建活动
export const saveActivity = (data) =>
  axios.post(`/api/v1/act/ngo`, data);

// 提交审核
export const auditActivity = (data) =>
  axios.post(`/api/v1/act/ngo/audit`, data);

// 取消审核
export const cancelAudit = (id) =>
  axios.delete(`/api/v1/act/ngo/audit`, {data: {id}});

// 获取报名审核列表
export const getRegisterList = (id, params) =>
  axios.get(`/api/v1/act/ngo/record`, {params: Object.assign({actIds: [id]}, params)});

// 获取活动数据信息
export const getDataInfo = (id) =>
  axios.get(`/api/v1/act/ngo/statistics`, {params: {id}});

// 审核通过/拒绝
export const passAudit = (data) =>
  axios.post(`/api/v1/act/ngo/record/audit`, data);

// 获取活动信息
export const getDetail = (id) =>
  axios.get(`/api/v1/act/ngo/detail`, {params: {id}});