import React from 'react';
import { observer, inject } from 'mobx-react';
import { FilterLayout, Content, OperateBtn } from 'components/common';
import { Filter, Table, CancelAudit } from 'components/activity/manage';

import history from 'router/history';

@inject('activityManageStore')
@observer
class ActivityManage extends React.Component {
  filterRef = React.createRef();
  state = {
    optActivity: null,
    visibleOfCancelAudit: false,
    cancelAuditLoading: false,
  }
  componentDidMount() {
    const filterParams = this.filterRef.current.getParams();
    this.getListData(filterParams);
  }
  getListData = (filterParams) => {
    const {
      requestGetActivityList,
    } = this.props.activityManageStore;
    requestGetActivityList(filterParams);
  }
  resetPageParams = () => {
    const {
      updateState,
    } = this.props.activityManageStore;
    updateState('activityListPager.index', 1);
  }
  handleFilter = (filterParams, action) => {
    switch(action) {
      case 'search':
      case 'reset':
        this.resetPageParams();
        this.getListData(filterParams);
        break;
      case 'refresh':
        const { activityManageStore } = this.props;
        const oldFilterParams = activityManageStore.filterParams;
        this.getListData(oldFilterParams);
        break;
      default:
    }
  }
  // 编辑活动
  editActivity = (activity) => {
    const { id } = activity;
    history.toActivityEditPage(id);
  }
  // 显示取消审核弹框
  showCancelAudit = (visible = false, activity = null) => {
    this.setState({
      visibleOfCancelAudit: visible,
      optActivity: activity,
    })
  }

  // 确认取消审核
  confirmCancelAudit = () => {
    const { optActivity } = this.state;
    const { id } = optActivity;
    const { requestCancelAudit } = this.props.activityManageStore;
    this.setState({
      cancelAuditLoading: true,
    });
    requestCancelAudit(id)
      .then(() => {
        this.setState({
          cancelAuditLoading: false,
        });
        this.showCancelAudit(false, null);
        // 刷新列表
        this.getListData();
      })
      .catch(err => {
        this.setState({
          cancelAuditLoading: false,
        });
        this.showCancelAudit(false, null);
      });
  }
  // 报名审核
  viewAudit = (activity) => {
    const { id } = activity;
    history.toActivityAuditPage(id);
  }
  // 活动数据
  viewData = (activity) => {
    const { id } = activity;
    history.toActivityDataPage(id);
  }
  render() {
    const {
      visibleOfCancelAudit,
      cancelAuditLoading,
    } = this.state;
    const {
      filterParams,
      activityListLoading,
      activityList,
      activityListError,
      activityListPager,
    } = this.props.activityManageStore;
    const filterProps = {
      filterRef: this.filterRef,
      onFilter: this.handleFilter,
    };
    const tableProps = {
      loading: activityListLoading,
      data: activityList,
      error: activityListError,
      pager: activityListPager,
      params: filterParams && Object.keys(filterParams).length > 0,
      actionRender: (val, record) => {
        const { state } = record;
        const showEditBtn = [0, 1, 3].includes(state);
        const showCancelBtn = [2].includes(state);
        const showAuditBtn = [4, 5].includes(state);
        const showViewDataBtn = [4, 5, 6].includes(state);
        // 判断一个按钮都没有时展示/
        if (!showEditBtn && !showCancelBtn && !showAuditBtn && !showViewDataBtn) {
          return '/';
        }
        return (
          <React.Fragment>
            <OperateBtn
              text="编辑活动"
              onClick={this.editActivity.bind(this, record)}
              show={showEditBtn}
            />
            <OperateBtn
              text="取消审核"
              onClick={this.showCancelAudit.bind(this, true, record)}
              show={showCancelBtn}
            />
            <OperateBtn
              text="报名审核"
              onClick={this.viewAudit.bind(this, record)}
              show={showAuditBtn}
            />
            <OperateBtn
              text="查看数据"
              onClick={this.viewData.bind(this, record)}
              show={showViewDataBtn}
            />
          </React.Fragment>
        );
      }
    };
    const cancelAuditProps = {
      visible: visibleOfCancelAudit,
      onCancel: this.showCancelAudit.bind(this, false, null),
      onOk: this.confirmCancelAudit,
      okButtonProps: {
        loading: cancelAuditLoading,
      },
    };
    return (
      <React.Fragment>
        <FilterLayout>
          <Filter {...filterProps} />
        </FilterLayout>
        <Content>
          <Table {...tableProps} />
          <CancelAudit {...cancelAuditProps} />
        </Content>
      </React.Fragment>
    );
  }
}

export default ActivityManage;
