// import { message } from 'antd';
import initStore from 'utils/initStore';
import { orgApi } from 'api';

const MessageStore = initStore({
  state() {
    return {
      unreadMsgCount: 0,
      // 消息列表
      messageListIsReady: false,
      messageList: [],
      messageError: null,
      // 消息列表分页
      messagePager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.messagePager.index = current;
          this.requestGetMessageList();
        }
      }
    };
  },
  actions: {
    requestGetUnreadMsgCount() {
      orgApi.getUnreadMsgCount()
        .then(resp => {
          this.unreadMsgCount = resp.data.data.unread;
        });
    },
    requestGetMessageList() {
      const {
        index,
        size,
      } = this.messagePager;
      const postData = {
        pageNo: index,
        pageSize: size,
      };
      this.messageListIsReady = false;
      orgApi.getMessageList(postData)
        .then(resp => {
          this.messageListIsReady = true;
          this.messageList = resp.data.data;
          this.messageError = null;
          this.messagePager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, "<===获取消息通知列表失败");
          this.messageListIsReady = true;
          this.messageList = [];
          this.messageError = new Error('暂无消息通知');
        });
    },
    requestModifyMsgToRead(msgId) {
      orgApi.modifyMsgToRead(msgId)
        .then(() => {
          this.unreadMsgCount = this.unreadMsgCount - 1;
          const msg = this.messageList.find(
            msg => msg.id === msgId
          );
          msg.state = 1;
        });
    },
  }
});

export default new MessageStore();
