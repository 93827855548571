import React from 'react';
import { observer } from 'mobx-react';

import {
  RowList,
} from 'components/common';

import styles from './index.less';

function List({
  loading,
  data,
  error,
  pager = {},
}) {
  const {
    index,
    size,
  } = pager;
  const listProps = {
    rowKey: 'id',
    render: (item, index) => {
      return (
        <div className={styles.item}>
          <div className={styles.headRow}>
            <span className={styles.index}>Q{index}</span>
            <h2 className={styles.question}>{item.name}</h2>
          </div>
          <p className={styles.answer} dangerouslySetInnerHTML={{__html: item.backUpOther}} />
        </div>
      );
    },
    loading,
    data: data.slice((index - 1) * size, index * size),
    error,
    pager,
  };
  return (
    <RowList
      {...listProps}
    />
  );
}

export default observer(List);
