import { message } from 'antd';
import initStore from 'utils/initStore';
import { storeApi, orgApi } from 'api';
import { getPathValue } from 'pathval';
import  moment from 'moment';

const ExchangeStore = initStore({
  state() {
    return {
      remainBeans: 0,
      list: {
        loading: true,
        error: false,
        data: [],
      },
      pager: {
        pageNo: 1,
        pageSize: 24,
        total: 0,
        onShowSizeChange: false
      },
      costBeans: 0,
      exchangeParams: {
        detail: [
          {
            id: 0,
            num: 0
          }
        ],
        payPwd: ''
      },
      exLoading: false,
      // 兑换明细相关状态
      exHistory: {
        list: {
          loading: true,
          error: false,
          data: [],
        },
        filter: {
          goodsName: '',
          startCTime: '',
          endCTime: ''
        },
        pager: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        }
      }
    };
  },
  actions: {
    setExchangeDetail(num, id, costBeans) {
      this.costBeans = costBeans;
      this.exchangeParams.detail[0].id = id;
      this.exchangeParams.detail[0].num = num;
    },
    setPayWd(pwd) {
      this.exchangeParams.payPwd = pwd;
    },
    setNoData () {
      this.list.loading = false;
      this.list.error = true;
      this.list.data = [];
      this.pager.total = 0;
    },
    // 获取咖啡豆月
    requestGetBeans() {
      orgApi.getSummary()
        .then(resp => {
          const data = resp.data.data || {};
          this.remainBeans = data.coffeeBeans;
        })
        .catch(err => {
          this.remainBeans = 0;
          console.log(err, '<===获取咖啡豆余额失败');
        });
    },
    // 获取可兑换商品列表
    requestGetGoodsList () {
      this.list.loading = true;
      storeApi.getGoodsList()
        .then(({data})=>{
          if (data.data.length < 1) {
            this.setNoData()
          } else {
            this.list.loading = false;
            this.list.error = false;
            this.list.data = data.data;
            this.pager.total = data.total;
          }
        })
        .catch(() => {
          this.setNoData()
        })
    },
    // 更新账户咖啡豆余额
    updateRemainBeans() {
      const {remainBeans, costBeans} = this;
      this.remainBeans = remainBeans - costBeans;
      this.costBeans = 0;
    },
    // 更新咖啡卷库存
    updateCouponStock() {
      const { id, num } = this.exchangeParams.detail[0];
      const couponIndex = this.list.data.findIndex(item => item.id === id);
      if (couponIndex > -1) {
        const coupon = this.list.data[couponIndex];
        coupon.myStock += num;
      }
    },
    // 兑换咖啡卷
    requestExchangeCoupon () {
      const params = this.exchangeParams;
      this.exLoading = true;
      return new Promise((resolve, reject) => {
        storeApi.ngoExchangeCoupon(params)
          .then(()=>{
            this.exResultVis = true;
            this.exLoading = false;
            this.updateRemainBeans();
            this.updateCouponStock();
            resolve()
          })
          .catch((error)=>{
            const msg = getPathValue(error, 'response.data.msg') || '操作失败'
            message.error(msg)
            this.exLoading = false;
            reject(error)
          });
      });
    },
    setExHisNoData() {
      this.exHistory.list.error = true;
      this.exHistory.list.data = [];
      this.exHistory.list.loading = false;
    },
    // 获取兑换明细
    requestExHistoryList () {
      const { startCTime,  endCTime, goodsName } = this.exHistory.filter;
      const { pageSize,  pageNo } = this.exHistory.pager;
      const params = {
        goodsName,
        startCTiem: startCTime ? moment(startCTime).format('YYYY-MM-DD HH:MM:SS') : undefined,
        endCTime: endCTime ? moment(endCTime).format('YYYY-MM-DD HH:MM:SS') : undefined,
        pageSize,
        pageNo
      }
      this.exHistory.list.loading = true;
      storeApi.getExchangeHistory(params)
        .then(({data})=>{
          if (data.data.length === 0) {
            this.setExHisNoData()
          } else {
            this.exHistory.list.data = data.data;
            this.exHistory.list.error = false;
            this.exHistory.pager.total = data.total;
            this.exHistory.list.loading = false;
          }
        })
        .catch(() => {
          this.setExHisNoData()
        })
    }
  },
});

export default new ExchangeStore();
