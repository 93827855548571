import initStore from 'utils/initStore';
import { orgApi } from 'api';

const DonationStore = initStore({
  state() {
    return {
      statistics: {
        loading: false,
        data: {},
        error: null,
      },
      list: {
        loading: false,
        data: [],
        error: null,
      },
      listPager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.listPager.index = current;
          this.requestGetDonationList();
        },
      },
    };
  },
  actions: {
    requestGetDonationStatistics () {
      this.statistics.loading = true;
      orgApi.getDonationStatistics()
        .then(resp => {
          this.statistics.loading = false;
          this.statistics.data = resp.data.data;
          this.statistics.error = null;
        })
        .catch(err => {
          console.log(err, '<===获取捐赠统计失败');
          this.statistics.loading = false;
          this.statistics.data = {};
          this.statistics.error = err;
        });
    },
    requestGetDonationList() {
      this.list.loading = true;
      const {
        index,
        size,
      } = this.listPager;
      const postData = {
        pageNo: index,
        pageSize: size,
      };
      orgApi.getDonationList(postData)
        .then(resp => {
          const data = resp.data.data || [];
          const dict = resp.data.dict || {};
          this.list.loading = false;
          this.list.data = data.map(item => Object.assign(item, dict[item.userId]));
          this.list.error = null;
          this.listPager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取捐赠统计失败');
          this.list.loading = false;
          this.list.data = {};
          this.list.error = err;
        });
    }
  },
});

export default new DonationStore();
