import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Input } from 'antd';

import styles from './index.less';

function TitleInput({ form, defaultValue }) {
  const {
    getFieldDecorator,
    getFieldValue,
    getFieldError,
  } = form;
  const handleTitleChange = (evt) => {
    const { value } = evt.target;
    if (value.length > 30) {
      evt.target.value = value.substr(0, 30);
    }
  }
  const title = getFieldValue('title') || defaultValue || '';
  return (
    <div className={styles.wrap}>
      <span className={styles.required}>*</span>
      {getFieldDecorator('title', {
        initialValue: defaultValue,
        rules: [
          {required: true, message: '动态标题不能为空'},
          {max: 30, message: '标题最多为30个字符'},
        ],
      })(
      <Input
        className={classnames(styles.titleInput, {
          [styles.error]: getFieldError('title'),
        })}
        onChange={handleTitleChange}
        placeholder="输入动态标题"
      />)}
      <span className={styles.byteCount}>{title.length}/30</span>
    </div>
  );
}

export default observer(TitleInput);
