import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';

import styles from './index.less';

function Footer({ form, onPublish, onModify, onCancelModify, onPreview }) {
  const { eventId } = useParams();
  const {
    validateFields,
  } = form;
  const handlePublish = () => {
    validateForm((formData) => {
      onPublish(formData);
    });
  }
  const handleModify = () => {
    validateForm((formData) => {
      onModify(formData);
    });
  }
  const handleCancelModify = () => {
    onCancelModify();
  }
  const handlePreview = () => {
    validateForm((formData) => {
      onPreview(formData);
    });
  }
  const validateForm = (cb) => {
    validateFields(['title', 'content', 'covers'], (errors, values) => {
      // 组装api参数
      const formData = {};
      if (!errors) {
        formData.title = values.title;
        formData.content = values.content.toHTML();
        formData.imgUrls = values.covers[0];
        cb(formData);
      }
    });
  }
  return (
    <div className={styles.wrap}>
      {!eventId ? (
        <Button
          type="primary"
          onClick={handlePublish}
        >
          发表
        </Button>
      ) : (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handleModify}
          >
            确定修改
          </Button>
          <Button
            onClick={handleCancelModify}
          >
            取消修改
          </Button>
        </React.Fragment>
      )}
      <Button
        onClick={handlePreview}
      >
        预览
      </Button>
    </div>
  );
}

export default observer(Footer);
