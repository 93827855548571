import { observer } from 'mobx-react';
import React from 'react';
import { SmallBlock } from 'components/common';
import { Button } from 'antd';
import { thousandsSplit } from 'utils/common'
import styles from './index.less';

const NearestApply = ({data, onTabChange, openPublicApplyModal}) => {
  const EMPTY_TEXT = '/';
  const handleAddBtnClick = () => {
    openPublicApplyModal();
    onTabChange('COST');
  }
  return (
    <SmallBlock>
        <div className={styles.date}>
          <span>最近一次提取公益金</span>
          <label>{data.nearestApplyTime || EMPTY_TEXT}</label>
        </div>
        <p className={styles.apply}>
          <span>￥</span>
          {thousandsSplit(data.nearestApplyAmount)}
        </p>
        <div className={styles.costInfo}>
          <Button
            className={styles.btn}
            onClick={handleAddBtnClick}
          >添加花费公示</Button>
          <p>花费公示比率<span>{data.costRate || EMPTY_TEXT}</span></p>
        </div>
      </SmallBlock>
  )
};

export default observer(NearestApply);
