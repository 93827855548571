import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import styles from './index.less';

function Delete({ batch, count, ...restProps }) {
  const title = !batch ?
    '确定删除该文章？' :
    <React.Fragment>
      共需要删除
      <span className={styles.count}>{count}</span>
      篇文章
    </React.Fragment>;
  return (
    <Modal
      width="41.25rem"
      okText="确定删除"
      cancelText="关闭"
      {...restProps}
      destroyOnClose
    >
      <div className={styles.icon} />
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.text}>文章删除后不可恢复，确定要删除吗？</p>
      <hr className={styles.horHr} />
    </Modal>
  );
}

export default observer(Delete);
