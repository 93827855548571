import React from 'react'
import { LIVE_STATE } from 'api/enum';

export default function State({ value }) {
  const stateText = LIVE_STATE.value(value) || '/';
  return (
    <span>
      {stateText}
    </span>
  )
}
