import React from 'react';
import { observer } from 'mobx-react';
import {
  Table,
  ErrorText,
} from 'components/common';

function List({ columns, loading, params=false, data, pager={}, error, ...restProps }) {
  // params标识数据是否被筛选
  const errorTextType = params ? 'search' : '';
  const tableProps = {
    loading,
    columns,
    dataSource: data,
    pagination: {
      current: pager.index,
      pageSize: pager.size,
      total: pager.total,
      showQuickJumper: true,
      onChange: pager.onChange,
    },
    locale: {
      emptyText: (
        <ErrorText
          type={errorTextType}
          error={error}
        />
      ),
    },
  };
  return (
    <Table {...tableProps} {...restProps} />
  );
}

export default observer(List);
