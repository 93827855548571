import React from 'react';
import { observer, inject } from 'mobx-react';

import {
  Info,
  MenuBar,
  GoodsCard
} from 'components/store/exchange'

import styles from './index.less';

@inject('exchangeStore')
@observer
class Exchange extends React.Component {
  componentDidMount() {
    const {
      requestGetBeans,
      requestGetGoodsList,
    } = this.props.exchangeStore;
    requestGetBeans();
    requestGetGoodsList();
  }
  render() {
    return (
      <React.Fragment>
        <Info />
        <div className={styles.contentBox}>
          <MenuBar />
          <GoodsCard />
        </div>
      </React.Fragment>
    );
  }
}
export default Exchange;
