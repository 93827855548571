import React from 'react';
import { observer } from 'mobx-react';
import {
  Loading,
  ErrorText
} from 'components/common'
/**
 * mapPropsToData返回字段说明
 * @prop {string} type 错误结果样式：noData search
 * @prop {bool} loading 数据是否正在加载
 * @prop {bool} error 数据是否加载出错
 */

/**
 * @param {func} mapPropsToData 从props中获取数据
 */
function loadingAndError(mapPropsToData) {
  return (WrappedComponent) => {
    function LoadingAndError (props) {
      const { loading, error, errorText, className } = mapPropsToData(props)
      if (loading) {
        return <Loading className={className}/>
      } else if (error) {
        return <ErrorText errorText={errorText} className={className}/>
      }
      return <WrappedComponent {...props} />
    }
    return observer(LoadingAndError)
  }
}
export default loadingAndError;
