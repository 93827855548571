import { observer, inject } from 'mobx-react';
import React, { useState } from 'react';

import {
  EditIcon
} from '../common';

import EditNickname from './EditNickname';
import Introduce from './Introduce';

import { UPLOAD_URL_PREFIX } from 'config/upload';
import UpHeadImg from './UpHeadImg';
import defauHeadImg from 'imgs/org/orgInfo/defauHeadImg.png';
import styles from './index.less';

const BaseInfo = ({ data, orgInfoStore }) => {
  const [nameVis, setNameVis] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const {
    requestUploadImg,
    requestModifyMyInfo
  } = orgInfoStore;
  const uploadHeadImg = (formData) => {
    requestUploadImg(formData, 11);
  }
  const editName = () => {
    setNameVis(true);
  }
  const cancelCb = () =>{
    setNameVis(false);
  }
  const updateNickName = (name) => {
    if (name.trim()) {
      setNameLoading(true);
      requestModifyMyInfo({ nickname: name }, 'nickname')
        .then(()=>{
          setNameVis(false);
          setNameLoading(false);
        })
        .catch(()=>{
          setNameLoading(false);
        })
    }
  }
  return (
    <div className={styles.infoBox}>
      <div className={styles.headImg}>
        <img src={data.logo ? `${UPLOAD_URL_PREFIX.MEMBER_HEAD_PREFIX}${data.logo}` : defauHeadImg} alt=""/>
        <UpHeadImg uploadCb={uploadHeadImg}/>
      </div>
      <div className={styles.content}>
        <p className={styles.nickname} onClick={editName}>{data.nickname}<EditIcon/></p>
        <Introduce data={data} requestModifyMyInfo={requestModifyMyInfo}/>
      </div>
      <EditNickname
        visible={nameVis}
        onOk={updateNickName}
        onCancel={cancelCb}
        loading={nameLoading}
      />
    </div>
  )
};

export default inject('orgInfoStore')(observer(BaseInfo));
