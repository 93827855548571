import { message } from 'antd';
import initStore from 'utils/initStore';
import { orgApi } from 'api';

const EventPublishStore = initStore({
  state() {
    return {
      // 后端返回的动态详情
      eventIsReady: false,
      event: {},
    };
  },
  actions: {
    requestGetEventDetail(eventId) {
      this.eventIsReady = false;
      return new Promise((resolve, reject) => {
        orgApi.getEventDetail(eventId)
          .then(resp => {
            this.event = resp.data.data[0];
            this.eventIsReady = true;
            resolve(this.event);
          })
          .catch(err => {
            console.log(err, '<===获取动态详情失败');
            this.event = {};
            this.eventIsReady = true;
            message.error(err.response.data.msg || '获取动态详情失败');
            reject(err);
          });
      });
    },
    requestPublishEvent(postData) {
      return new Promise((resolve, reject) => {
        orgApi.publishEvent(postData)
          .then(() => {
            message.success('发表动态成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '发表动态失败');
            const { msg } = err.response.data;
            message.error(msg || '发表动态失败');
            reject(err);
          });
      })
    },
    requestModifyEvent(putData) {
      return new Promise((resolve, reject) => {
        orgApi.publishEvent(putData)
          .then(resp => {
            message.success('修改动态成功');
            resolve(resp.data.data);
          })
          .catch(err => {
            console.log(err, '修改动态失败');
            const { msg } = err.response.data;
            message.error(msg || '修改动态失败');
            reject(err);
          });
      })
    },
  },
});

export default new EventPublishStore();
