import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { SearchButton } from 'components/common';
import ControlItem from '../ControlItem';

import styles from './index.less';

function BtnGroup({onSearch, onRefresh, onReset}) {
  return (
    <ControlItem className={styles.btnGroup}>
      {onSearch && <SearchButton className={styles.btnSearch} onClick={onSearch}>搜索</SearchButton>}
      {onRefresh && <Button className={styles.btnDefault} onClick={onRefresh}>刷新</Button>}
      {onReset && <Button className={styles.btnDefault}  onClick={onReset}>重置</Button>}
    </ControlItem>
  );
}

export default observer(BtnGroup);
