import { observer } from 'mobx-react';
import React from 'react';
import KeyValue from '../KeyValue'
//import styles from './index.less';

const KvCard = ({config, data, className}) => {
  const createDom = () => {
    const output = config.map((item, idx) => {
      const value = item.modifyText ? item.modifyText(data[item.key], data) : data[item.key];
      return <KeyValue key={`kv${idx}`} label={item.label} value={value} />
    })
    return output;
  }
  return (
    <div className={className}>
      {createDom()}
    </div>
  )
};

export default observer(KvCard);