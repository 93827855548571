import React, { Component } from 'react'
import { inject, observer } from 'mobx-react';

import {
  FilterLayout,
  Content,
  OperateBtn,
} from 'components/common';

import {
  Filter,
  Table,
  DetailModal,
} from 'components/live/onlineLive';

@inject('onlineLiveStore')
@observer
class OnlineLive extends Component {
  filterRef = React.createRef();
  state = {
    showDetailModal: false,
  };

  componentDidMount() {
    const filterParams = this.filterRef.current.getParams();
    this.getListData(filterParams);
  }

  getListData = (filterParams) => {
    const {
      requestGetList,
    } = this.props.onlineLiveStore;
    requestGetList(filterParams);
  }

  resetPageParams = () => {
    const {
      updateState,
    } = this.props.onlineLiveStore;
    updateState('pager.index', 1);
  }

  handleFilter = (filterParams, action) => {
    switch(action) {
      case 'search':
      case 'reset':
        this.resetPageParams();
        this.getListData(filterParams);
        break;
      case 'refresh':
        const { onlineLiveStore } = this.props;
        const oldFilterParams = onlineLiveStore.filterParams;
        this.getListData(oldFilterParams);
        break;
      default:
    }
  }

  showDetail(record) {
    const {
      requestGetDetail,
    } = this.props.onlineLiveStore;
    requestGetDetail(record.id)
      .then(() => {
        this.setState({
          showDetailModal: true,
        });
      });
  }

  closeDetail = () => {
    this.setState({
      showDetailModal: false,
    });
  }

  render() {
    const {
      filterParams,
      loading,
      data,
      error,
      pager,
      detail,
    } = this.props.onlineLiveStore;
    const filterProps = {
      filterRef: this.filterRef,
      onFilter: this.handleFilter,
    };
    const tableProps = {
      loading,
      data,
      error,
      pager,
      params: filterParams && Object.keys(filterParams).length > 0,
      actionRender: (val, record) => {
        return (
          <React.Fragment>
            <OperateBtn
              text="查看"
              onClick={this.showDetail.bind(this, record)}
              show={true}
            />
          </React.Fragment>
        );
      }
    };
    const detailProps = {
      visible: this.state.showDetailModal,
      onCancel: this.closeDetail,
      data: detail,
    };
    return (
      <React.Fragment>
        <FilterLayout>
          <Filter {...filterProps} />
        </FilterLayout>
        <Content>
          <Table {...tableProps} />
          <DetailModal {...detailProps} />
        </Content>
      </React.Fragment>
    )
  }
}

export default OnlineLive;
