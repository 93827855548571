import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { Radio } from 'antd';

import { Modal, Clipper } from 'components/common';
import FormWrapper from '../common/FormWrapper';
import Upload from '../common/Upload';
import Content from './Content';

import styles from './index.less';

/**
 * finished：完成
 * success: 成功
 * failed: 失败
 * process: 上传中
 */
const initPreviewImgs = (defaultImgs) => {
  return defaultImgs.map((item, index) => ({
    id: index,
    status: 'finished',
    step: 100,
    uploadUrl: item.img,
    previewImg: item.img,
    desc: item.content,
  }));
}
function Image({ form, defaultValue }) {
  // 初始化已保存的数据
  const [
    previewImgs,
    setPreviewImgs
  ] = useState(initPreviewImgs(defaultValue));
  const [
    clipImg,
    setClipImg,
  ] = useState(null);
  const [
    ratio,
    setRatio,
  ] = useState('1');
  const clipperRef = useRef();
  const ratioOptions = [
    {label: '原图比例（默认）', value: '1', ratio: null},
    {label: '1:1', value: '2', ratio: {x:1 ,y:1}},
    {label: '2:3',  value: '3', ratio: {x:2, y:3}},
    {label: '3:4', value: '4', ratio: {x:3, y:4}},
    {label: '9:16', value: '5', ratio: {x:9, y:16}},
  ];
  const {
    getFieldDecorator,
    getFieldValue,
    getFieldError,
    setFieldsValue,
  } = form;
  const handleFileChange = (previewItem) => {
    const content = getFieldValue('content') || [];
    const { id, uploadUrl } = previewItem;
    const index = previewImgs.findIndex(item => item.id === id);
    if (index > -1) {
      Object.assign(previewImgs[index], previewItem);
      content[index].content = previewImgs[index].desc;
      content[index].img = uploadUrl;
    } else {
      previewImgs.push(Object.assign({desc: ''}, previewItem));
      content.push({
        content: '',
      });
    }
    // 数组更新必须改变引用
    setPreviewImgs(previewImgs.slice());
    // 同步表单数据
    setFieldsValue({
      content,
    });
  }
  const handlePreviewChange = (previewImgs) => {
    setPreviewImgs(previewImgs.slice());
    const content = previewImgs.map(item => ({
      img: item.uploadUrl,
      content: item.desc,
    }));
    setFieldsValue({
      content,
    });
  }
  const handleClip = () => {
    window.onClipSuccess(clipperRef.current.clippedResult);
    window.onClipSuccess = undefined;
    setClipImg(null);
  }
  const handleShowClipper = (src) => {
    return new Promise((resolve) => {
      setClipImg(src);
      window.onClipSuccess = resolve;
    })
  }
  const handleRatioChange = (evt) => {
    const value = evt.target.value;
    setRatio(value);
  }
  const checkedRatio = ratioOptions.find(item => {
    return item.value === ratio;
  }).ratio;
  return (
    <React.Fragment>
      <div className={styles.header}>
        <span className={styles.tip}>
          <span>*</span>
          请选择图片比例
        </span>
          <Radio.Group
            onChange={handleRatioChange}
            value={ratio}
          >
            {ratioOptions.map(option => (
              <Radio key={option.value} value={option.value}>{option.label}</Radio>
            ))}
          </Radio.Group>
      </div>
      <div className={styles.wrap}>
        {getFieldDecorator('content', {
          initialValue: defaultValue,
          rules: [
            {required: true, message: '内容不能为空'},
            {validator: (rule, value) => value.every(item => item.img), message: '图片还未上传完成'},
          ],
        })(<FormWrapper />)}
        {previewImgs.length === 0 ? (
          <Upload
            size="large"
            onChange={handleFileChange}
            showClipper={checkedRatio && handleShowClipper}
            len={previewImgs.length}
            error={getFieldError('content')}
          />
        ) : (
          <Content
            form={form}
            onFileChange={handleFileChange}
            showClipper={checkedRatio && handleShowClipper}
            onChange={handlePreviewChange}
            value={previewImgs}
            limit={20}
          />
        )}
        <Modal
          visible={!!clipImg}
          title="请剪裁您的图片"
          onCancel={() => setClipImg(null)}
          destroyOnClose
          okText="裁剪"
          onOk={handleClip}
        >
          {checkedRatio  && <Clipper
            ref={clipperRef}
            src={clipImg}
            aspect={checkedRatio.x / checkedRatio.y}
          />}
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default observer(Image);
