import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import searchNoDataImg from 'imgs/common/searchNoData.png';

import noDataImg from 'imgs/common/noData.png';
import styles from './index.less';

const ErrorText = ({type, error, className}) => {
  const cssName = classnames(styles.errorBox, className);
  switch (type) {
    case 'search':
      return (
        <div className={cssName}>
          <div>
            <img src={searchNoDataImg} alt="" />
            <p>{'没有找到结果，请重新搜索'}</p>
          </div>
        </div>
      )
    default:
      return (
        <div className={cssName}>
          <div>
            <img src={noDataImg} alt="" />
            <p>{'暂无数据'}</p>
          </div>
        </div>
      )
  }
};

export default observer(ErrorText);
