import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { message } from 'antd';

import { commonApi } from 'api';
import {
  Upload as UploadFile,
} from 'components/common';

import styles from './index.less';

class Upload extends React.Component {
  uploadId = this.props.id || this.props.len || 0;
  intervalTimer = {};
  intervalTimer = {};
  timeoutTimer = {};
  componentWillUnmount() {
    Object.values(this.intervalTimer).forEach(timer => {
      clearInterval(timer);
    });
    Object.values(this.timeoutTimer).forEach(timer => {
      clearTimeout(timer);
    });
  }
  checkUpload = (evt) => {
    const { len, limit } = this.props;
    if (limit !== undefined && len >= limit) {
      evt.stopPropagation();
      return message.warning('可上传数量已达上限');
    }
  }
  upload = (id, src, file) => {
    const previewItem = {
      id,
      status: 'process',
      step: 20,
      previewImg: src,
      uploadUrl: null,
    };
    this.intervalTimer[previewItem.id] = setInterval(() => {
      if (previewItem.step < 90) {
        previewItem.step++;
        this.props.onChange(previewItem);
      } else {
        clearInterval(this.intervalTimer[previewItem.id]);
      }
    }, 1000 / 60);
    this.props.onChange(previewItem);
    const formData = new FormData();
    formData.append('upfile', file);
    commonApi.uploadImg(formData, 21)
      .then(resp => {
        clearInterval(this.intervalTimer[previewItem.id]);
        const { viewPath } = resp.data.data || {};
        previewItem.uploadUrl = viewPath;
        previewItem.status = 'success';
        previewItem.step = 100;
        this.props.onChange(previewItem);
        this.timeoutTimer[previewItem.id] = setTimeout(() => {
          if (previewItem.status !== 'deleted') {
            previewItem.status = 'finished';
            this.props.onChange(previewItem);
          }
        }, 2000);
      })
      .catch(() => {
        clearInterval(this.intervalTimer[previewItem.id]);
        previewItem.status = 'failed';
        this.props.onChange(previewItem);
      });
  }
  onFileChange = (file) => {
    const { len, limit } = this.props;
    if (limit !== undefined && len >= limit) {
      return message.warning('可上传数量已达上限');
    }
    const sourceReader = new FileReader();
    sourceReader.readAsDataURL(file[0]);
    sourceReader.onload = () => {
      const id = this.uploadId;
      if (this.props.id === undefined) {
        this.uploadId++;
      }
      const sourceSrc = sourceReader.result;
      if (this.props.showClipper) {
        this.props.showClipper(sourceSrc)
          .then(clipResult => {
            const clipReader = new FileReader();
            clipReader.readAsDataURL(clipResult);
            clipReader.onload = () => {
              this.upload(id, clipReader.result, clipResult);
            }
          });
      } else {
        this.upload(id, sourceSrc, file[0]);
      }
    }
  }
  render() {
    const { size, error, className, ratio={}, children } = this.props;
    return (
      <UploadFile
        accept="image/*"
        className={className}
        onChange={this.onFileChange}
      >
        <div onClick={this.checkUpload}>
          {children || (
            <div
              style={{width: `${100 * ratio.x / 16}rem`, height: `${100 * ratio.y / 16}rem`}}
              className={classnames(styles.wrap, styles[size], {
                [styles.error]: error,
              })}
            >
              <span className={styles.icon} />
              <span className={styles.info}>点击添加图片</span>
              <span className={styles.tip}>
                {size === 'small' ? (
                  <React.Fragment>
                    <span>
                      支持JPG/PNG格式，
                    </span>
                    <br />
                    <span className={styles.warning}>图片底色不要纯白色，否则影响美观</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    支持JPG/PNG格式，大小不要超过1M，最多上传20张图
                  </React.Fragment>
                )}
              </span>
            </div>
          )}
        </div>
      </UploadFile>
    );
  }
}

Upload.defaultProps = {
  size: 'small',
}

export default observer(Upload);
