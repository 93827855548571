import React from 'react';
import { observer } from 'mobx-react';
import { thousandsSplit } from 'utils/common';

import styles from './index.less';

function Statistics({ config, data }) {
  return (
    <div className={styles.wrapper}>
      {config.map(item => {
        const num = (item.render ? item.render(data) : data[item.dataIndex]) || 0;
        return (
          <div key={item.dataIndex} className={styles.item}>
            <span className={styles.name}>{item.name}</span>
            <span className={styles.num}>
              {thousandsSplit(num, 0)}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default observer(Statistics);