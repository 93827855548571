import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from './index.less';

function FilterLayout({ className, children, ...restProps }) {
  return (
    <div
      className={classnames(styles.wrap, className)}
      {...restProps}
    >
      {children}
    </div>
  );
}

export default observer(FilterLayout);
