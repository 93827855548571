import { observer } from 'mobx-react';
import React from 'react';
import {
  EditIcon,
} from '../../common';
import  {
  Upload
} from 'components/common'
import styles from './index.less';

const UpHeadImg = ({ uploadCb }) => {
  const changeCb = (files) => {
    const formData = new FormData();
    formData.append('upfile', files[0])
    uploadCb(formData)
  }
  return (
    <Upload onChange={changeCb}>
      <div className={styles.uploadImg}>
      <span>修改头像</span>
        <EditIcon/>
      </div>
    </Upload>
  )
};

export default observer(UpHeadImg);
