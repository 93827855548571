import moment from 'moment';

export const convertTimestamp = (timestamp) => {
  if (!timestamp) {
    return '/';
  }
  return moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
}

export const convertS2H = (s) => {
  return `${(s / 3600).toFixed(2)}小时`;
}
