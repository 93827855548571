import React from 'react';
import { observer } from 'mobx-react';
import { TableFilter } from 'components/common';

function Filter({ onFilter, filterRef }) {

  const filterConfig = {
    controls: [
      {
        name: 'title',
        label: '直播名称',
        type: 'input',
        placeholder: '请输入直播名称',
      },
    ],
    params: () => {

    },
    btnPlacement: 'right',
    onSearch: (values, action) => {
      onFilter(values, action);
    },
  };
  return (
    <TableFilter
      ref={filterRef}
      {...filterConfig}
    />
  );
}
export default observer(Filter);
