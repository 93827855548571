import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Content,
} from 'components/common';
import {
  Statistics,
  List,
} from 'components/org/donation';

@inject('donationStore')
@observer
class Donation extends Component {
  componentDidMount() {
    const {
      requestGetDonationStatistics,
      requestGetDonationList,
    } = this.props.donationStore;
    requestGetDonationStatistics();
    requestGetDonationList();
  }
  render() {
    const {
      statistics,
      list,
      listPager,
    } = this.props.donationStore;
    const listProps = {
      ...list,
      pager: listPager,
    };
    return (
      <Content style={{paddingTop: 0}}>
        <Statistics {...statistics} />
        <List {...listProps} />
      </Content>
    );
  }
}
export default Donation;