import React from 'react';
import { observer } from 'mobx-react';

import styles from './index.less';

function Statistics({ loading, data, error }) {
  // 容错处理
  if (loading || error || !data) {
    data = {};
  }
  // 统计配置
  const items = [
    {
      name: '文章数量',
      itemCss: 'article',
      total: data.totalNewsCount || 0,
      increase: data.addedNewsCount || 0,
    },
    {
      name: '阅读数量',
      itemCss: 'read',
      total: data.totalReadCount || 0,
      increase: data.addedReadCount || 0,
    },
    {
      name: '分享次数',
      itemCss: 'share',
      total: data.totalShareCount || 0,
      increase: data.addedShareCount || 0,
    },
  ];
  // 遍历统计配置生成统计项
  const renderItems = () => {
    return items.map((item, itemIndex) => {
      return (
        <div key={itemIndex} className={styles[item.itemCss]}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.total}>{item.total}</div>
          <div className={styles.footer}>
            <span className={styles.label}>今日新增</span>
            <span className={styles.inc}>{item.increase}</span>
            <span className={styles.incIcon} />
          </div>
        </div>
      );
    });
  }
  return (
    <div className={styles.wrap}>
      {renderItems()}
    </div>
  );
}

export default observer(Statistics);
