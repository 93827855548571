import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Button } from 'antd';

import styles from './index.less';

function SearchButton({ className, onClick }) {
  return (
    <Button
      type="primary"
      className={classnames(styles.searchBtn, className)}
      onClick={onClick}
    >
      <span className={styles.icon} />
      <span className={styles.text}>搜索</span>
    </Button>
  )
}
export default observer(SearchButton);
