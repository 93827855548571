import React from 'react';
import moment from 'moment';
import styles from './index.less';

function ICP() {
  return (
    <div className={styles.ICP}>
      <span>©{moment().format('YYYY')}</span>
      <span>上海群雄科技有限公司</span>
      <a
        href="http://icp.chinaz.com/%E6%B2%AAICP%E5%A4%8719026741%E5%8F%B7-4"
        target="__blank">
        沪ICP备19026741号-4
      </a>
    </div>
  );
}

export default ICP;