import { observer, inject } from 'mobx-react';
import React from 'react';
import BaseInfo from '../BaseInfo';
import AccountInfo from '../AccountInfo';
import BodyPic from '../BodyPic';
import Cover from '../Cover';
import LoginType from '../LoginType';
import OperInfo from '../OperInfo';
import { loadingAndError } from 'components/hoc';

const OrgBody = ({orgInfoStore}) => {
  const { orgInfo, requestUploadImg } = orgInfoStore;
  return (
    <div>
      <BaseInfo data={orgInfo}/>
      <BodyPic data={orgInfo} requestUploadImg={requestUploadImg}/>
      <Cover orgInfoStore={orgInfoStore} covers={orgInfo.covers || []}/>
      <AccountInfo data={orgInfo}/>
      <LoginType data={orgInfo}/>
      <OperInfo data={orgInfo}/>
    </div>
  )
};

export default
  inject('orgInfoStore')(
    loadingAndError((props)=>({
      loading: props.orgInfoStore.loading,
      error: props.orgInfoStore.error,
    }))(
      observer(OrgBody)
    )
  );
