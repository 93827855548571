import axios from 'axios';

export const getUserInfo = () =>
  axios.post(`/api/user/v2/info`);

export const getOfficialAuthors = () =>
  axios.get(`/api/v1/conf/cms.official`);

export const getUserMenu = () =>
  axios.get(`/api/v2/base/menu`);

export const getCode = (postData) =>
  axios.post(`/api/v2/base/sms`, postData);

export const getGraphicCode = (params) =>
  axios.get(`/api/v2/base/pictureCode`, {params, responseType: 'blob'});

export const login = (loginWay, data) => {
  const pathDict = {
    pwd: '/api/v3/earth/pwd/login',
    code: '/api/v3/earth/sms/login',
  };
  return axios.post(pathDict[loginWay], data);
}

export const logout = () =>
  axios.get(`/api/v2/base/loginOut`);
