import React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, DatePicker, Button, Icon } from 'antd';

import { SearchButton } from 'components/common';

import styles from './index.less';

const { RangePicker } = DatePicker;

function Filter({
  form,
  onAdd,
  onSearch
}) {
  const {
    getFieldDecorator,
    validateFields,
  } = form;
  const handleSearch = () => {
    validateFields((errors, values) => {
      if (!errors) {
        const formData = {
          contents: values.keyword,
          startTime: values.time && values.time[0].format("YYYY-MM-DD hh:mm:ss"),
          endTime: values.time && values.time[1].format("YYYY-MM-DD hh:mm:ss"),
        };
        onSearch(formData);
      }
    });
  }
  const handleSearchEffect = () => {
    const formData = {
      states: [1],
    };
    onSearch(formData);
  }
  return (
    <div className={styles.wrap}>
      {getFieldDecorator('keyword')(<Input
        className={styles.keywordInput}
        placeholder="请输入公告内容"
      />)}
      {getFieldDecorator('time')(<RangePicker
        className={styles.rangePicker}
        showTime
        placeholder={['发表开始时间', '发表结束时间']}
      />)}
      <SearchButton
        className={styles.searchBtn}
        onClick={handleSearch}
      />
      <Button
        className={styles.secondaryBtn}
        onClick={handleSearchEffect}
      >
        查看当前生效的
      </Button>
      <Button
        className={styles.secondaryBtn}
        onClick={onAdd}
      >
        <Icon
          className={styles.plusIcon}
          type="plus-circle"
        />
        <span>添加公告</span>
      </Button>
    </div>
  );
}

export default Form.create()(observer(Filter));
