import React from 'react';
import { observer } from 'mobx-react';
import {
  getStateText,
}  from 'components/activity/common/utils';

import styles from './index.less';

function State({value}) {
  return <span className={styles[`state_${value}`]}>{getStateText(value)}</span>;
}

export default observer(State);