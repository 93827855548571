import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const Title = (porps) => (
  <div className={styles.title}>
    <span className={styles.line}></span>
    <span>{porps.children}</span>
  </div>
);

export default observer(Title);