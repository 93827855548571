import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import BraftEditor from 'braft-editor';

import { commonApi } from 'api';
import 'braft-editor/dist/index.css';
import styles from './index.less';

class Editor extends React.Component {
  mediaUploadFn = (param) => {
    const formData = new FormData();
    formData.append('upfile', param.file);
    commonApi.uploadImg(formData, 12)
      .then(resp => {
        const { viewPath } = resp.data.data || {};
        param.success({
          url: viewPath,
        });
      })
      .catch(() => {
        param.error({
          msg: 'unable to upload.'
        })
      });
  }
  render() {
    const {
      form,
      defaultValue,
    } = this.props;
    const {
      getFieldDecorator,
      getFieldValue,
      getFieldError,
    } = form;
    const content = getFieldValue('content') || BraftEditor.createEditorState(defaultValue);
    return (
      <div className={styles.wrap}>
        <div
          className={classnames(styles.editorBox, {
            [styles.error]: getFieldError('content'),
          })}
        >
          {getFieldDecorator('content', {
            initialValue: BraftEditor.createEditorState(defaultValue),
            rules: [
              {validator: (rule, val) => {
                return val.toText().length > 0;
              }, message: '内容不能为空'},
            ],
            validateTrigger: 'onBlur',
          })(
            <BraftEditor
              media={{
                uploadFn: this.mediaUploadFn
              }}
            />
          )}
        </div>
        <div className={styles.countInfo}>
          <span className={styles.byteCount}>字数 {content.toText().length}</span>
        </div>
      </div>
    );
  }
}

export default observer(Editor);
