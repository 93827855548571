import React from 'react';
import { observer } from 'mobx-react';
import { Icon, Popover } from 'antd';

import { thousandsSplit } from 'utils/common';

import styles from './index.less';

function Statistics({ data }) {
  if (!data) {
    return null;
  }
  console.log(data);
  return (
    <div className={styles.wrap}>
      <div className={styles.item}>
        <span className={styles.label}>
          本期公益金
          <Popover
            placement="topLeft"
            content={(
              <div className={styles.tipBox}>
                本期公益金：包含这期提现金额+上期截止结束时间时的剩余公益金
              </div>
            )}
          >
            <Icon type="question-circle" />
          </Popover>
        </span>
        <span className={styles.money}>
          <span>¥</span>
          {thousandsSplit(data.amount || 0)}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>共花费公益金</span>
        <span className={styles.money}>
          <span>¥</span>
          {thousandsSplit(data.spend || 0)}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>
          剩余公益金
          <Popover
            placement="topLeft"
            content={(
              <div className={styles.tipBox}>
                剩余公益金=本期公益金-共花费公益金
              </div>
            )}
          >
            <Icon type="question-circle" />
          </Popover>
        </span>
        <span className={styles.money}>
          <span>¥</span>
          {thousandsSplit(data.balance || 0)}
        </span>
      </div>
      <div className={styles.item}>
        <span className={styles.label}>
          花费公示比率
          <Popover
            placement="topLeft"
            content={(
              <div className={styles.tipBox}>
                花费率=花费公益金/本期公益金
              </div>
            )}
          >
            <Icon type="question-circle" />
          </Popover>
        </span>
        <span className={styles.money}>
          {`${data.costRate * 100}％`}
        </span>
      </div>
    </div>
  );
}

export default observer(Statistics);
