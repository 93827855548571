import React from 'react';
import { observer } from 'mobx-react';
import { Switch } from 'antd';

import {
  List as CommonList,
  OperateBtn,
} from 'components/common';

import styles from './index.less';

function List({
  loading,
  data,
  error,
  pager={},
  params,
  onStatusChange,
  onViewDetail,
  onDelete,
}) {
  const handleViewDetail = (record) => {
    onViewDetail(record.id);
  }
  const handleDelete = (record) => {
    onDelete(record.id);
  }
  const renderContent = (val) => {
    return <div className={styles.content}>{val}</div>;
  }
  const renderTime = (val) => {
    return <div className={styles.time}>{val}</div>;
  }
  const renderStatus = (val, record) => {
    return (
      <Switch
        onChange={onStatusChange.bind(null, record)}
        checked={val === 1}
      />
    );
  }
  const renderOperate = (val, record) => {
    return (
      <React.Fragment>
        <OperateBtn
          show={true}
          text="详情"
          onClick={handleViewDetail.bind(null, record)}
        />
        <OperateBtn
          show={true}
          text="删除"
          onClick={handleDelete.bind(null, record)}
        />
      </React.Fragment>
    );
  }
  const listProps = {
    columns: [
      {
        key: 'contents',
        title: '公告内容',
        dataIndex: 'contents',
        width: '40%',
        render: renderContent,
      },
      {
        key: 'applyTime',
        title: '创建时间',
        dataIndex: 'applyTime',
        width: '20%',
        render: renderTime,
      },
      {
        key: 'state',
        title: '可见状态',
        dataIndex: 'state',
        width: '15%',
        render: renderStatus,
      },
      {
        key: 'operate',
        title: '操作',
        dataIndex: 'operate',
        width: '25%',
        render: renderOperate,
      },
    ],
    rowKey: 'id',
    loading,
    dataSource: data,
    error,
    params,
    pager,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(List);
