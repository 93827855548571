import axios from 'axios';

// 获取文章统计
export const getArticleStatistics = () =>
  axios.get('/api/ngo/news/v2/meta');

// 获取文章列表
export const getArticleList = (postData) =>
  axios.post('/api/ngo/news/v2/list', postData);

// 获取文章详情
export const getArticleDetail = (articleId) =>
  axios.get(`/api/ngo/news/v2/${articleId}`);

// 发表文章
export const publishArticle = (articleId) =>
  axios.put(`/api/ngo/news/v2/approve/${articleId}`);

// 保存文章
export const saveArticle = (postData) =>
  axios.post(`/api/ngo/news/v2`, postData);

// 修改文章
export const modifyArticle = (putData) =>
  axios.put(`/api/ngo/news/v2`, putData);

// 删除文章
export const deleteArticle = (articleId) =>
  axios.delete('/api/ngo/news/v2', {params: {id: articleId}});

// 下线
export const offlineArticle = (articleId) =>
  axios.put(`/api/ngo/news/v2/outline/${articleId}`);

// 上线
export const onlineArticle = (articleId) =>
  axios.put(`/api/ngo/news/v2/online/${articleId}`);

// 查看被拒原因
export const getRefusedReason = (articleId) =>
  axios.get(`/api/ngo/news/v2/refuseReason/${articleId}`);

// 取消审核
export const cancelAudit = (articleId) =>
  axios.put(`/api/ngo/news/v2/cancelAudit/${articleId}`);
