import React from 'react';
import { observer } from 'mobx-react';
import { Radio, Input, DatePicker } from 'antd';
import moment from 'moment';
import classnames from 'classnames';

import styles from './index.less';

function ContentType({
  form,
  defaultValue,
  contentSource,
  contentAuthor,
  contentPublishTime,
  contentUrl,
}) {
  const {
    getFieldDecorator,
    getFieldValue,
    getFieldError,
  } = form;
  return (
    <div className={styles.wrap}>
      <span className={styles.required}>*</span>
      <span className={styles.title}>内容</span>
      {getFieldDecorator('sourceType', {
        initialValue: defaultValue,
      })(
        <Radio.Group>
          <Radio value={true}>原创</Radio>
          <Radio value={false}>转载</Radio>
        </Radio.Group>
      )}
      {!getFieldValue('sourceType') ? (
        <div className={styles.republish}>
          <div className={classnames(styles.formItem, styles.smallItem)}>
            <span>内容来源</span>
            {getFieldDecorator('contentSource', {
              initialValue: contentSource,
              rules: [
                {required: true, message: '内容来源不能为空'},
              ],
            })(
            <Input
              className={classnames({
                [styles.error]: getFieldError('contentSource'),
              })}
              placeholder="请输入内容来源"
            />)}
          </div>
          <div className={classnames(styles.formItem, styles.smallItem)}>
            <span>原创作者</span>
            {getFieldDecorator('contentAuthor', {
              initialValue: contentAuthor,
              rules: [
                {required: true, message: '原创作者不能为空'},
              ],
            })(
            <Input
              className={classnames({
                [styles.error]: getFieldError('contentAuthor'),
              })}
              placeholder="请输入原创作者"
            />)}
          </div>
          <div className={classnames(styles.formItem, styles.smallItem)}>
            <span>原文发布时间</span>
            {getFieldDecorator('contentPublishTime', {
              initialValue: contentPublishTime ? moment(contentPublishTime) : contentPublishTime,
              rules: [
                {required: true, message: '原文发布时间不能为空'},
              ],
            })(
            <DatePicker
              className={classnames({
                [styles.error]: getFieldError('contentPublishTime'),
              })}
              showTime={true}
              placeholder="请输入原文发布时间"
            />)}
          </div>
          <div className={classnames(styles.formItem, styles.largeItem)}>
            <span>原文URL</span>
            {getFieldDecorator('contentUrl', {
              initialValue: contentUrl,
              rules: [
                {required: true, message: '原文URL不能为空'},
                {validator: (rule, val) => /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/.test(val), message: '原文URL格式错误'},
              ],
            })(
            <Input
              className={classnames({
                [styles.error]: getFieldError('contentUrl'),
              })}
              placeholder="请输入原文URL"
            />)}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default observer(ContentType);
