import React from 'react';
import { observer } from 'mobx-react';

import { thousandsSplit } from 'utils/common';
import {
  List as CommonList,
  OperateBtn,
} from 'components/common';

import styles from './index.less';

function CashRecord({
  loading,
  data,
  error,
  pager={},
  onViewRefusedReason,
}) {
  const statusMap = {
    0: '审核被拒绝',
    1: '审核中',
    2: '提现成功',
  };
  const handleViewRefusedReason = (record) => {
    onViewRefusedReason(record.id);
  }
  const renderCycle = (val, record) => {
    return `${record.startTime || '/'} 至 ${record.endTime || '/'}`
  }
  const renderAmount = (val) => {
    return `¥${thousandsSplit(val)}`;
  }
  const renderStatus = (val) => {
    return <span className={styles[`status_${val}`]}>{statusMap[val] || '审核中'}</span>;
  }
  const renderOperate = (val, record) => {
    return (
      <React.Fragment>
        <OperateBtn
          show={record.state === 0}
          text="查看拒绝原因"
          onClick={handleViewRefusedReason.bind(null, record)}
        />
      </React.Fragment>
    );
  }
  const listProps = {
    columns: [
      {
        key: 'startTime',
        title: '申请时间',
        dataIndex: 'startTime',
        width: '15%',
      },
      {
        key: 'transferTime',
        title: '打款日期',
        dataIndex: 'transferTime',
        width: '15%',
      },
      {
        key: 'startTime-endTime',
        title: '结算周期',
        dataIndex: 'startTime-endTime',
        width: '27%',
        render: renderCycle,
      },
      {
        key: 'transferAmount',
        title: '打款金额',
        dataIndex: 'transferAmount',
        width: '15%',
        render: renderAmount,
      },
      {
        key: 'state',
        title: '申请状态',
        dataIndex: 'state',
        width: '13%',
        render: renderStatus,
      },
      {
        key: 'operate',
        title: '操作',
        dataIndex: 'operate',
        width: '15%',
        render: renderOperate,
      },
    ],
    rowKey: 'id',
    loading,
    dataSource: data,
    error,
    pager,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(CashRecord);
