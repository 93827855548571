import React from 'react';
import { observer } from 'mobx-react';

import { thousandsSplit } from 'utils/common';
import { COFFEE_MONEY_TYPE } from 'api/enum';
import {
  List as CommonList,
} from 'components/common';

import styles from './index.less';

function CoffeeBeanDetail({
  loading,
  data,
  error,
  pager={},
}) {
  const typeMap = {
    EXPEND: '-',
    EARN: '+',
  };
  const renderWay = (val) => {
    return COFFEE_MONEY_TYPE.value(val) || '/';
  }
  const renderNum = (val) => {
    return `${thousandsSplit(val, 0)}`;
  }
  const renderBeans = (val, record) => {
    let { type } = record;
    return <span className={styles[`bean_${type}`]}>{typeMap[type] || ''}{renderNum(val)}</span>;
  }
  const listProps = {
    columns: [
      {
        key: 'ctime',
        title: '创建时间',
        dataIndex: 'ctime',
        width: '20%',
      },
      {
        key: 'mobile',
        title: '对方信息',
        dataIndex: 'mobile',
        width: '20%',
      },
      {
        key: 'way',
        title: '类型',
        dataIndex: 'way',
        width: '20%',
        render: renderWay,
      },
      {
        key: 'beans',
        title: '咖啡豆收支数额',
        dataIndex: 'beans',
        width: '20%',
        render: renderBeans,
      },
      {
        key: 'surplusBeans',
        title: '咖啡豆余额',
        dataIndex: 'surplusBeans',
        width: '20%',
        render: renderNum,
      },
    ],
    rowKey: (record, index) => index,
    loading,
    dataSource: data,
    error,
    pager,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(CoffeeBeanDetail);
