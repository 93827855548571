import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const MenuBar = () => (
  <div className={styles.menuBox}>
    <p className={styles.actMenu}>积分兑换</p>
  </div>
);

export default observer(MenuBar);