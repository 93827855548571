import React from 'react';
import { observer } from 'mobx-react';
import { TableFilter } from 'components/common';

function Filter({ onFilter, filterRef }) {

  const filterConfig = {
    controls: [
      {
        name: 'states',
        label: '活动状态',
        type: 'select',
        split: true,
        convert: 'split',
        defaultValue: 'AUDIT,PASS_AUDIT,UNDERWAY,OVER,REJECT_AUDIT,CREATE,CANCEL_AUDIT',
        options: [
          {label: '全部', value: 'AUDIT,PASS_AUDIT,UNDERWAY,OVER,REJECT_AUDIT,CREATE,CANCEL_AUDIT'},
          {label: '审核中', value: 'AUDIT'},
          {label: '进行中', value: 'UNDERWAY'},
          {label: '已结束', value: 'OVER'},
          {label: '驳回', value: 'REJECT_AUDIT'},
          {label: '未提交', value: 'CREATE,CANCEL_AUDIT'},
        ],
        placeholder: '请选择活动状态',
      },
      {
        name: 'time',
        label: '活动时间',
        type: 'rangeTime',
        convert: (val, params) => {
          if (val && val.length > 0) {
            params.startTime = val[0];
            params.expireTime = val[1];
          }
          return 'DELETE_FIELD';
        },
        placeholder: ['请选择活动开始时间', '请选择活动结束时间'],
      },
      {
        name: 'types',
        label: '活动类型',
        type: 'select',
        convert: 'split',
        defaultValue: 'VOLUNTEER,ON_LINE_CLS,OFF_LINE_SHARE,OTHER',
        options: [
          {label: '全部', value: 'VOLUNTEER,ON_LINE_CLS,OFF_LINE_SHARE,OTHER'},
          {label: '志愿者招募类', value: 'VOLUNTEER'},
          {label: '线上课程类', value: 'ON_LINE_CLS'},
          {label: '线下分享类', value: 'OFF_LINE_SHARE'},
          {label: '其他', value: 'OTHER'},
        ],
        placeholder: '请选择活动状态',
      },
      {
        name: 'title',
        label: '活动名称',
        type: 'input',
        placeholder: '请输入活动名称',
      },
    ],
    params: () => {

    },
    onSearch: (values, action) => {
      onFilter(values, action);
    },
  };
  return (
    <TableFilter
      ref={filterRef}
      {...filterConfig}
    />
  );
}
export default observer(Filter);
