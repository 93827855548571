import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const SmallBlock = ({children}) => {
  return (
    <div className={styles.block}>
      {children}
    </div>
  )
};

export default observer(SmallBlock);