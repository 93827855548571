
import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from 'antd';

import { List as CommonList } from 'components/common';

import { UPLOAD_URL_PREFIX } from 'config/upload';

import styles from './index.less'

const List = ({ goodsStore }) => {
  const {
    filter,
    loadingObj,
    updateState,
    requestGetGoodsStock,
    requestUpOrDownGoods
  } = goodsStore;
  const {
    loading,
    data,
    error
  } = goodsStore.list;
  const {
    pageNo,
    pageSize,
    total,
  } = goodsStore.pager;
  const optGoods = ({id, status}) => {
    requestUpOrDownGoods({goodsId: id, status: status === 'UP' ? 'DOWN': 'UP'});
  }
  const renderOperation = (value, record) => {
    return (
      <Button
        loading = {loadingObj[`goods${record.id}`]}
        className={styles.btn}
        onClick={optGoods.bind(null, record)}>
        {record.status === 'UP' ? '下架' : '上架'}
      </Button>
    )
  }
  const renderGoodsInfo = (value, record) => {
    return (
      <div className={styles.goods}>
        <img src={record.couponImg ? `${UPLOAD_URL_PREFIX.COUPON_PREFIX}${record.couponImg}` : null} alt="" />
        <p className={styles.goodsName}>{record.couponName}</p>
      </div>
    )
  }
  const renderStatus = (value, record) => {
    if (value === 'UP') {
      return (
        <span className={styles.saleLabel}>出售中</span>
      )
    }
    return (
      <span className={styles.warehouse}>在仓库</span>
    )
  }
  const listProps = {
    rowKey: "id",
    columns: [
      {
        key: 'item',
        dataIndex: 'item',
        title: '商品',
        width: '25%',
        render: renderGoodsInfo
      },
      {
        key: 'price',
        dataIndex: 'price',
        title: '售价',
        width: '10%',
        renderType: 'money'
      },
      {
        key: 'stock',
        dataIndex: 'stock',
        title: '库存',
        width: '10%',
      },
      {
        key: 'salesQuantity',
        dataIndex: 'salesQuantity',
        title: '销量',
        width: '10%'
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: '状态',
        width: '10%',
        renderType: 'money',
        render: renderStatus
      },
      {
        key: 'operate',
        dataIndex: 'operate',
        title: '操作',
        width: '10%',
        render: renderOperation,
      },
    ],
    loading,
    data,
    params: Object.values(filter).some(val => val),
    error,
    onChange: (pagination) => {
      updateState('pager.pageNo', pagination.current)
      requestGetGoodsStock()
    },
    pagination: {
      current: pageNo,
      pageSize: pageSize,
      total: total,
      showQuickJumper: true,
    },
  }
  useEffect(()=>{
    requestGetGoodsStock()
  }, [requestGetGoodsStock])
  return (
    <div className={styles.table}>
      <CommonList {...listProps}/>
    </div>
  );
}

export default inject('goodsStore')(observer(List));
