import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Input } from 'antd';
import {
  Modal
} from 'components/common'
import styles from './index.less';

const EditNickname = ({onOk, onCancel, visible, loading}) => {
  const [value, setValue] = useState()
  const changeValue = (evt) => {
    const value = evt.target.value.trim();
    setValue(value);
  }
  const okCb = () => {
    onOk(value);
    setValue('');
  }
  const cancelCb = () => {
    onCancel();
    setValue('');
  }
  return (
    <Modal
      visible={visible}
      width="41.25rem"
      onOk={okCb}
      onCancel={cancelCb}
      okButtonProps={{ loading }}>
      <React.Fragment>
        <div className={styles.infoBox}>
          <i className={styles.icon}></i>
          <div>
            <p className={styles.title}>编辑昵称</p>
            <p className={styles.subTitle}>昵称最多不能超过12个字符</p>
          </div>
        </div>
        <Input
          className={styles.input}
          maxLength={12}
          value={value}
          onChange={changeValue}
        />
      </React.Fragment>
     
    </Modal>
  );
};

export default observer(EditNickname);