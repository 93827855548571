import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import styles from './index.less';

function Offline(props) {
  return (
    <Modal
      width="41.25rem"
      okText="确定下线"
      cancelText="关闭"
      {...props}
      destroyOnClose
    >
      <div className={styles.icon} />
      <h2 className={styles.title}>确定要下线该动态？</h2>
      <p className={styles.text}>下线后动态会变为未发表状态，确定要下线吗？</p>
      <hr className={styles.horHr} />
    </Modal>
  );
}

export default observer(Offline);
