import React from 'react';
import { observer } from 'mobx-react';
import { List } from 'components/common';
import { State } from 'components/live/onlineLive/common';
import { UPLOAD_URL_PREFIX } from 'config/upload';
import {
  convertTimestamp,
} from '../utils';

import styles from './index.less';

function Table(props) {
  const {actionRender, ...restProps} = props;
  const tableProps = {
    columns: [
      {
        key: 'cover',
        title: '直播封面',
        dataIndex: 'cover',
        width: '10%',
        render(value) {
          return <img className={styles.cover} alt="" src={`${UPLOAD_URL_PREFIX.LIVE}${value}`} />;
        },
      },
      {
        key: 'title',
        title: '直播名称',
        dataIndex: 'title',
        width: '10%',
      },
      {
        key: 'nickname',
        title: '主播昵称',
        dataIndex: 'nickname',
        width: '10%',
      },
      {
        key: 'ctime',
        title: '发布时间',
        dataIndex: 'ctime',
        width: '10%',
        render(value) {
          return convertTimestamp(value);
        }
      },
      {
        key: 'startTime',
        title: '计划时间',
        dataIndex: 'startTime',
        width: '20%',
        render(value, record) {
          return `${convertTimestamp(value)}至${convertTimestamp(record.expireTime)}`;
        }
      },
      {
        key: 'cancelTime',
        title: '取消时间',
        dataIndex: 'cancelTime',
        width: '10%',
        render(value) {
          return convertTimestamp(value);
        }
      },
      {
        key: 'breakTime',
        title: '终止时间',
        dataIndex: 'breakTime',
        width: '10%',
        render(value) {
          return convertTimestamp(value);
        }
      },
      {
        key: 'state',
        title: '状态',
        dataIndex: 'state',
        width: '10%',
        render(value) {
          return <State value={value} />;
        },
      },
      {
        key: 'operation',
        title: '操作',
        width: '10%',
        render: actionRender,
      },
    ],
    rowKey: 'id',
    actionAlign: 'right',
    ...restProps,
  };
  return (
    <div className={styles.wrapper}>
      <List {...tableProps} />
    </div>
  );
}

export default observer(Table);
