import React, { useState } from 'react';
import { observer } from 'mobx-react';

import FormWrapper from '../common/FormWrapper';
import Previewer from './Previewer';

import styles from './index.less';

/**
 * finished：完成
 * success: 成功
 * failed: 失败
 * process: 上传中
 */
const initPreviewImgs = (defaultImgs) => {
  if (!defaultImgs) {
    return [];
  }
  return [{
    id: 1,
    status: 'finished',
    step: 100,
    uploadUrl: defaultImgs,
    previewImg: defaultImgs,
  }];
}
function Cover({ form, defaultValue }) {
  const [
    previewImgs,
    setPreviewImgs
  ] = useState(initPreviewImgs(defaultValue));
  const {
    getFieldDecorator,
  } = form;
  const handlePreviewChange = (value) => {
    setPreviewImgs(value.slice());
  }
  return (
    <div className={styles.wrap}>
      {getFieldDecorator('covers', {
        initialValue: previewImgs.map(item => item.uploadUrl),
        rules: [
          {required: true, message: `封面图片不能为空`},
        ],
      })(
        <FormWrapper />
      )}
      <span>
        <span className={styles.required}>*</span>
        <span className={styles.title}>封面</span>
      </span>
      <Previewer
        form={form}
        onChange={handlePreviewChange}
        value={previewImgs}
      />
    </div>
  );
}

export default observer(Cover);
