import { observer } from 'mobx-react';
import React from 'react';
import { Title, KvCard } from '../common'
import styles from './index.less';

const AccountInfo = ({data}) => {
  const modifyPhoto = (value) => value ? '已上传' : value;
  const config = [
    {
      key: 'role',
      label: '类型',
      modifyText: (val) => {
        return ({
          0: '新人',
          1: '个人',
          2: '大V',
          3: '机构',
        })[val] || '/';
      },
    },
    { key: 'id', label: 'ID'},
    { key: 'name', label: '机构名称'},
    { key: 'code', label: '机构代码'},
    { key: 'region', label: '所在城市'},
    { key: 'animals', label: '相关慈善动物'},
    { key: 'bankNum', label: '银行卡号'},
    // { key: 'people', label: '收款方信息'},
    // { key: 'openBank', label: '开户银行'}, v-1.2暂时下调
    { key: 'photo', label: '证件机构照', modifyText: modifyPhoto},
  ]
  return (
    <div className={styles.box}>
      <Title>账号信息</Title>
      <KvCard className={styles.kvCard} data={data} config={config}></KvCard>
    </div>
  )
};

export default observer(AccountInfo);
