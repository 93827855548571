import { observer } from 'mobx-react';
import React from 'react';
import Modal from 'components/common/Modal';
import { Button } from 'antd';
import styles from './index.less';

const ExSuccessModal = ({visible, onOk}) => {
  return (
    <Modal
      visible={visible}
      footer={null}
      width="33.9375rem"
      centered
      onCancel={onOk}
      >
      <div className={styles.box}>
        <i className={styles.iconSuccess}></i>
        <p className={styles.text}>咖啡兑换成功</p>
        <p className={styles.subText}>兑换的咖啡商品可以在在售商品中方查看</p>
        <Button className={styles.button} type="primary" onClick={onOk}>知道了</Button>
      </div>
    </Modal>
  )
};

export default observer(ExSuccessModal);
