import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import {
  RowList,
} from 'components/common';

import styles from './index.less';

function List({
  loading,
  data,
  error,
  pager = {},
  onRead = () => {},
}) {
  const titleMap = {
    1:'系统',
    2:'文章',
    3:'订单',
    4:'捐赠',
  }
  const statusMap = {
    0: '标记为已读',
    1: '已读',
  }
  const handleRead = (item) => {
    if (item.state === 0) {
      onRead(item.id);
    }
  }
  const listProps = {
    rowKey: 'id',
    render: item => {
      return (
        <div className={styles.item}>
          <h2 className={styles.title}>
            {titleMap[item.type] || '系统'}消息
            <span
              className={classnames({
                [styles.unread]: item.state === 0,
                [styles.read]: item.state === 1,
              })}
              onClick={handleRead.bind(null, item)}
            >
              {statusMap[item.state] || '已读'}
            </span>
          </h2>
          <p className={styles.content}>{item.content}</p>
          <span className={styles.time}>{item.ctime}</span>
        </div>
      );
    },
    loading,
    data,
    error,
    pager,
  };
  return (
    <RowList
      {...listProps}
    />
  );
}

export default observer(List);
