import React from 'react';
import { observer } from 'mobx-react';
import { Pagination } from 'antd';

import {
  Loading,
  ErrorText,
} from 'components/common';

import styles from './index.less';

function RowList({
  rowKey,
  render,
  loading,
  data,
  error,
  pager,
}) {
  const getIndex = (page, size, index) => {
    return (page - 1) * size + index + 1;
  } 
  if (loading) {
    return <Loading />;
  }
  if (error || data.length === 0) {
    return <ErrorText error={error} />;
  }
  const {
    index,
    size,
  } = pager || {};
  return (
    <div className={styles.wrap}>
      <div className={styles.list}>
        {data.map((item, itemIndex) => (
          <div key={item[rowKey]} className={styles.item}>
            {render(item, getIndex(index, size, itemIndex))}
          </div>
        ))}
      </div>
      <div className={styles.pager}>
        <Pagination
          current={pager.index}
          onChange={pager.onChange}
          pageSize={pager.size}
          total={pager.total}
          showQuickJumper
        />
      </div>
    </div>
  );
}

export default observer(RowList);
