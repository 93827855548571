import React from 'react';
import { observer } from 'mobx-react';

import Period from './Period';
import List from './List';

function CostPublicity({ periodProps, listProps }) {
  return (
    <React.Fragment>
      <Period {...periodProps} />
      <List {...listProps} />
    </React.Fragment>
  );
}

export default observer(CostPublicity);
