import { message } from 'antd';
import initStore from 'utils/initStore';
import { liveApi } from 'api';

const OnlineLiveStore = initStore({
  state() {
    return {
      filterParams: null,

      loading: false,
      data: [],
      error: null,

      detail: null,

      pager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.pager.index = current;
          this.requestGetList(this.filterParams);
        },
      },
    };
  },
  actions: {
    /**
     * 请求获取直播列表
     * @param {object|null} filterParams 筛选参数
     * @return 
     */
    requestGetList(filterParams = null) {
      this.loading = true;
      this.filterParams = filterParams;
      const { index, size } = this.pager;
      const requestParams = Object.assign({}, this.filterParams, {
        pageNo: index,
        pageSize: size,
      })
      liveApi.getLiveList(requestParams)
        .then(res => {
          const { data } = res.data;
          if (!data || data.length === 0) {
            return Promise.reject(new Error('暂无数据'));
          }
          this.data = data;
          this.pager.total = res.data.total;
          this.error = null;
          this.loading = false;
        })
        .catch(err => {
          this.data = [];
          this.pager.total = 0;
          this.error = err;
          this.loading = false;
          console.log(err);
        });
    },
    /**
     * 请求获取直播详情
     * @param {Number} liveId 直播id
     */
    requestGetDetail(liveId) {
      return new Promise((resolve) => {
        const params = {
          liveId,
        }
        liveApi.getLiveDetail(params)
          .then(res => {
            this.detail = res.data.data;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===获取直播详情失败');
            message.warn(err.response.data.msg || '获取直播详情失败');
          });
      })
    }
  },
});

export default new OnlineLiveStore();
