/**
 * mobx store中心
 */

import {
  loginStore,
} from './user';

// 商店
import {
  orderStore,
  goodsStore,
  exchangeStore
} from './store'
// 内容管理
import {
  articleManageStore,
  articlePublishStore,
} from './article';
// 直播管理
import {
  onlineLiveStore,
} from './live';
// 活动
import {
  activityManageStore,
  activityPublishStore,
} from './activity';
// 机构
import {
  orgInfoStore,
  accountStore,
  donationStore,
  noticeStore,
  // v1.3暂时下掉机构动态
  // eventManageStore,
  // eventPublishStore,
  faqStore,
  messageStore,
} from './org';

export default {
  // 登录
  loginStore,
  // 商店-爱心订单
  orderStore,
  // 商店-在售商品
  goodsStore,
  // 商店-兑换
  exchangeStore,
  // 内容管理
  articleManageStore,
  // 发表文章
  articlePublishStore,
  // 直播管理-线上直播
  onlineLiveStore,
  // 活动管理
  activityManageStore,
  // 活动-发布、编辑
  activityPublishStore,
  // 机构－信息设置
  orgInfoStore,
  // 机构－账号设置
  accountStore,
  // 机构－捐献管理
  donationStore,
  // 机构-公告
  noticeStore,
  // 机构-动态-列表
  // eventManageStore,
  // 机构-动态-发表
  // eventPublishStore,
  // 机构-常见问题
  faqStore,
  // 机构-消息通知
  messageStore,
};
