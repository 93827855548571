import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Modal as AntdModal, Button } from 'antd';

import styles from './index.less';

function Modal({
  className,
  closable,
  title,
  text,
  children,
  footer,
  cancelText,
  onCancel,
  cancelButtonProps,
  okText,
  onOk,
  okButtonProps,
  ...restProps
}) {
  const handleClose = () => {
    if (onCancel) {
      onCancel();
    }
  }
  const handleOk = () => {
    if (onOk) {
      onOk();
    }
  }
  return (
    <AntdModal
      className={classnames(styles.wrap, className)}
      {...restProps}
      onCancel={onCancel}
      closable={false}
      footer={null}
    >
      {/* 自定义关闭按钮 */}
      {closable && <div
        type="default"
        onClick={handleClose}
        className={styles.closeBtn}
      />}
      {/* 自定义标题 */}
      {title && <h2 className={styles.title}>{title}</h2>}
      {/* 自定义纯文本显示 */}
      {text && <p className={styles.text}>{text}</p>}
      {/* 内容 */}
      {children}
      {/* 自定义按钮 */}
      {footer && (
        <div className={styles.footer}>
          <Button onClick={handleClose} {...cancelButtonProps}>{cancelText}</Button>
          <Button type="primary" onClick={handleOk} {...okButtonProps}>{okText}</Button>
        </div>
      )}
    </AntdModal>
  );
}

Modal.defaultProps = {
  closable: true,
  footer: true,
  okText: '确认',
  cancelText: '取消',
}

export default observer(Modal);
