import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, message } from 'antd';

import {
  List as CommonList,
  OperateBtn,
} from 'components/common';
import {
  EVENT_PUBLISH_STATUS,
  EVENT_AUDIT_STATUS,
} from 'api/enum';

import styles from './index.less';

function List({
  loading,
  data,
  checkedData,
  error,
  pager={},
  params,
  onCheck,
  onPublish,
  onViewDetail,
  onViewReason,
  onModify,
  onCancelAudit,
  onOffline,
  onDelete,
  onBatchDel,
}) {
  const isChecked = (item) => {
    return checkedData.some(checkedItem => checkedItem.id === item.id);
  }
  const isAllChecked = () => {
    return data.length > 0 && data.every(item => isChecked(item));
  }
  const handleCheckAll = (evt) => {
    const { checked } = evt.target;
    data.forEach(item => {
      onCheck(checked, item);
    })
  }
  const handleCheck = (record, evt) => {
    const { checked } = evt.target;
    onCheck(checked, record);
  }
  // 批量删除
  const handleBatchDelClick = () => {
    if (checkedData.length > 0) {
      onBatchDel();
    } else {
      message.warn('请选择要删除的动态');
    }
  }
  const handlePublish = (record) => {
    onPublish(record);
  }
  const handleViewDetail = (record) => {
    onViewDetail(record);
  }
  const handleViewReason = (record) => {
    onViewReason(record);
  }
  const handleModify = (record) => {
    onModify(record);
  }
  const handleCancelAudit = (record) => {
    onCancelAudit(record);
  }
  const handleOffline = (record) => {
    onOffline(record);
  }
  const handleDelete = (record) => {
    onDelete(record);
  }
  // 表格title栏
  const renderTitle = () => {
    return (
      <div className={styles.header}>
        <div>
          <Checkbox
            onChange={handleCheckAll}
            checked={isAllChecked()}
          />
          <span className={styles.count}>{`共${pager.total}条记录`}</span>
        </div>
        <span
          className={styles.batchDelBtn}
          onClick={handleBatchDelClick}
        >
          批量删除
        </span>
      </div>
    );
  }
  // 动态信息列
  const renderInfo = (val, record) => {
    return (
      <div className={styles.infoCol}>
        <Checkbox
          onChange={handleCheck.bind(null, record)}
          checked={isChecked(record)}
        />
        <div className={styles.infoBox}>
          <p className={styles.artTitle}>{record.title}</p>
          <span className={styles.artDetail}>
            <span className={styles.artId}>ID {record.id}</span>
          </span>
        </div>
      </div>
    );
  }
  // key value上下排列的列
  const renderPeerValue = (name, val) => {
    return (
      <span className={styles.peerValue}>
        <span>{name}</span>
        <span>{val || 0}</span>
      </span>
    );
  }
  // 审核状态映射
  const mixinStatusMap = {
    'AUDIT': '审核中',
    'REFUSED': '审核被拒绝',
    'PUBLISHED': '已发表',
    'PRE_PUBLISH': '未发表',
  };
  const getMixinStatus = (record) => {
    let mixinStatus = 0;
    const { approveState, issueState } = record;
    switch (approveState) {
      case EVENT_AUDIT_STATUS.key('审核中'):
        mixinStatus = 'AUDIT';
        break;
      case EVENT_AUDIT_STATUS.key('审核通过'):
        if (issueState === EVENT_PUBLISH_STATUS.key('未发表')) {
          mixinStatus = 'PRE_PUBLISH';
        } else {
          mixinStatus = 'PUBLISHED';
        }
        break;
      case EVENT_AUDIT_STATUS.key('审核拒绝'):
        mixinStatus = 'REFUSED';
        break;
      case EVENT_AUDIT_STATUS.key('待审核'):
        mixinStatus = 'PRE_PUBLISH';
        break;
      default:
        mixinStatus = 'PRE_PUBLISH';
    }
    return mixinStatus;
  }
  // 状态列
  const renderAuditStatus = (val, record) => {
    const mixinStatus = getMixinStatus(record);
    return (
      <span className={styles[`auditStatus_${mixinStatus}`]}>
        {mixinStatusMap[mixinStatus]}
      </span>
    );
  }
  // 操作列,只有当状态满足某些条件时,按钮才会显示
  const renderOperate = (val, record) => {
    const mixinStatus = getMixinStatus(record);
    return (
      <div className={styles.optBox}>
        <OperateBtn
          text="发表"
          show={['未发表'].includes(mixinStatusMap[mixinStatus])}
          onClick={handlePublish.bind(null, record)}
        />
        <OperateBtn
          text="查看"
          show={['审核中', '已发表'].includes(mixinStatusMap[mixinStatus])}
          onClick={handleViewDetail.bind(null, record)}
        />
        <OperateBtn
          text="查看被拒绝原因"
          show={['审核被拒绝'].includes(mixinStatusMap[mixinStatus])}
          onClick={handleViewReason.bind(null, record)}
        />
        <OperateBtn
          text="修改"
          show={true}
          onClick={handleModify.bind(null, record)}
        />
        <OperateBtn
          text="取消审核"
          show={['审核中'].includes(mixinStatusMap[mixinStatus])}
          onClick={handleCancelAudit.bind(null, record)}
        />
        <OperateBtn
          text="下线"
          show={['已发表'].includes(mixinStatusMap[mixinStatus])}
          onClick={handleOffline.bind(null, record)}
        />
        <OperateBtn
          text="删除"
          show={true}
          onClick={handleDelete.bind(null, record)}
        />
      </div>
    );
  }
  const listProps = {
    columns: [
      {
        key: 'info',
        title: '动态信息',
        dataIndex: 'info',
        width: '30%',
        render: renderInfo,
      },
      {
        key: 'totalLook',
        title: '阅读',
        dataIndex: 'totalLook',
        width: '20%',
        render: renderPeerValue.bind(null, '阅读'),
      },
      {
        key: 'issueState',
        title: '状态',
        dataIndex: 'issueState',
        width: '20%',
        render: renderAuditStatus,
      },
      {
        key: 'operate',
        title: '操作',
        dataIndex: 'operate',
        width: '30%',
        render: renderOperate,
      },
    ],
    rowKey: 'id',
    showHeader: false,
    loading,
    dataSource: data,
    error,
    pager,
    params,
    title: renderTitle,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(List);
