import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Modal from 'components/common/Modal';

import { UPLOAD_URL_PREFIX } from 'config/upload';

import styles from './index.less';

const ExchangeModal = ({visible, data, exchangeStore, onOk, onCancel}) => {
  const [count, setCount] = useState(1);
  useEffect(() => {
    if (!visible) {
      setCount(1);
    }
  }, [visible]);
  const subCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const addCount = () => {
    setCount(count + 1);
  }
  const okHandle = () => {
    const costBeans = count * data.beans;
    onOk(count, data.id, costBeans)
  }
  const closeModal = () => {
    setCount(1);
    onCancel();
  }
  if (data) {
    const costBeans = count * data.beans;
    const beans = exchangeStore.remainBeans || 0;
    const remainBeans = beans - costBeans;
    const okButtonProps = {
      disabled: remainBeans < 0,
    }
    return (
      <Modal
        visible={visible}
        title="兑换咖啡"
        text="兑换的咖啡会变成券在您的爱心商店里售卖"
        cancelText="取消兑换"
        okText="确认兑换"
        width="41.25rem"
        okButtonProps= {okButtonProps}
        onOk={okHandle}
        onCancel={closeModal}
        centered
      >
        <div className={styles.goodsBox}>
          <img src={data.coupon.couponImg ? `${UPLOAD_URL_PREFIX.COUPON_PREFIX}${data.coupon.couponImg}` : null} alt="" />
          <div>
            <p className={styles.name}>{data.coupon.name}</p>
            <div className={styles.operation}>
              <span className={styles.beans}>{data.beans}</span>
              <div className={styles.countBox}>
                <span
                  className={styles.mini}
                  onClick={subCount}
                />
                <span className={styles.count}>{count}</span>
                <span
                  className={styles.plus}
                  onClick={addCount}
                />
              </div>
            </div>
            <hr className={styles.line}/>
            <div className={styles.costBox}>
              <label>本次预计花费</label>
              <label className={styles.costBeans}>- {costBeans} 咖啡豆</label>
            </div>
            <div className={styles.costBox}>
              <label>兑换后剩余</label>
              {
                remainBeans >= 0 ? <label className={styles.remainBeans}>{remainBeans} 咖啡豆</label> : <label className={styles.error}>咖啡豆余额不足</label>
              }
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default inject('exchangeStore')(observer(ExchangeModal));
