import numbro from 'numbro';

// 数字增加千分符
export const thousandsSplit = (number, digit) => {
  // 使用原始数据的小数
  if (!isNaN(Number(number)) && Number(number) && digit === 'normal') {
    return numbro(Number(number)).format(
      {
        thousandSeparated: true
      })
  }
  // 保留小数由传入的数字决定,没有传入默认两位小数
  if (!isNaN(Number(number)) && Number(number)) {
    return numbro(Number(number)).format(
      {
        thousandSeparated: true,
        mantissa: !isNaN(Number(digit)) ? digit : 2
      })
  }
  return Number(number);
}

// 将数字金额化　eg: ￥12,345
export function modifyMoney(val) {
  if(!Number.isNaN(Number(val))) {
    return '￥' + thousandsSplit(val);
  }
  return val;
}

// 将咖啡豆兑换为金额
export function exBeansToMoney (val) {
  const value = val ? (Math.floor(val / 300 * 100) / 100).toFixed(2) : 0;
  return Number(value);
}

// 将金额兑换为咖啡豆
export function exMoneyToBeans (val) {
  const value = val ? val * 300 : 0
  return Number(value);
}

export function rangeArray(range) {
  const arr = [];
  for(let i = 1; i <= range; i++) {
    arr.push(i);
  }
  return arr;
}