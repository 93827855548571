import React from 'react';
import { observer } from 'mobx-react';
import styles from './index.less'
function KeyValue({label, value, className}) {
  return (
    <div className={styles.row + ` ${className}` }>
      <div className={styles.key}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  )
}
export default observer(KeyValue)