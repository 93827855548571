import { observer } from 'mobx-react';
import React from 'react';
import { Button } from 'antd'

import { UPLOAD_URL_PREFIX } from 'config/upload';

import styles from './index.less';

const CardItem = ({data, exHandle}) => {
  const clickHandle = () => {
    exHandle(data)
  }
  return (
    <div className={styles.cardItem}>
      <img src={data.coupon.couponImg ? `${UPLOAD_URL_PREFIX.COUPON_PREFIX}${data.coupon.couponImg}` : null} alt="" />
      <p className={styles.name}>{data.coupon.name}</p>
      <p className={styles.beans}>{data.beans}</p>
      <div className={styles.tipInfo}>
        <span>售价： ¥{(data.beans / 100).toFixed(2)}</span>
        <span className={styles.line}>|</span>
        <span>库存{data.myStock}件</span>
      </div>
      <Button className={styles.exButton} onClick={clickHandle}>兑换</Button>
  </div>
  )
};

export default observer(CardItem);
