import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { DatePicker, Radio } from 'antd';
import moment from 'moment';
import { ImageUpload, Editor } from 'components/common';
import { Title, FormItem } from 'components/activity/common';
import EmptyControl from '../common/EmptyControl';
import { UPLOAD_URL_PREFIX } from 'config/upload';

import styles from './index.less';

const RangePicker = DatePicker.RangePicker;
const RadioGroup = Radio.Group;
const imageUploadTips = [
  {text: '支持JPG/PNG格式，', type: 'normal'},
  {text: '建议尺寸3:2，最小不要小于750*500px', type: 'normal'},
  {text: '大小不要超过1M', type: 'normal'},
];

function Setting({ form, data = {} }) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldError,
  } = form;
  const handlePosterChange = (imgUrl = null) => {
    setFieldsValue({
      poster: imgUrl,
    })
    return Promise.resolve();
  }
  return (
    <React.Fragment>
      <Title text="活动设置" />
      <div className={styles.wrapper}>
        <FormItem label="活动封面" align="top" error={getFieldError('poster')}>
          {getFieldDecorator('poster', {
            initialValue: data.cover || null,
            valuePropName: 'preview',
            rules: [
              { required: true, message: '封面不能为空' },
            ],
          })(
            <EmptyControl />
          )}
          <div className={classnames({
            [styles.posterBox]: true,
            [styles.error]: getFieldError('poster'),
          })}>
            <ImageUpload
              tipClassName={styles.imageUploadTip}
              tips={imageUploadTips}
              uploadType={8}
              pathMode="relative"
              onSuccess={handlePosterChange}
              onRemove={handlePosterChange}
              preview={data.cover ? `${UPLOAD_URL_PREFIX.ACTIVITY}${data.cover}` : null}
            />
          </div>
        </FormItem>
        <FormItem label="活动详情" align="top" error={getFieldError('detail')}>
          <Editor
            form={form}
            defaultValue={data.content || ''}
            uploadType={8}
            fieldName="detail"
          />
        </FormItem>
        <FormItem className={styles.offset} label="是否需要对报名用户进行审核" align="center" error={getFieldError('audit')}>
          {getFieldDecorator('audit', {
            initialValue: data.autoAudit === undefined ? false : data.autoAudit,
          })(
          <RadioGroup>
            <Radio value={false}>需要</Radio>
            <Radio value={true}>不需要</Radio>
          </RadioGroup>)}
        </FormItem>
        <FormItem className={styles.offset} label="报名时间" align="center" error={getFieldError('registerTime')}>
          {getFieldDecorator('registerTime', {
            initialValue: data.applyStartTime ? [
              moment(data.applyStartTime * 1000),
              moment(data.applyExpireTime * 1000)
            ] : null,
            rules: [
              { required: true, message: '报名时间必填' },
              {
                validator: (rule, value) => {
                  const time = form.getFieldValue('time');
                  if ((value && value.length > 0) && (time && time.length > 0)) {
                    return value[1].isBefore(time[0]);
                  }
                  return true;
                },
                message: '报名时间不能晚于活动开始时间'
              },
            ],
          })(
          <RangePicker
            className={classnames({
              [styles.time]: true,
              [styles.error]: getFieldError('registerTime'),
            })}
            disabledDate={currentDate => {
              if (currentDate) {
                const isBeforeToday = currentDate.isBefore(Date.now(), 'day');
                return isBeforeToday;
              }
            }}
            showTime={{
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
            }}
            placeholder={['开始时间', '结束时间']}
          />)}
        </FormItem>
      </div>
    </React.Fragment>
  );
}

export default observer(Setting);