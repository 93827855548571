
import axios from 'axios';

// 信息设置-获取机构信息
export const getMyInfo = (params) =>
  axios.get(`/api/v2/zoo/myinfo`, { params });

// 信息设置-修改机构信息
export const modifyMyInfo = (data) =>
  axios.put(`/api/v2/zoo/myinfo`,  data);

// 账号管理-获取统计信息
export const getSummary = () =>
  axios.get(`/api/v2/zoo/draw/summary`);

// 账号管理-添加花费公示
export const addPublicApply = (params) =>
  axios.post(`/api/v2/zoo/draw/public/apply`, params);

// 账号管理-收款信息
export const getRecipientInfo = () =>
  axios.get('/api/v6/payee');
// 账号管理-保存收款信息
export const saveRecipientInfo = (data) =>
  axios.post('/api/v6/payee', data);

// 账号管理-申请提现
export const drawApplay = (postData) =>
  axios.post(`/api/v2/zoo/draw/apply`, postData);

// 账号管理-兑换公益金
export const exchangeBeansToMoney = (params) =>
  axios.post(`/api/v2/zoo/ngo/exchange`, params);

// 账号管理-申请提现
export const getCashRecord = (postData) =>
  axios.post(`/api/v2/zoo/draw/myapply`, postData);

// 账号管理-申请提现拒绝原因
export const getCashRefusedReason = (cashId) =>
  axios.get(`/api/v2/zoo/detail/reason/${cashId}`);

// 账号管理-花费公示
export const getCostPublicity = (postData) =>
  axios.post(`/api/v2/zoo/draw/mypublic`, postData);

// 账号管理-花费公示拒绝原因
export const getCostRefusedReason = (costId) =>
  axios.get(`/api/v2/zoo/draw/reason/${costId}`);

// 账号管理-公益金明细
export const getWelfareDetail = (postData) =>
  axios.post(`/api/v2/zoo/ngo/amount`, postData);

// 账号管理-咖啡豆明细
export const getBeanDetail = (postData) =>
  axios.post(`/api/v2/zoo/ngo/beans`, postData);

// 捐赠管理-统计
export const getDonationStatistics = () =>
  axios.get(`/api/v2/zoo/donate/dashboard`);

// 捐赠管理-捐赠记录
export const getDonationList = (postData) =>
  axios.post(`/api/v2/zoo/donate/donates`, postData);

// 公告-新增公告
export const addNotice = (postData) =>
  axios.post(`/api/v2/zoo/notice`, postData);

// 公告-获取公告列表
export const getNoticeList = (postData) =>
  axios.post(`/api/v2/zoo/notices`, postData);

// 公告-获取公告详情
export const getNoticeDetail = (params) =>
  axios.get(`/api/v2/zoo/notice/details`, {params});

// 公告-改变公告状态
export const modifyNoticeStatus = ({id, state}) =>
  axios.patch(`/api/v2/zoo/notice?id=${id}&state=${state}`);

// 公告-删除公告
export const deleteNotice = (noticeId) =>
  axios.delete(`/api/v2/zoo/notice?ids=${[noticeId]}`);

// 动态-获取列表
export const getEventList = (postData) =>
  axios.post('/api/v2/zoo/dynamics', postData);

// 动态-获取详情
export const getEventDetail = (eventId) =>
  axios.get(`/api/v2/zoo/details`, {params: {ids: eventId}});

// 动态-发表
export const publishEvent = (postData) =>
  axios.post(`/api/v2/zoo/dynamic`, postData);

// 动态-批量删除
export const deleteEvent = (eventIds) =>
  axios.delete(`/api/v2/zoo/dynamic?ids=${eventIds}`);

// 动态-下线
export const offlineEvent = (eventId) =>
  axios.patch(`/api/v2/zoo/outline/${eventId}`);

// 动态-查看被拒原因
export const getEventRefusedReason = (eventId) =>
  axios.get(`/api/v2/zoo/refusedReason/${eventId}`);

// 动态-取消审核
export const cancelEventAudit = (eventId) =>
  axios.patch(`/api/v2/zoo/cancelAudit/${eventId}`);

// 消息通知-列表
export const getMessageList = (postData) =>
  axios.post(`/api/v2/sys/list`, postData);

// 消息通知-未读消息数量
export const getUnreadMsgCount = () =>
  axios.get(`/api/v2/sys/unread`);

// 消息通知-标记消息为已读
export const modifyMsgToRead = (msgIds) =>
  axios.patch(`/api/v2/sys/notice/read?ids=${msgIds}`)

// 常见问题-列表
export const getFaqList = (params) =>
  axios.get(`/api/sys/dictType/v1/getDictTypeByCode/iToken`, {params});
