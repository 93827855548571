import React from 'react';
import classnames from 'classnames';

import styles from './index.less';

function FormItem({
  className,
  label,
  error,
  required=true,
  align="center",
  vertical = false,
  children,
  ...restProps
}) {
  return (
    <div
      className={classnames(styles.wrap, styles[`wrap-${align}`], {
        [styles[`wrap-vertical`]]: vertical,
      }, className)}
      {...restProps}>
      {label && <span className={styles.label}>
          {required && <span className={styles.required}>*</span>}
          {label}：
        </span>}
      <div className={styles.controlWrapper}>
        {children}
        {error && <span className={styles.error}>{error}</span>}
      </div>
    </div>
  );
}

export default FormItem;