import React from 'react';
import { observer } from 'mobx-react';

import { thousandsSplit } from 'utils/common';

import styles from './index.less';

function Statistics({ loading, data = {}, error }) {
  if (loading || error) {
    data = {};
  }
  const items = [
    {
      name: '捐赠人数',
      num: data.people,
      renderInc: () => (
        <React.Fragment>
          今日新增 
          <span>{data.dayPeople || 0}</span>
          人
        </React.Fragment>
      ),
    },
    {
      name: '捐赠次数',
      num: data.times,
      renderInc: () => (
        <React.Fragment>
          今日获得
          <span>{data.dayTimes || 0}</span>
          次捐赠
        </React.Fragment>
      ),
    },
    {
      name: '咖啡豆余额',
      num: data.beansBalance,
      numUnit: '粒',
      renderInc: () => (
        <React.Fragment>
          今日新增
          <span>{data.dayBeans || 0}</span>
          粒咖啡豆
        </React.Fragment>
      ),
    },
    {
      name: '共获得咖啡豆',
      numUnit: '粒',
      num: data.totalBeans,
    },
  ];
  return (
    <div className={styles.wrap}>
      {items.map((item, itemIndex) => (
        <div key={itemIndex} className={styles.item}>
          <span className={styles.name}>{item.name}</span>
          <span className={styles.num}>
            {thousandsSplit(item.num || 0, 0)}
            {item.numUnit && <span className={styles.numUnit}>{item.numUnit}</span>}
          </span>
          {item.renderInc && <span className={styles.inc}>{item.renderInc(item)}</span>}
        </div>
      ))}
    </div>
  );
}

export default observer(Statistics);
