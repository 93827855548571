import { message } from 'antd';
import initStore from 'utils/initStore';
import { orgApi, commonApi } from 'api';
import { getPathValue } from 'pathval';
// import history from 'router/history';

const updateMsg = {
  'logo': '头像修改',
  'nickname': '昵称修改',
  'introduce': '机构介绍修改',
  'background': '形象照修改',
  'covers': '封面修改',
}
const OrgInfoStore = initStore({
  state() {
    return {
      orgInfo: {},
      loading: false,
      error: false
    };
  },
  actions: {
    requestMyInfo() {
      this.loading = true
      orgApi.getMyInfo()
        .then(( { data } )=>{
          this.orgInfo = data.data
          this.loading = false
        })
        .catch(()=>{
          this.error = true
          this.loading = false
        })
    },
    requestModifyMyInfo(params, key) {
      const data = Object.assign({
        background: this.orgInfo.background,
        introduce: this.orgInfo.introduce,
        logo: this.orgInfo.logo,
        nickname: this.orgInfo.nickname,
        covers: this.orgInfo.covers,
      }, params);
      return orgApi.modifyMyInfo(data)
        .then(()=>{
          message.success(`${updateMsg[key]}成功！`);
          // 修改更新后的数据
          this.orgInfo[key] = params[key];
          return Promise.resolve()
        })
        .catch((error)=>{
          console.log('修改失败', error)
          const msg = getPathValue(error, 'response.data.msg') || `${updateMsg[key]}失败`;
          message.error(msg)
          return Promise.reject()
        })
    },
    requestModifyCovers(covers) {
      const data = Object.assign({
        background: this.orgInfo.background,
        introduce: this.orgInfo.introduce,
        logo: this.orgInfo.logo,
        nickname: this.orgInfo.nickname,
        covers: this.orgInfo.covers,
      }, {covers});
      return orgApi.modifyMyInfo(data)
        .then(()=>{
          message.success(`修改封面成功！`);
          // 修改更新后的数据
          this.orgInfo.covers = covers;
          return Promise.resolve()
        })
        .catch((error)=>{
          console.log('修改封面失败', error)
          const msg = getPathValue(error, 'response.data.msg') || `修改封面失败`;
          message.error(msg)
          return Promise.reject()
        })
    },
    requestUploadImg (file, type, key = 'logo') {
      commonApi.uploadImg(file, type)
        .then(({ data })=>{
          console.log(data, key);
          const imgUrl = key === 'logo' ? data.data.path : data.data.viewPath;
          if (imgUrl) {
            this.requestModifyMyInfo({
              [key]: imgUrl
            }, key);
          } else {
            message.error('上传图片失败');
          }
        })
        .catch((error)=>{
          const msg = getPathValue(error, 'response.data.msg') || '上传图片失败';
          message.error(msg)
        })
    }
  },
});

export default new OrgInfoStore();
