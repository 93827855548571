import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Content } from 'components/common';
import { HorizontalHr } from 'components/activity/common';
import {
  Info,
  List,
} from 'components/activity/activityData';

@withRouter
@inject('activityManageStore')
@observer
class ActivityData extends React.Component {
  componentDidMount() {
    const {
      match,
    } = this.props;
    // 获取地址栏带过来的活动id
    const { id } = match.params;
    const activityId = Number(id);
    const {
      requestGetDataInfo,
      requestGetDataList,
    } = this.props.activityManageStore;
    // 获取统计信息
    requestGetDataInfo(activityId);
    // 获取列表信息
    requestGetDataList(activityId);
  }
  componentWillUnmount() {
    this.props.activityManageStore.resetState('activityData');
  }
  render() {
    const {
      activityData,
    } = this.props.activityManageStore;
    const {
      infoLoading,
      infoData,
      infoError,
      listLoading,
      listData,
      listError,
      listPager,
    } = activityData;
    const infoProps = {
      loading: infoLoading,
      data: infoData,
      error: infoError,
    };
    const listProps = {
      loading: listLoading,
      data: listData,
      error: listError,
      pager: listPager,
    };
    return (
      <Content>
        <Info {...infoProps} />
        <HorizontalHr />
        <List {...listProps} />
      </Content>
    );
  }
}

export default ActivityData;