import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';

import styles from './index.less';

function Footer({ form, publishIsReady, article, onPublish, onSave, onModify, onPreview }) {
  const { articleId } = useParams();
  const {
    validateFields,
    getFieldValue,
  } = form;
  const handlePublish = () => {
    validateForm((formData) => {
      onPublish(formData);
    });
  }
  const handleSave = () => {
    validateForm((formData) => {
      onSave(formData);
    });
  }
  const handleModify = () => {
    validateForm((formData) => {
      onModify(formData);
    });
  }
  const handlePreview = () => {
    validateForm((formData) => {
      onPreview(formData);
    });
  }
  const validateForm = (cb) => {
    const isOrigin = getFieldValue('sourceType');
    const needValidateFields = ['title', 'sourceType', 'content', 'covers'];
    // 转载类文章需要校验转载信息
    if (!isOrigin) {
      [].push.apply(needValidateFields, [
        'contentSource',
        'contentAuthor',
        'contentPublishTime',
        'contentUrl',
      ]);
    }
    validateFields(needValidateFields, (errors, values) => {
      // 组装api参数
      const formData = {};
      if (!errors) {
        formData.title = values.title;
        const { content } = values;
        // 图文混排
        if (content.toHTML) {
          formData.content = content.toHTML();
          formData.newsType = 3;
        // 纯图文章
        } else {
          formData.content = JSON.stringify(content);
          formData.newsType = 4;
        }
        formData.isOrigin = isOrigin;
        formData.coverImages = values.covers || [];
        // 转载信息
        if (!isOrigin) {
          Object.assign(formData, {
            contentSource: values.contentSource,
            contentAuthor: values.contentAuthor,
            contentPublishTime: values.contentPublishTime.format('YYYY-MM-DD HH:mm:ss'),
            contentUrl: values.contentUrl,
          });
        }
        cb(formData);
      }
    });
  }
  const renderPrimaryButton = () => {
    // 已发表的文章展示修改
    const isModify = article.issueStatus === 1;
    return isModify ?
      <Button
        type="primary"
        onClick={handleModify}
      >
        确定修改
      </Button> :
      <Button
        type="primary"
        onClick={handlePublish}
        loading={!publishIsReady}
      >
        发表
      </Button>
  }
  return (
    <div className={styles.wrap}>
      {!articleId ? (
        <React.Fragment>
          <Button
            type="primary"
            onClick={handlePublish}
            loading={!publishIsReady}
          >
            发表
          </Button>
        </React.Fragment>
      ) : (
        renderPrimaryButton()
      )}
      <Button
        onClick={handleSave}
      >
        保存
      </Button>
      <Button
        onClick={handlePreview}
      >
        预览
      </Button>
    </div>
  );
}

export default observer(Footer);
