import { observer } from 'mobx-react';
import React from 'react';
import { Title, KvCard } from '../common'
import styles from './index.less';

const OperInfo = ({data}) => {
  const modifyPhoto = (value) => value ? '已上传' : value;
  const config = [
    { key: 'people', label: '运营者姓名'},
    { key: 'operatorIdCardImgFront', label: '运营者身份证正面照',  modifyText: modifyPhoto},
    { key: 'operatorIdCardImgRear', label: '运营者身份证背面照',  modifyText: modifyPhoto},
  ]
  return (
    <div className={styles.box}>
      <Title>运营者信息</Title>
      <KvCard className={styles.kvCard} data={data} config={config}></KvCard>
   </div>
  )
};

export default observer(OperInfo);