import React from 'react';
import { observer } from 'mobx-react';

import styles from './index.less';

const emptyText = '/';

function KeyValues({ config, data }) {
  return (
    <div className={styles.wrapper}>
      {config.map(item => {
        return (
          <div key={item.dataIndex} className={styles.item}>
            <span className={styles.name}>{item.name}</span>
            <span className={styles.value}>
              {item.render ? (item.render(data[item.dataIndex], data) || emptyText) : (data[item.dataIndex] || emptyText)}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default observer(KeyValues);