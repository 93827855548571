import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const KeyValue = ({label, value}) => {
  return (
    <div className={styles.row}>
      <span>{label}</span>
      <label>{value || '/'}</label>
    </div>
  );
}

export default observer(KeyValue);