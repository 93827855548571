import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Form, Input } from 'antd';
import { Modal } from 'components/common';

import styles from './index.less';

function GraphicCode({ graphic, refreshGraphic, onOk, disabled, form, ...modalProps }) {
  const {
    getFieldDecorator,
    getFieldError,
    validateFields,
  } = form;

  const handleOk = () => {
    validateFields((errors, values) => {
      if (!errors) {
        onOk(values.graphic);
      }
    });
  }
  return (
    <Modal
      width="41.25rem"
      okText="确定"
      cancelText="关闭"
      onOk={handleOk}
      okButtonProps={{
        disabled,
      }}
      {...modalProps}
      destroyOnClose
    >
      <div className={styles.icon} />
      <h2 className={styles.title}>输入图形码</h2>
      <p className={styles.text}>为确保您的账户安全验证码获取超过3次，需要输入图形验证码</p>
      <div className={styles.formItem}>
        <div className={styles.controlItem}>
          {getFieldDecorator('graphic', {
            rules: [
              {required: true, message: '请输入图形码'},
            ],
          })(
            <Input
              className={classnames(styles.graphicInput, {
                [styles.error]: getFieldError('graphic'),
              })}
              placeholder="请输入图形码"
            />
          )}
          <img
            className={styles.graphicImg}
            onClick={refreshGraphic}
            src={graphic}
            title="看不清楚，换一张"
            alt="code"
          />
        </div>
        {getFieldError('graphic') && <div className={styles.errorMsg}>
          {getFieldError('graphic')[0]}
        </div>}
      </div>
    </Modal>
  );
}

export default Form.create()(observer(GraphicCode));
