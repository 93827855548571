/**
 * 应用级配置文件
 */

export default {
  // 首页路由
  homepageUrl: '/article/manage',
  // 不需要登录的路由列表（正则严格匹配）
  notNeedLoginRoutes: [
    new RegExp('^/?$'),
    new RegExp('^/login/?$'),
  ],
  // 不需要导航的路由列表（正则严格匹配）
  notNeedNavbarRoutes: [
    new RegExp('^/?$'),
    new RegExp('^/login/?$'),
  ],
}
