import React from 'react';
import { inject, observer } from 'mobx-react';
import { getPathValue } from 'pathval';
import classnames from 'classnames';
import { Form, Input, Button, message } from 'antd';

import { GraphicCode } from 'components/login';

import history from 'router/history';
import logo from 'imgs/login/logo.png';

import styles from './index.less';

@inject('loginStore')
@observer
class Login extends React.Component {
  state = {
    errMsg: '',
    time: 0,
    codeBtnIsDisabled: false,
    visibleOfGraphicCode: false,
    // 登录方式，pwd密码登录，code短信登录
    loginWay: 'pwd',
    graphicCodeLoading: null,
    loading: false,
  }
  tabConfig = [
    {name: '密码登录', value: 'pwd'},
    {name: '短信登录', value: 'code'},
  ];
  timer = null;
  timeout = 60;
  changeTab = (value) => {
    this.setState({
      loginWay: value,
    });
    // 切换回到密码登录时如果当前没有图形码而且手机号码格式正确，则需重新获取图形码
    if (value === 'pwd') {
      const { getFieldError } = this.props.form;
      const { graphicCode } = this.props.loginStore;
      const mobileError = getFieldError('mobile');
      if (!mobileError && !graphicCode) {
        this.getLoginGraphicCode();
      }
    }
  }
  updateGraphicCodeStatus = (visible = false) => {
    this.setState({
      visibleOfGraphicCode: visible,
    });
  }
  handleGetCode = () => {
    const { form } = this.props;
    const { validateFields } = form;
    // 获取验证码前先校验手机号码
    validateFields(['mobile'], (errors, values) => {
      if (!errors) {
        if (this.state.time > 0) {
          return false;
        }
        this.getCode(values.mobile);
      }
    });
  }
  // 密码登录-获取图形验证码
  getLoginGraphicCode = () => {
    const { form } = this.props;
    const { validateFields } = form;
    // 获取验证码前先校验手机号码
    validateFields(['mobile'], (errors, values) => {
      if (!errors) {
        this.setState({
          graphicCodeLoading: true,
        })
        this.refreshGraphic()
          .then(() => {
            this.setState({
              graphicCodeLoading: false,
            });
          })
          .catch(err => {
            this.setState({
              graphicCodeLoading: false,
            });
            console.log(err);
          });
      }
    });
  }
  setLoading = (loading) => {
    this.setState({
      loading,
    });
  }
  updateCodeBtnStatus = (disabled = false) => {
    this.setState({
      codeBtnIsDisabled: disabled,
    });
  }
  handleSubmit = () => {
    const { loginWay } = this.state;
    const { form } = this.props;
    const { validateFields } = form;
    if (loginWay === 'pwd') {
      validateFields(['mobile', 'password', 'graphicCode'], (err, {mobile, password, graphicCode}) => {
        if (!err) {
          const loginData = {
            mobile,
            password,
            pictureCode: graphicCode,
          };
          this.login(loginWay, loginData);
        }
      });
    } else if (loginWay === 'code') {
      validateFields(['mobile', 'code'], (err, {mobile, code}) => {
        if (!err) {
          const loginData = {
            mobile,
            code,
          };
          this.login(loginWay, loginData);
        }
      });
    }
  }
  handleEnter = (evt) => {
    if(evt.keyCode === 13) {
      this.handleSubmit();
    }
  }
  getCode = (phone, graphic) => {
    const {
      requestGetCode,
    } = this.props.loginStore;
    // 禁用获取验证码按钮
    this.updateCodeBtnStatus(true);
    requestGetCode(phone, graphic)
      .then(popup => {
        // 启用获取验证码按钮
        this.updateCodeBtnStatus(false);
        // 短信验证码获取失败,弹出图形验证码
        if (popup) {
          this.popupGraphicCode(phone);
        } else {
          /* 短信验证码获取成功 */
          // 倒计时开始
          this.loopTime();
          // 关闭图形验证码弹框
          if (graphic) {
            this.updateGraphicCodeStatus();
          }
        }
      })
      .catch(() => {
        // 启用获取验证码按钮
        this.updateCodeBtnStatus(false);
      });
  }
  getCodeWithGraphic = (graphic) => {
    const { form } = this.props;
    const { getFieldValue } = form;
    const phone = getFieldValue('mobile');
    this.getCode(phone, graphic);
  }
  refreshGraphic = () => {
    const {
      requestGetGraphicCode,
    } = this.props.loginStore;
    const { form } = this.props;
    const { getFieldValue } = form;
    const phone = getFieldValue('mobile');
    return requestGetGraphicCode(phone);
  }
  popupGraphicCode = (phone) => {
    const {
      requestGetGraphicCode,
    } = this.props.loginStore;
    requestGetGraphicCode(phone)
      .then(() => {
        this.updateGraphicCodeStatus(true);
      });
  }
  loopTime = () => {
    this.setState({
      time: this.timeout,
    });
    this.timer = setInterval(() => {
      const { time } = this.state;
      if (time > 0) {
        this.setState({
          time: time - 1,
        });
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  }
  login = (loginWay, data) => {
    const { loginStore } = this.props;
    this.setLoading(true);
    // 重新登录移除本地token
    localStorage.removeItem('token');
    loginStore.requestLogin(loginWay, data)
      .then(this.onSuccess)
      .catch(this.onFailed);
  }
  onSuccess = () => {
    message.success({
      content: '登录成功',
      duration: 3,
    });
    this.setLoading(false);
    history.toHomepage();
  }
  onFailed = (error) => {
    const errMsg = getPathValue(error, 'response.data.msg') || "账号或验证码错误";
    message.error(errMsg);
    this.setLoading(false);
  }
  handlePhoneChange = (evt) => {
    const { value } = evt.target;
    if (value.length > 11) {
      evt.target.value = value.substr(0, 11);
    }
  }
  handleMobileBlur = () => {
    // 密码登录需要获取图形码
    const { loginWay } = this.state;
    if (loginWay === 'pwd') {
      this.getLoginGraphicCode();
    } else {
      const { updateState } = this.props.loginStore;
      updateState('graphicCode', null);
    }
  }
  render() {
    const {
      time,
      codeBtnIsDisabled,
      visibleOfGraphicCode,
      loginWay,
      graphicCodeLoading,
      loading,
    } = this.state;
    const {
      getFieldDecorator,
      getFieldError,
    } = this.props.form;
    const {
      graphicCode,
    } = this.props.loginStore;
    const graphicCodeProps = {
      visible: visibleOfGraphicCode,
      onOk: this.getCodeWithGraphic,
      onCancel: this.updateGraphicCodeStatus.bind(this, false),
      graphic: graphicCode,
      disabled: codeBtnIsDisabled,
      refreshGraphic: this.refreshGraphic,
    };
    return (
      <div className={styles.wrap}>
        <div className={styles.form}>
          <img className={styles.logo} src={logo} alt="logo" />
          <h2>欢迎登录SUPERZOO机构后台</h2>
          <div className={styles.tab}>
            {this.tabConfig.map(item => (
              <span
                className={classnames(styles.tabItem, {
                  [styles.active]: item.value === loginWay,
                })}
                key={item.value}
                onClick={this.changeTab.bind(this, item.value)}
              >
                {item.name}
              </span>
            ))}
          </div>
          <div className={styles.formItem}>
            {getFieldDecorator('mobile', {
              rules: [
                {required: true, message: '手机号码不能为空'},
                {pattern: /^1\d{10}$/, message: '手机号格式不正确，请输入正确的手机号'},
              ],
              validateTrigger: 'onBlur',
            })(
            <Input
              className={classnames(styles.mobile, {
                [styles.error]: getFieldError('mobile'),
              })}
              onChange={this.handlePhoneChange}
              onBlur={this.handleMobileBlur}
              placeholder="请输入手机号"
            />)}
            <div className={styles.errorMsg}>{getFieldError('mobile') && getFieldError('mobile')[0]}</div>
          </div>
          {loginWay === 'pwd' && <div className={styles.formItem}>
            {getFieldDecorator('password', {
              rules: [
                {required: true, message: '密码不能为空'},
              ],
              validateTrigger: 'onBlur',
            })(
            <Input
              className={classnames(styles.pwd, {
                [styles.error]: getFieldError('password'),
              })}
              type="password"
              onKeyDown={this.handleEnter}
              placeholder="请输入密码"
            />)}
            <div className={styles.errorMsg}>{getFieldError('password') && getFieldError('password')[0]}</div>
          </div>}
          {loginWay === 'pwd' && <div className={styles.formItem}>
            {getFieldDecorator('graphicCode', {
              rules: [
                {required: true, message: '图形码不能为空'},
                {pattern: /^\d{4}$/, message: '图形码格式不正确'},
              ],
              validateTrigger: 'onBlur',
            })(
            <Input
              className={classnames(styles.graphic, {
                [styles.error]: getFieldError('graphicCode'),
              })}
              maxLength={4}
              onKeyDown={this.handleEnter}
              placeholder="请输入图形码"
            />)}
            {graphicCode && <img
              className={classnames(styles.graphicCode, {
                [styles.disabled]: graphicCodeLoading,
              })}
              src={graphicCode}
              alt="图形码"
              onClick={this.getLoginGraphicCode}
            />}
            <div className={styles.errorMsg}>{getFieldError('graphicCode') && getFieldError('graphicCode')[0]}</div>
          </div>}
          {loginWay === 'code' && <div className={styles.formItem}>
            {getFieldDecorator('code', {
              rules: [
                {required: true, message: '短信验证码不能为空'},
                {len: 6, message: '短信验证码格式错误'},
              ],
              validateTrigger: 'onBlur',
            })(
            <Input
              className={classnames(styles.code, {
                [styles.error]: getFieldError('code'),
              })}
              onKeyDown={this.handleEnter}
              placeholder="请输入短信验证码"
            />)}
            <div
              className={classnames(styles.codeBtn, {
                [styles.disabled]: time > 0 || codeBtnIsDisabled,
              })}
              onClick={this.handleGetCode}
            >
              {time > 0 ? `${time}S后重新获取` : '获取验证码'}
            </div>
            <div className={styles.errorMsg}>{getFieldError('code') && getFieldError('code')[0]}</div>
          </div>}
          <Button
            type="primary"
            onClick={this.handleSubmit}
            className={styles.loginBtn}
            loading={loading}
          >
            登录
          </Button>
          <div className={styles.boxShadow2} />
          <div className={styles.boxShadow1} />
        </div>
        <GraphicCode {...graphicCodeProps} />
      </div>
    );
  }
}

export default Form.create()(Login);
