import CustomEnum from 'utils/customEnum';

/* 后端枚举 */

// 文章类型
export const ARTICLE_TYPE = new CustomEnum([
  ['', '全部类型'],
  // [1, '无图文章'],
  // [2, '视频文章'],
  [3, '文章'],
  [4, '图集'],
]);

// 文章发表状态
export const ARTICLE_PUBLISH_STATUS = new CustomEnum([
  ['', '全部状态'],
  [1, '已发表'],
  [2, '未发表'],
  [3, '已下线'],
  [4, '官方下线'],
]);

// 文章审核状态
export const ARTICLE_AUDIT_STATUS = new CustomEnum([
  ['', '全部状态'],
  [1, '审核中'],
  [2, '审核通过'],
  [3, '审核拒绝'],
  [4, '未提交审核'],
  [5, '取消审核'],
]);

// 文章草稿状态
export const ARTICLE_DRAFT_STATUS = new CustomEnum([
  ['', 'ddddd'],
  [1, '草稿'],
  [2, '修改'],
  [3, '无草稿'],
]);

// 文章筛选状态
export const ARTICLE_FILTER_STATE = new CustomEnum([
  ['', '全部状态'],
  [1, '未发表'],
  [2, '已发表'],
  [3, '已下线'],
  [4, '官方下线'],
  [5, '审核中'],
  [6, '审核被拒绝'],
]);

// 机构状态发表状态
export const EVENT_PUBLISH_STATUS = new CustomEnum([
  ['', '全部状态'],
  [0, '未发表'],
  [1, '已发表'],
]);

// 机构状态审核状态
export const EVENT_AUDIT_STATUS = new CustomEnum([
  ['', '全部状态'],
  [0, '审核拒绝'],
  [1, '待审核'],
  [2, '审核中'],
  [3, '审核通过'],
]);

// 咖啡豆明细/公益金明细类型
export const COFFEE_MONEY_TYPE = new CustomEnum([
  ['OTHER', '其他'],
  ['ONLINE_RECHARGE', '线上充值'],
  ['OFFLINE_RECHARGE', '充值'],
  ['SHOP_EXCHANGE_COUPON', '兑券'],
  ['SHOP_BUY_COUPON', '购券'],
  ['SHOP_ORDER', '点单'],
  ['LOVE_SHOP_EXCHANGE_COUPON', '机构后台兑券'],
  ['LOVE_SHOP_SALE_COUPON', '爱心店售券'],
  ['LOVE_SHOP_BUY_COUPON', '爱心店买券'],
  ['DONATE', '捐出咖啡豆'],
  ['BE_DONATE', '受捐咖啡豆'],
  ['BEANS_EXCHANGE_CHEST', '咖啡豆换公益金'],
  ['CHEST_WITHDRAW', '公益金提现'],
  ['SIGN_UP', '注册赠送'],
  ['INVITATION', '爱心接力'],
  ['SIGN', '签到'],
  ['LUCKY_DRAW', '抽奖活动'],
  ['READ_NEWS', '阅读文章'],
  ['SHARE_NEWS', '分享文章'],
]);

// 机构状态审核状态
export const LIVE_STATE = new CustomEnum([
  [4, '已结束'],
  [6, '已终止'],
]);
