import { observer } from 'mobx-react';
import React from 'react';
import { SmallBlock } from 'components/common';
import { thousandsSplit } from 'utils/common'
// import { Button } from 'antd';
import styles from './index.less';

const Balance = ({data}) => {
  // const EMPTY_TEXT = '/'
  return (
    <SmallBlock>
      <div className={styles.box}>
        <p className={styles.title}>公益金累计提现金额</p>
        <p className={styles.money}>
          <span>￥</span>{thousandsSplit(data.extractTotal)}
        </p>
      </div>
    </SmallBlock>
  )
};

export default observer(Balance);
