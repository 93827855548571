import { observer, inject } from 'mobx-react';
import React, { useState } from 'react';
import { SmallBlock } from 'components/common';
import { thousandsSplit, exBeansToMoney } from 'utils/common';
import { Button } from 'antd';
import BeansToMoney from './BeansToMoney';
import styles from './index.less';

const Beans = ({accountStore}) => {
  const {
    summary,
    requestExBeansToMoney,
    requestGetSummary,
    exStatus
  } = accountStore;
  const data = summary.data;
  const beansToMon = '￥' + exBeansToMoney(data.coffeeBeans);
  const [visible, setVisible] = useState(false)
  const closeModal = () => {
    setVisible(false)
  }
  const openModal = () => {
    setVisible(true)
  }
  const confirmEx = (params) => {
    return new Promise((resolve) => {
      requestExBeansToMoney(params)
        .then(()=>{
          resolve();
          requestGetSummary();
        });
    })
  }
  return (
    <SmallBlock>
      <div className={styles.box}>
        <p className={styles.title}>咖啡豆数量</p>
        <p className={styles.beans}>
          {thousandsSplit(data.coffeeBeans, 0)}
          <span>粒</span>
          <span className={styles.tip}>可直接兑换{beansToMon} </span>
        </p>
        <Button className={styles.btn} onClick={openModal}>兑换成公益金</Button>
        <BeansToMoney
          beans={data.coffeeBeans}
          visible={visible}
          onCancel={closeModal}
          onOk={confirmEx}
          requestExBeansToMoney={requestExBeansToMoney}
          loading={exStatus.loading}
        />
      </div>
    </SmallBlock>
  )
};

export default inject('accountStore')(observer(Beans));
