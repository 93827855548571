import React from 'react';
import { observer } from 'mobx-react';

import CashRecord from './CashRecord';
import CostPublicity from './CostPublicity';
import WelfareDetail from './WelfareDetail';
import CoffeeBeanDetail from './CoffeeBeanDetail';

const Content = ({
  tab,
  cashProps,
  costProps,
  welfareProps,
  beanProps,
}) => {
  return (
    <React.Fragment>
      {tab === 'CASH' && <CashRecord {...cashProps} />}
      {tab === 'COST' && <CostPublicity {...costProps} />}
      {tab === 'WELFARE' && <WelfareDetail {...welfareProps} />}
      {tab === 'BEAN' && <CoffeeBeanDetail {...beanProps} />}
    </React.Fragment>
  )
};

export default observer(Content);
