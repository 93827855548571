import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import {
  Content,
} from 'components/common';
import {
  Goods,
  OrderInfo,
} from 'components/store/orderDetail'

@withRouter
@inject('orderStore')
@observer
class OrderDetail extends React.Component {
  componentDidMount() {
    const { orderId } = this.props.match.params;
    const {
      requestOrderDetail,
    } = this.props.orderStore;
    requestOrderDetail(orderId);
  }
  componentWillUnmount() {
    const {
      orderStore
    } = this.props;
    const {
      resetState,
    } = orderStore;
    resetState();
  }
  render() {
    const {
      orderDetail,
      orderDetailDict,
    } = this.props.orderStore;
    return (
      <Content>
        <OrderInfo data={orderDetail} dict={orderDetailDict} />
        <Goods data={orderDetail}/>
      </Content>
    );
  }
}
export default OrderDetail;
