import React from 'react';
import classnames from 'classnames';

import styles from './index.less';

function Flow({ className, text }) {
  return (
    <div className={classnames(styles.wrap, className)}>
      <span className={styles.text}>{text}</span>
    </div>
  );
}

export default Flow;