import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Modal } from 'antd';

import { loadingAndError } from 'components/hoc';
import CardItem from './CardItem';
import ExchangeModal from './ExchangeModal';
import PayPwdModal from './PayPwdModal';
import ExSuccessModal from './ExSuccessModal';

import styles from './index.less';

const GoodsCard = ({ exchangeStore, loginStore }) => {
  const [cardItemData, setCardItemData] = useState()
  const [visible, setVisible] = useState(false)
  const [pwdVisible, setPwdVisible] = useState(false)
  const [exSuccessVis, setExSuccessVis] = useState(false)
  const { data } = exchangeStore.list;
  const {
    setExchangeDetail,
    setPayWd,
    exLoading,
    requestExchangeCoupon
  } = exchangeStore;
  const exHandle = (data) => {
    setCardItemData(data);
    setVisible(true)
  }
  const createItem = () => {
    return data.map((item, idx)=>{
      return <CardItem key={`card${idx}`} data={item} exHandle={exHandle}/>
    })
  }
  const confirmExChange = (count, id, costBeans) => {
    // 已设置支付密码需要弹框输入支付密码，否则直接兑换
    loginStore.requestJudgePayPwd()
      .then((hasPayPwd) => {
        if (hasPayPwd) {
          setExchangeDetail(count, id, costBeans);
          setPwdVisible(true);
        } else {
          Modal.info({
            title: '系统提示',
            content: '您还未设置支付密码，请先前往app设置支付密码！'
          }); 
        }
      })
      .catch(() => {
        setExchangeDetail(count, id, costBeans);
        setPwdVisible(true);
      })
  }
  const setPassWord = (pwd) => {
    setPayWd(pwd);
    return new Promise((resolve) => {
      requestExchangeCoupon()
        .then(()=>{
          closeExModal();
          closePwdModal();
          setExSuccessVis(true);
          resolve();
        });
    })
  }
  const closeExModal = () => {
    setVisible(false)
  }
  const closePwdModal = ()=> {
    setPwdVisible(false)
  }
  const closeSuccessModal = () => {
    setExSuccessVis(false)
  }
  return (
    <div className={styles.goodsBox}>
      {createItem()}
      <ExchangeModal
        data={cardItemData}
        visible={visible}
        onOk={confirmExChange}
        okLoading={exLoading}
        onCancel={closeExModal}
      />
      <PayPwdModal
        visible={pwdVisible}
        onOk={setPassWord}
        onCancel={closePwdModal}
        loading={exLoading}
      />
      <ExSuccessModal
        visible={exSuccessVis}
        onOk={closeSuccessModal}
      />
    </div>
  )
};

export default
  inject('exchangeStore', 'loginStore')(
    loadingAndError(({ exchangeStore })=>({
      loading: exchangeStore.list.loading,
      error: exchangeStore.list.error,
    }))(
      observer(GoodsCard)
    )
  );
