/**
 * 系统菜单配置
 */
export default [
  {
    name: '商店',
    order: 4,
    authId: 1,
    subMenus: [
      {
        name: '爱心订单',
        authId: 2,
        route: '/store/order',
        highlightRoute: [
          '/store/order/detail/:orderId',
        ],
        iconCss: 'order',
      },
      {
        name: '兑换',
        authId: 3,
        route: '/store/exchange',
        highlightRoute: [
          '/store/exchange/history',
        ],
        iconCss: 'exchange',
      },
      {
        name: '在售商品',
        authId: 4,
        route: '/store/goods',
        iconCss: 'goods',
      },
    ],
  },
  {
    name: '内容',
    order: 1,
    authId: 5,
    subMenus: [
      {
        name: '内容管理',
        authId: 6,
        route: '/article/manage',
        highlightRoute: [
          '/article/modify/mixin/:articleId',
          '/article/modify/imgs/:articleId',
        ],
        iconCss: 'manage',
      },
      {
        name: '发表图文',
        authId: 7,
        route: '/article/publish/mixin',
        iconCss: 'mixin',
      },
      {
        name: '发表纯图',
        authId: 8,
        route: '/article/publish/imgs',
        iconCss: 'imgs',
      },
    ],
  },
  {
    name: '直播管理',
    order: 2,
    authId: 19,
    subMenus: [
      {
        name: '线上直播',
        authId: 20,
        route: '/live/onlineLive',
        iconCss: 'live',
      },
    ],
  },
  {
    name: '活动',
    order: 3,
    authId: 16,
    subMenus: [
      {
        name: '活动管理',
        authId: 17,
        route: '/activity/manage',
        highlightRoute: [
          '/activity/modify/:id',
          '/activity/audit/:id',
          '/activity/data/:id',
        ],
        iconCss: 'activity_manage',
      },
      {
        name: '新建活动',
        authId: 18,
        route: '/activity/publish',
        highlightRoute: [
          '/activity/publish',
        ],
        iconCss: 'activity_publish',
      },
    ],
  },
  {
    name: '机构',
    order: 5,
    authId: 9,
    subMenus: [
      {
        name: '信息设置',
        authId: 10,
        route: '/org/info',
        iconCss: 'info',
      },
      {
        name: '账户管理',
        authId: 11,
        route: '/org/account',
        iconCss: 'account',
      },
      {
        name: '捐赠管理',
        authId: 12,
        route: '/org/donation',
        iconCss: 'donation',
      },
      {
        name: '机构公告',
        authId: 13,
        route: '/org/notice',
        iconCss: 'notice',
      },
      // v1.3暂时下掉动态模块
      // {
      //   name: '机构动态',
      //   authId: 14,
      //   route: '/org/event',
      //   highlightRoute: [
      //     '/org/event/publish',
      //     '/org/event/modify/:eventId',
      //   ],
      //   iconCss: 'event',
      // },
      {
        name: '常见问题',
        authId: 15,
        route: '/org/FAQ',
        iconCss: 'FAQ',
      },
    ],
  },
];
