import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Filter,
  List,
} from 'components/store/order'
import {
  Content,
} from 'components/common';

@inject('orderStore')
@observer
class Order extends React.Component {
  componentDidMount() {
    const {
      requestLoveOrder,
    } = this.props.orderStore;
    requestLoveOrder();
  }
  render() {
    return (
      <Content>
        <Filter />
        <List />
      </Content>
    );
  }
}
export default Order;
