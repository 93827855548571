import React from 'react';
import { observer } from 'mobx-react';

import styles from './index.less';

function AppContent({ children }) {
  return (
    <div className={styles.wrap}>
      {children}
    </div>
  );
}

export default observer(AppContent);
