import React, { useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Input, Select, DatePicker } from 'antd';

import { SearchButton } from 'components/common'
import styles from './index.less'

const Option = Select.Option;
const { RangePicker } = DatePicker;

const Filter = ({ goodsStore }) => {
  const {
    filter,
    updateState,
    requestGetGoodsStock,
  } = goodsStore;
  const {
    goodsName,
    startCTime,
    endCTime,
    startPrice,
    endPrice,
  } = filter;
  const [
    goodsNameVal,
    setGoodsNameVal,
  ] = useState(goodsName);
  const [
    startCTimeVal,
    setStartCTimeVal,
  ] = useState(startCTime);
  const [
    endCTimeVal,
    setEndCTimeVal,
  ] = useState(endCTime);
  const [
    startPriceVal,
    setStartPrice,
  ] = useState(startPrice);
  const [
    endPriceVal,
    setEndPrice,
  ] = useState(endPrice);
  const handleGoodsNameChange = (evt) => {
    const { value } = evt.target;
    setGoodsNameVal(value);
  }
  const handleStartPriceChange = (evt) => {
    const { value } = evt.target;
    setStartPrice(value);
  }
  const handleEndPriceChange = (evt) => {
    const { value } = evt.target;
    setEndPrice(value);
  }
  const changeDate = (date) => {
    setStartCTimeVal(date[0]);
    setEndCTimeVal(date[1]);
  }
  const handleSearch = () => {
    updateState('filter', Object.assign(filter, {
      goodsName: goodsNameVal,
      startCTime: startCTimeVal,
      endCTime: endCTimeVal,
      startPrice: startPriceVal,
      endPrice: endPriceVal,
    }));
    updateState('pager.pageNo', 1);
    requestGetGoodsStock();
  }
  const classItem = [
    {
      label: '积分兑换',
      value: '积分兑换',
    },
  ]
  const renderOptions = () => {
    return classItem.map((option, optionIndex)=>{
      return (
        <Option
          key={optionIndex}
          value={option.value}
        >
          {option.label}
        </Option>
      )
    })
  }
  return ( 
    <div className={styles.wrap} >
      <Input
        className={styles.searchInput}
        placeholder="输入商品名称"
        onChange={handleGoodsNameChange}
        value={goodsNameVal}
      />
      <RangePicker
        className={styles.rangePicker}
        placeholder={['兑换开始时间', '兑换结束时间']}
        onChange={changeDate}
        value={[startCTimeVal, endCTimeVal]}
        />
      <div className={styles.priceBox}>
        <Input
          className={styles.searchInput}
          placeholder="价格起"
          onChange={handleStartPriceChange}
          value={startPriceVal}
        />
        <span>至</span>
        <Input
          className={styles.searchInput}
          placeholder="价格至"
          onChange={handleEndPriceChange}
          value={endPriceVal}
        />
      </div>
      <Select
        className={styles.select}
        value="积分兑换"
      >
        {renderOptions()}
      </Select>
      <SearchButton
        onClick={handleSearch}
      />
    </div>
   );
}
 
export default inject('goodsStore')(observer(Filter));