import { observer } from 'mobx-react';
import React from 'react';
import { message } from 'antd';

import { Upload } from 'components/common';
import { Icon } from 'antd';
import styles from './index.less';

const UploadImg = ({imgs, addImg, delImg, limit=5}) => {
  const onChange = (files) => {
    if (imgs.length >= limit) {
      message.warning(`最多上传${limit}张`);
    } else {
      addImg(files[0]);
    }
  }
  const removeImg = (idx) => {
    delImg(idx)
  }
  const preViewImg = () => {
    return  imgs.map((imgUrl, idx)=>{
      return (
        <div className={styles.preViewItem} key={imgUrl}>
          <img src={imgUrl} alt=""/>
          <i className={styles.delBtn} onClick={removeImg.bind(null, idx)}></i>
        </div>
      )
    })
  }
  return (
    <div>
      <p className={styles.title}>
        上传票据照片凭证
        <span>最多上传{limit}张，每张不超过1M</span>
      </p>
      <div className={styles.imgsBox}>
        {imgs.length < limit && (
          <div className={styles.uploadBox}>
            <Upload
              key="upload"
              accept="image/*"
              onChange={onChange}
            >
              <div className={styles.addBtn}>
                <Icon className={styles.icon} type="plus-circle" />
                <p>点击添加</p>
              </div>
            </Upload>
          </div>
        )}
        {preViewImg()}
      </div>
    </div>
  )
};

export default observer(UploadImg);
