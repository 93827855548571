import React from 'react';
import { observer } from 'mobx-react';
import {
  getRegisterStateText,
}  from 'components/activity/common/utils';

import styles from './index.less';

function RegisterState({value}) {
  return <span className={styles[`state_${value}`]}>{getRegisterStateText(value)}</span>;
}

export default observer(RegisterState);