import initStore from 'utils/initStore';
import { getPathValue } from 'pathval';
import { orgApi } from 'api';

const FaqStore = initStore({
  state() {
    return {
      // 常见问题列表
      faqListIsReady: false,
      faqList: [],
      faqError: null,
      // 常见问题列表分页(客户端分页)
      faqPager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.faqPager.index = current;
        }
      }
    };
  },
  actions: {
    requestGetFaqList() {
      const params = {
        code: 'DCB-23',
      };
      this.faqListIsReady = false;
      orgApi.getFaqList(params)
        .then(resp => {
          const data = getPathValue(resp, 'data.data.valueList');
          if (!data || data.length === 0) {
            return Promise.reject(new Error('暂无数据'));
          }
          this.faqListIsReady = true;
          this.faqList = data;
          this.faqError = null;
          this.faqPager.total = data.length;
        })
        .catch(err => {
          console.log(err, "<===获取常见问题通知列表失败");
          this.faqListIsReady = true;
          this.faqList = [];
          this.faqError = new Error('暂无数据');
        });
    },
  }
});

export default new FaqStore();
