import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import styles from './index.less';

function SaveRecipientModal({ visible, onCancel, loading, onOk }) {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      cancelText="关闭"
      okText="保存"
      onOk={onOk}
      centered
      okButtonProps={{ loading } }>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>收款信息</h2>
        <p>请仔细核对收款账号信息，保存后默认此账号，下次提现如有变动请及时修改</p>
      </div>
    </Modal>
  )
}

export default observer(SaveRecipientModal);
