import { message } from 'antd';
import initStore from 'utils/initStore';
import { articleApi } from 'api';

const ArticlePublishStore = initStore({
  state() {
    return {
      // 后端返回的文章详情
      articleIsReady: false,
      article: {},
    };
  },
  actions: {
    requestGetArticleDetail(articleId) {
      this.articleIsReady = false;
      return new Promise((resolve, reject) => {
        articleApi.getArticleDetail(articleId)
          .then(resp => {
            this.article = resp.data.data;
            this.articleIsReady = true;
            resolve(this.article);
          })
          .catch(err => {
            console.log(err, '<===获取文章详情失败');
            this.articleIsReady = true;
            this.article = {};
            message.error(err.response.data.msg || '获取文章详情失败');
            reject(err);
          });
      });
    },
    requestPublishArticle(articleId) {
      return new Promise((resolve, reject) => {
        articleApi.publishArticle(articleId)
          .then(() => {
            message.success('发表文章成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '发表文章失败');
            const { msg } = err.response.data;
            message.error(msg || '发表文章失败');
            reject(err);
          });
      })
    },
    requestSaveArticle(postData, silent) {
      return new Promise((resolve, reject) => {
        articleApi.saveArticle(postData)
          .then(resp => {
            // silent为true则不显示提示
            if (!silent) {
              message.success('保存文章成功');
            }
            resolve(resp.data.data);
          })
          .catch(err => {
            console.log(err, '保存文章失败');
            const { msg } = err.response.data;
            message.error(msg || '保存文章失败');
            reject(err);
          });
      })
    },
    requestModifyArticle(putData) {
      return new Promise((resolve, reject) => {
        articleApi.modifyArticle(putData)
          .then(resp => {
            message.success('修改文章成功');
            resolve(resp.data.data);
          })
          .catch(err => {
            console.log(err, '修改文章失败');
            const { msg } = err.response.data;
            message.error(msg || '修改文章失败');
            reject(err);
          });
      })
    },
  },
});

export default new ArticlePublishStore();
