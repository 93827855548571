import React from 'react';
import { observer } from 'mobx-react';

import styles from './index.less';

function AppMain({ children }) {
  return (
    <div style={{minWidth: 1200}} className={styles.wrap}>
      {children}
    </div>
  );
}

export default observer(AppMain);
