import { observer } from 'mobx-react';
import React from 'react';
import { Title, KvCard } from '../common'
import styles from './index.less';

const LoginType = ({data}) => {
  const config = [
    { key: 'phone', label: '绑定手机号'},
  ]
  return (
    <div className={styles.box}>
      <Title>登录方式</Title>
      <KvCard className={styles.kvCard} data={data} config={config}></KvCard>
   </div>
  )
};

export default observer(LoginType);