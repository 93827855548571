import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import styles from './index.less';

function Detail({ data, ...modalProps }) {
  return (
    <Modal
      width="41.25rem"
      footer={null}
      {...modalProps}
      destroyOnClose
    >
      <h3 className={styles.title}>{data.title}</h3>
      <p
        className={styles.content}
        dangerouslySetInnerHTML={{__html: data.content}}
      />
    </Modal>
  );
}

export default observer(Detail);
