import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import { ConfigProvider, Modal } from 'antd';
import zh_CN from 'antd/es/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
import { stringify } from 'query-string';

import history from 'router/history';
import stores from 'stores';
import App from 'containers/App';

// 全局api请求设置
axios.interceptors.request.use(
  axiosConfig => {
    // 配置项目唯一appid
    axiosConfig.headers['appid'] = 200103;
    axiosConfig.headers['Cache-Control'] = 'no-cache';
    const token = localStorage.getItem('token');
    if (token) {
      axiosConfig.headers['Authorization'] = token;
    }
    // 对get请求的参数进行编码,并添加时间戳防止缓存
    if (!axiosConfig.params) {
      axiosConfig.url = encodeURI(axiosConfig.url);
      axiosConfig.params = {
        timestamp: new Date().getTime()
      };
    } else {
      // 深拷贝axiosConfig.params
      const paramsData = JSON.parse(JSON.stringify(axiosConfig.params));
      axiosConfig.params = paramsData;
      axiosConfig.paramsSerializer = function(params) {
        return stringify(params, { arrayFormat: 'repeat' })
      }
      axiosConfig.params.timestamp = new Date().getTime();
    }
    return axiosConfig;
  },
  error => {
    console.log('request error', error);
  }
);
// 全局api错误拦截
let hasGuard = false;
axios.interceptors.response.use(
  response => {
    /* 处理原因: 后端api不规范 */
    // 1 错误信息会存在msg和message两种情况->解决方式: 统一合并为msg
    // 2 部分状态码非200的请求返回的200->解决方式: 根据code是否存在抛错进入axios.catch
    const { code, msg, message } = response.data;
    if (code && code !== 200) {
      return Promise.reject({
        status: code,
        response: {
          data: {
            code,
            msg: msg || message,
          }
        }
      });
    }
    return response;
  },
  error => {
    // api cancel error直接返回
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    const { status: httpStatus } = error.response;
    if (!hasGuard) {
      if (httpStatus > 500) {
        hasGuard = true;
        Modal.confirm({
          title: '系统通知',
          content: '服务器正在维护，请稍等',
          okText: '知道了',
          cancelText: '刷新页面',
          onOk: () => {
            history.toLoginPage();
            hasGuard = false;
          },
          onCancel: () => {
            window.location.reload();
          }
        });
        // 中断请求
        return new Promise(() => {});
      }
      // 根据后端错误码做相应操作
      const { msg, message } = error.response.data;
      delete error.response.data.msg;
      error.response.data.msg = msg || message;
      // 401表示未登录
      if (httpStatus === 401 || httpStatus === 403) {
        hasGuard = true;
        Modal.confirm({
          title: ({
            401: '权限不够',
            403: '登录超时',
          })[httpStatus],
          content: ({
            401: '请先申请成为大V账号或者机构账号',
            403: '请重新登录',
          })[httpStatus],
          okText: '重新登录',
          cancelText: '刷新页面',
          onOk: () => {
            history.toLoginPage();
            hasGuard = false;
          },
          onCancel: () => {
            window.location.reload();
          }
        });
        // 中断请求
        return new Promise(() => {});
      }
    }
    return Promise.reject(error);
  }
);
ReactDOM.render(
  (
    <Provider {...stores}>
      <Router history={history}>
        <ConfigProvider locale={zh_CN}>
          <App />
        </ConfigProvider>
      </Router>
    </Provider>
  ),
  document.getElementById('root')
);
