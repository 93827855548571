export default class CustomEnum {
  constructor(value) {
    this.enum = new Map(value);
  }
  key = (value) => {
    for (let key of this.enum.keys()) {
      if (this.enum.get(key) === value) {
        return key;
      }
    }
    return null;
  }
  value = (key) => {
    return this.enum.get(key);
  }
  toOptions = () => {
    const options = [];
    for (let key of this.enum.keys()) {
      options.push({label: this.value(key), value: key});
    }
    return options;
  }
}
