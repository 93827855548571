
import React from 'react';
import { observer, inject } from 'mobx-react';
import { List as CommonList } from 'components/common';
import { Button } from 'antd';

import { modifyMoney } from 'utils/common'
import history from 'router/history'
import { UPLOAD_URL_PREFIX } from 'config/upload';

import styles from './index.less'

const List = ({ orderStore }) => {
  const {
    filter,
    list,
    pager,
    updateState,
    requestLoveOrder
  } = orderStore;
  const {
    loading,
    data,
    error
  } = list;
  const {
    pageNo,
    pageSize,
    total,
  } = pager;
  const gotoOrderDetail = (orderId) => {
    history.push(`/store/order/detail/${orderId}`);
  }
  const renderOperation = (value, record) => {
    return (
      <Button
        onClick={gotoOrderDetail.bind(null, record.orderId)}
      >
        详情
      </Button>
    );
  }
  const renderGoodsInfo = (value, record) => {
    return (
      <div className={styles.goods}>
        <img src={`${UPLOAD_URL_PREFIX.COUPON_PREFIX}${value[0].couponImg}`} alt="" />
        <div>
          <p className={styles.goodsName}>{value[0].couponName}</p>
          <p className={styles.orderId}>订单编号：{record.tradeId}</p>
        </div>
      </div>
    );
  }
  const renderPrice = (value, record) => {
    return (
      <div>{modifyMoney(record.item[0].price)}</div>
    );
  }
  const renderUser = (value, record) => {
    return (
      <div>
        <p className={styles.userName}>{value}</p>
        <p className={styles.phone}>{record.phone}</p>
      </div>
    );
  }
  const listProps = {
    rowKey: "orderId",
    columns: [
      {
        key: 'item',
        dataIndex: 'item',
        title: '商品',
        width: '25%',
        render: renderGoodsInfo
      },
      {
        key: 'price',
        dataIndex: 'price',
        title: '单价',
        width: '10%',
        render: renderPrice
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: '数量',
        width: '10%',
      },
      {
        key: 'username',
        dataIndex: 'username',
        title: '买家',
        width: '10%',
        render: renderUser
      },
      {
        key: 'totalPrice',
        dataIndex: 'totalPrice',
        title: '支付金',
        width: '10%',
        renderType: 'money'
      },
      {
        key: 'realIncome',
        dataIndex: 'realIncome',
        title: '实收',
        width: '10%',
        renderType: 'money'
      },
      {
        key: 'ctime',
        dataIndex: 'ctime',
        title: '购买时间',
        width: '15%',
      },
      {
        key: 'operate',
        dataIndex: 'operate',
        title: '操作',
        width: '10%',
        render: renderOperation,
      },
    ],
    loading,
    params: Object.values(filter).some(val => val),
    data,
    error,
    onChange: (pagination) => {
      updateState('pager.pageNo', pagination.current);
      requestLoveOrder();
    },
    pagination: {
      current: pageNo,
      pageSize: pageSize,
      total: total,
      showQuickJumper: true,
    },
  }
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default inject('orderStore')(observer(List));
