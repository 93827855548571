import { observer } from 'mobx-react';
import React from 'react';
import { Spin } from 'antd';
import styles from './index.less';

const Loading = ({ className }) => {
  const cssName = className ? `${styles.box} ${className}` : styles.box;
  return (
    <div className={cssName}>
      <Spin size="large" />
    </div>
  )
};

export default observer(Loading);