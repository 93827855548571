import { observer } from 'mobx-react';
import React from 'react';
import { Icon } from 'antd';
import { Title } from '../common';
import  {
  Upload
} from 'components/common'
import styles from './index.less';

const BodyPic = ({data, requestUploadImg}) => {
  const onChange = (files) => {
    const formData = new FormData();
    formData.append('upfile', files[0])
    requestUploadImg(formData, 16, 'background')
  }
  return (
    <div className={styles.box}>
      <Title>{data.background ? '修改' : '添加'}形象照</Title>
      <div className={styles.imgBox}>
        <Upload
          listType="picture-card"
          onChange={onChange}>
          {
            data.background ?
            <img src={data.background} alt=""/>
            :
            <div className={styles.uploadBox}>
              <Icon className={styles.icon} type="plus-circle" theme="filled" />
              <p className={styles.title}>点击添加形象照</p>
              <div className={styles.tipInfo}>
                <p>支持JPG/PNG格式，</p>
                <p>建议尺寸750*580px，大小不要超过1M</p>
              </div>
            </div>
          }
        </Upload>
      </div>
    </div>
  )
};

export default observer(BodyPic);
