import React from 'react';
import { observer } from 'mobx-react';

import { thousandsSplit } from 'utils/common';
import { COFFEE_MONEY_TYPE } from 'api/enum';
import {
  List as CommonList,
} from 'components/common';

import styles from './index.less';

function WelfareDetail({
  loading,
  data,
  error,
  pager={},
}) {
  const typeMap = {
    EXPEND: '-',
    EARN: '+',
  };
  const renderWay = (val) => {
    return COFFEE_MONEY_TYPE.value(val) || '/';
  }
  const renderMoney = (val) => {
    return `¥${thousandsSplit(val)}`;
  }
  const renderAmount = (val, record) => {
    let { type } = record;
    return <span className={styles[`amount_${type}`]}>{typeMap[type] || ''}{renderMoney(val)}</span>;
  }
  const listProps = {
    columns: [
      {
        key: 'ctime',
        title: '创建时间',
        dataIndex: 'ctime',
        width: '16%',
      },
      {
        key: 'tradeId',
        title: '单号',
        dataIndex: 'tradeId',
        width: '16%',
      },
      {
        key: 'mobile',
        title: '对方信息',
        dataIndex: 'mobile',
        width: '16%',
      },
      {
        key: 'way',
        title: '类型',
        dataIndex: 'way',
        width: '18%',
        render: renderWay,
      },
      {
        key: 'amount',
        title: '收支金额',
        dataIndex: 'amount',
        width: '16%',
        render: renderAmount,
      },
      {
        key: 'surplusAmount',
        title: '公益金余额',
        dataIndex: 'surplusAmount',
        width: '16%',
        render: renderMoney,
      },
    ],
    rowKey: (record, index) => index,
    loading,
    dataSource: data,
    error,
    pager,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(WelfareDetail);
