import React from 'react';
import { observer } from 'mobx-react';
import { loadingAndError } from 'components/hoc';
import { Title } from 'components/activity/common';
import {
  getStateText,
  getTypeText,
  getRangeTimeText,
}  from 'components/activity/common/utils';
import {
  KeyValues,
  Statistics,
} from '../../common';

import styles from './index.less';

function Info({ data }) {
  const keyValuesConfig = [
    {
      name: '活动状态',
      dataIndex: 'state',
      render: getStateText,
    },
    {
      name: '活动人数',
      dataIndex: 'applyLimit',
      render: (value) => {
        if (value === 0) {
          return '不限';
        }
        return `${value || 0}人`;
      },
    },
    {
      name: '活动名称',
      dataIndex: 'title',
    },
    {
      name: '活动时间',
      dataIndex: 'time',
      render(value, record) {
        return getRangeTimeText(record);
      },
    },
    {
      name: '活动类型',
      dataIndex: 'type',
      render: getTypeText,
    },
    {
      name: '活动地址',
      dataIndex: 'addr',
    },
  ];
  const statisticsConfig = [
    {name: '待审核', dataIndex: 'audit'},
    {name: '审核通过', dataIndex: 'passAudit'},
    {name: '审核拒绝', dataIndex: 'rejectAudit'},
  ];
  return (
    <div className={styles.wrapper}>
      <Title className={styles.title} text="活动信息" />
      <div className={styles.right}>
        <KeyValues config={keyValuesConfig} data={data} />
        <Statistics config={statisticsConfig} data={data} />
      </div>
    </div>
  );
}

export default loadingAndError(props => ({
  loading: props.loading,
  error: props.error,
  className: styles.loadingBox,
}))(observer(Info));