import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'antd';
import { Content } from 'components/common';
import { HorizontalHr } from 'components/activity/common';
import { Flow, Info, Setting, Preview } from 'components/activity/publish';
import history from 'router/history';
import styles from './index.less';

@withRouter
@inject('activityPublishStore', 'loginStore')
@observer
class ActivityPublish extends React.Component {
  state = {
    showPreview: false,
    previewData: null,
  };
  componentDidMount() {
    const id = this.getId();
    if (id) {
      this.props.activityPublishStore.requestGetDetail(id);
    }
  }
  componentWillUnmount() {
    this.props.activityPublishStore.resetState();
  }
  getId = () => {
    const {
      match,
    } = this.props;
    // 获取地址栏带过来的活动id
    const { id } = match.params;
    if (id) {
      return Number(id);
    }
    return null;
  }
  handleSave = () => {
    this.validateForm(formData => {
      this.props.activityPublishStore.requestSaveActivity(formData)
        .then(() => {
          history.toActivityManagePage();
        });
    });
  }
  handlePreview = () => {
    this.validateForm(formData => {
      console.log(formData)
      this.setState({
        showPreview: true,
        previewData: formData,
      });
    });
  }
  handleAudit = () => {
    this.validateForm(formData => {
      this.props.activityPublishStore.requestAuditActivity(formData)
        .then(() => {
          history.toActivityManagePage();
        });
    });
  }
  closePreview = () => {
    this.setState({
      showPreview: false,
      previewData: null,
    });
  }
  validateForm = (cb) => {
    const { form } = this.props;
    const fields = ['title', 'time', 'limit', 'type', 'poster', 'detail', 'audit', 'registerTime'];
    const type = form.getFieldValue('type');
    if (type === 'OFF_LINE_SHARE') {
      fields.push('location', 'addr');
    }
    form.validateFields(fields, (errors, values) => {
      if (!errors) {
        const formData = {
          title: values.title,
          startTime: values.time[0].format('YYYY-MM-DD HH:mm:ss'),
          expireTime: values.time[1].format('YYYY-MM-DD HH:mm:ss'),
          applyLimit: values.limit,
          type: values.type,
          cover: values.poster,
          content: values.detail.toHTML().replace(/<p><\/p>/g, '<p><br></p>'),
          autoAudit: values.audit,
          applyStartTime: values.registerTime[0].format('YYYY-MM-DD HH:mm:ss'),
          applyExpireTime: values.registerTime[1].format('YYYY-MM-DD HH:mm:ss'),
        };
        // 选择线下类活动时带上地址信息
        if (type === 'OFF_LINE_SHARE') {
          const [province, city, district] = values.location;
          const [provinceCode, cityCode, districtCode] = form.getFieldValue('locationCode');
          Object.assign(formData, {
            province,
            provinceCode,
            city,
            cityCode,
            district,
            districtCode,
            street: values.addr,
          });
        }
        // 带上活动id（编辑时）
        const activityId = this.getId();
        if (activityId) {
          formData.id = activityId;
        }
        cb(formData);
      }
    });
  }
  render() {
    const { showPreview, previewData } = this.state;
    const { form, activityPublishStore, loginStore } = this.props;
    const { userInfo } = loginStore;
    const {
      detailLoading,
      detail,
      saveLoading,
      auditLoading,
    } = activityPublishStore;
    const id = this.getId();
    const infoProps = {
      form,
      data: detail,
    };
    const settingProps = {
      form,
      data: detail,
    };
    if (id && detailLoading) {
      return null;
    }
    return (
      <Content>
        <Flow />
        <HorizontalHr />
        <Info {...infoProps} />
        <HorizontalHr />
        <Setting {...settingProps} />
        <Preview
          visible={showPreview}
          dataSource={previewData}
          orgInfo={userInfo}
          onCancel={this.closePreview}
        />
        <div className={styles.btnGroup}>
          <Button type="primary" loading={auditLoading} onClick={this.handleAudit}>提交审核</Button>
          <Button type="" loading={saveLoading} onClick={this.handleSave}>本地保存</Button>
          <Button type="" onClick={this.handlePreview}>预览</Button>
        </div>
      </Content>
    );
  }
}

export default Form.create()(ActivityPublish);
