import React from 'react';
import { observer } from 'mobx-react';
import { message } from 'antd';

import {
  List as CommonList,
  OperateBtn,
} from 'components/common';
import {
  ARTICLE_PUBLISH_STATUS,
  ARTICLE_AUDIT_STATUS,
  ARTICLE_DRAFT_STATUS,
} from 'api/enum';

import styles from './index.less';

function List({
  loading,
  data,
  // checkedData, 暂时下掉checkbox
  error,
  pager={},
  params,
  // onCheck, 暂时下掉checkbox
  // onPublish,
  onViewDetail,
  onViewReason,
  onModify,
  onCancelAudit,
  onOffline,
  onOnline,
  onDelete,
  // onBatchDel, 暂时下掉批量删除
}) {
  // 文章类型映射
  const artTypeMap = {
    '1': '文章',
    '2': '文章',
    '3': '文章',
    '4': '图集',
  };
  // const handlePublish = (record) => {
  //   onPublish(record);
  // }
  const handleViewDetail = (record) => {
    onViewDetail(record);
  }
  const handleViewReason = (record) => {
    onViewReason(record);
  }
  const handleModify = (record, auditStatusName) => {
    if (auditStatusName === '审核中') {
      message.warn('该文章正在审核中，请先撤销审核');
    } else {
      onModify(record);
    }
  }
  const handleCancelAudit = (record) => {
    onCancelAudit(record);
  }
  const handleOffline = (record, auditStatusName) => {
    if (auditStatusName === '审核中') {
      message.warn('该文章正在审核中，请先撤销审核');
    } else {
      onOffline(record);
    }
  }
  const handleOnline = (record, auditStatusName) => {
    if (auditStatusName === '审核中') {
      message.warn('该文章正在审核中，请先撤销审核');
    } else {
      onOnline(record);
    }
  }
  const handleDelete = (record, auditStatusName) => {
    if (auditStatusName === '审核中') {
      message.warn('该文章正在审核中，请先撤销审核');
    } else {
      onDelete(record);
    }
  }
  // 表格title栏
  const renderTitle = () => {
    return (
      <div className={styles.header}>
        <span className={styles.count}>{`共${pager.total}条记录`}</span>
      </div>
    );
  }
  // 文章信息列
  const renderInfo = (val, record) => {
    return (
      <div className={styles.infoCol}>
        <div className={styles.infoBox}>
          <p
            className={styles.artTitle}
            onClick={handleViewDetail.bind(null, record)}
          >
            {record.title}
          </p>
          <span className={styles.artDetail}>
            <span className={styles.artType}>{artTypeMap[record.newsType] || '文章'}</span>
            <span className={styles.artId}>ID {record.id}</span>
          </span>
        </div>
      </div>
    );
  }
  // key value上下排列的列
  const renderPeerValue = (name, val) => {
    return (
      <span className={styles.peerValue}>
        <span>{name}</span>
        <span>{val || 0}</span>
      </span>
    );
  }
  // 草稿状态列
  const renderDraftsStatus = (val) => {
    const statusName = ARTICLE_DRAFT_STATUS.value(val);
    if (['无草稿'].includes(statusName)) {
      return null;
    }
    return (
      <span className={styles[`draftStatus_${val}`]}>
        {statusName}
      </span>
    );
  }
  // 发表状态列
  const renderPublishStates = (val) => {
    const statusName = ARTICLE_PUBLISH_STATUS.value(val);
    return (
      <span className={styles[`publishStatus_${val}`]}>
        {statusName}
      </span>
    );
  }
  // 审核状态列
  const renderAuditStatus = (val) => {
    const statusName = ARTICLE_AUDIT_STATUS.value(val);
    if (['审核通过', '取消审核', '未提交审核'].includes(statusName)) {
      return null;
    }
    return (
      <span className={styles[`auditStatus_${val}`]}>
        {statusName || '/'}
      </span>
    );
  }
  // 操作列,只有当状态满足某些条件时,按钮才会显示
  const renderOperate = (val, record) => {
    const auditStatusName = ARTICLE_AUDIT_STATUS.value(record.auditStates);
    const publishStatusName = ARTICLE_PUBLISH_STATUS.value(record.publishStates);
    return (
      <div className={styles.optBox}>
        <OperateBtn
          text="查看"
          show={true}
          onClick={handleViewDetail.bind(null, record)}
        />
        {/* 暂时下掉列表上面的发布按钮 */}
        {/* <OperateBtn
          text="发表"
          show={['待审核', '取消审核', '审核拒绝'].includes(auditStatusName)}
          onClick={handlePublish.bind(null, record)}
        /> */}
        <OperateBtn
          text="查看被拒绝原因"
          show={['审核拒绝'].includes(auditStatusName)}
          onClick={handleViewReason.bind(null, record)}
        />
        <OperateBtn
          text="修改"
          show={['未发表', '已发表', '已下线'].includes(publishStatusName)}
          onClick={handleModify.bind(null, record, auditStatusName)}
        />
        <OperateBtn
          text="取消审核"
          show={['审核中'].includes(auditStatusName)}
          onClick={handleCancelAudit.bind(null, record)}
        />
        <OperateBtn
          text="下线"
          show={['已发表'].includes(publishStatusName)}
          onClick={handleOffline.bind(null, record, auditStatusName)}
        />
        <OperateBtn
          text="上线"
          show={['已下线'].includes(publishStatusName)}
          onClick={handleOnline.bind(null, record, auditStatusName)}
        />
        <OperateBtn
          text="删除"
          show={['未发表', '已下线'].includes(publishStatusName)}
          onClick={handleDelete.bind(null, record, auditStatusName)}
        />
      </div>
    );
  }
  const listProps = {
    columns: [
      {
        key: 'info',
        title: '文章信息',
        dataIndex: 'info',
        width: '30%',
        render: renderInfo,
      },
      {
        key: 'totalLook',
        title: '阅读',
        dataIndex: 'totalLook',
        width: '10%',
        render: renderPeerValue.bind(null, '阅读'),
      },
      {
        key: 'totalShare',
        title: '分享',
        dataIndex: 'totalShare',
        width: '10%',
        render: renderPeerValue.bind(null, '分享'),
      },
      {
        key: 'draftsStatus',
        title: '草稿状态',
        dataIndex: 'draftsStatus',
        width: '7%',
        render: renderDraftsStatus,
      },
      {
        key: 'publishStates',
        title: '文章状态',
        dataIndex: 'publishStates',
        width: '8%',
        render: renderPublishStates,
      },
      {
        key: 'auditStates',
        title: '审核状态',
        dataIndex: 'auditStates',
        width: '10%',
        render: renderAuditStatus,
      },
      {
        key: 'operate',
        title: '操作',
        dataIndex: 'operate',
        width: '25%',
        render: renderOperate,
      },
    ],
    rowKey: 'id',
    showHeader: false,
    loading,
    dataSource: data,
    params,
    error,
    pager,
    title: renderTitle,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(List);
