import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Icon, Input  } from 'antd';
import Modal from 'components/common/Modal';
import styles from './index.less';
const ExcangeModal = ({visible, onOk, onCancel, loading}) => {
  const [pwd, setPwd] = useState('');
  useEffect(() => {
    if (!visible) {
      setPwd('');
    }
  }, [visible]);
  const okHandle = () => {
    onOk(pwd)
  }
  const changePassWord = (evt) => {
    setPwd(evt.target.value)
  }
  const okButtonProps = {
    loading: loading
  }
  return (
    <Modal
        visible={visible}
        cancelText="关闭"
        okText="确定"
        width="41.25rem"
        onOk={okHandle}
        onCancel={onCancel}
        okButtonProps={okButtonProps}
        centered
      >
        <div className={styles.iconBox}>
          <Icon className={styles.icon} type="lock" theme="filled" />
        </div>
        <p className={styles.title}>输入支付密码</p>
        <p className={styles.text}>为确保您的资金安全需要输入支付密码，支付密码为六位数字的组合</p>
        <Input
          className={styles.password}
          type="password"
          placeholder="请输入支付密码"
          value={pwd}
          onChange={changePassWord}
        />
    </Modal>
  )
};

export default inject('loginStore')(observer(ExcangeModal));
