import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal } from 'components/common'
import { Input, message } from 'antd';
import { exBeansToMoney, exMoneyToBeans, thousandsSplit } from 'utils/common';
import styles from './index.less';

const BeansToMoney = ({ visible,  beans, onCancel, onOk, loading }) => {
  const [exBeansNum, setExBeansNum] = useState('');
  const [exMoney, setExMoney] = useState('');

  const exBeans = () => {
    if (exBeansNum < 3) {
      return message.error('输入咖啡豆太少，无法兑换');
    }
    if (!exBeansNum || !exMoney || exMoney === '0.00') {
      return message.error('请输入需要兑换的咖啡豆或者公益金');
    }
    onOk({
      amount: Number(exMoney),
      beans: Number(exBeansNum)
    }).then(() => {
      closeModal();
    });
  }
  const closeModal = () => {
    onCancel();
    setExBeansNum('');
    setExMoney('');
  }
  const checkIfNum = (value, hasPoint = true) => {
    const reg = hasPoint ? /^-?(0|[1-9][0-9]*)(\.[0-9]{0,2})?$/ : /^-?(0|[1-9][0-9]*)([0-9]*)?$/;
    return (!isNaN(value) && reg.test(value)) || value === ''
  }
  const changeBeansNum = (evt) => {
    let { value } = evt.target;
    if (checkIfNum(value, false)) {
      if (value > beans) {
        value = beans;
      }
      setExBeansNum(value);
      const money = exBeansToMoney(value);
      setExMoney(money);
    }
  }
  const maxMoney = exBeansToMoney(beans);
  const changeMoney = (evt) => {
    let { value } = evt.target;
    if (checkIfNum(value)) {
      let beansNum = exMoneyToBeans(value);
      if (value > maxMoney) {
        value = maxMoney;
        beansNum = beans;
      }
      setExMoney(value);
      setExBeansNum(beansNum);
    }
  }
  const exchangeAll = () => {
    setExBeansNum(beans);
    const money = exBeansToMoney(beans);
    setExMoney(money);
  }
  return (
    <Modal
      visible={visible}
      title="兑换公益金"
      text="每 300 粒咖啡豆可兑换1元"
      width="41.25rem"
      okText="确认兑换"
      onCancel={onCancel}
      onOk={exBeans}
      okButtonProps={{ loading } }>
      <div>
        <div className={styles.box}>
          <div className={styles.item}>
            <label>咖啡豆</label>
            <Input
              placeholder="请输入需要兑换的咖啡豆"
              value={exBeansNum}
              onChange={changeBeansNum}
              />
            <div className={styles.tip}>
              <span>
                共
                <span className={styles.info}>{thousandsSplit(beans, 0)|| 0} 粒</span>
                咖啡豆
              </span>
              <span
                className={styles.exAllBtn}
                onClick={exchangeAll}
              >
                全部兑换
              </span>
            </div>
          </div>
          <span className={styles.equal}>=</span>
          <div className={styles.item}>
            <label>公益金</label>
            <Input
              placeholder="请输入需要兑换的公益金"
              value={exMoney}
              onChange={changeMoney}
            />
            <div className={styles.tip}>
              <span>
                最多可兑换<span className={styles.info}>{exBeansToMoney(beans)}元</span>
              </span>
            </div>
          </div>
        </div>
        <hr className={styles.hr} />
      </div>
    </Modal>
  );
}

export default observer(BeansToMoney);
