import Login from 'containers/Login';
import {
  Order,
  Exchange,
  ExHistory,
  Goods,
  OrderDetail
} from 'containers/store';
import {
  ArticleManage,
  MixinPublish,
  PureImgPublish
} from 'containers/article';
import {
  OnlineLive,
} from 'containers/live';
import {
  ActivityManage,
  ActivityPublish,
  RegisterAudit,
  ActivityData,
} from 'containers/activity';
import {
  OrgInfo,
  Account,
  Donation,
  Notice,
  // EventManage,
  // EventPublish,
  FAQ,
  Message,
} from 'containers/org';


export default [
  {
    path: '/',
    title: '登录',
    component: Login,
    exact: true,
  },
  {
    path: '/login',
    title: '登录',
    component: Login,
    exact: true,
  },
  {
    path: '/store/order',
    title: '爱心订单',
    authIds: [2],
    component: Order,
    exact: true,
  },
  {
    path: '/store/order/detail/:orderId',
    title: '爱心订单详情',
    authIds: [2],
    component: OrderDetail,
    exact: true,
    backPath: '/store/order',
    backTitle: '返回爱心订单'
  },
  {
    path: '/store/exchange',
    title: '兑换',
    authIds: [3],
    component: Exchange,
    exact: true,
  },
  {
    path: '/store/exchange/history',
    title: '咖啡豆兑换明细',
    authIds: [3],
    backPath: '/store/exchange',
    backTitle: '返回兑换',
    component: ExHistory,
    exact: true,
  },
  {
    path: '/store/goods',
    title: '在售商品',
    authIds: [4],
    component: Goods,
    exact: true,
  },
  {
    path: '/article/manage',
    title: '内容管理',
    authIds: [6],
    component: ArticleManage,
    exact: true,
  },
  {
    path: '/article/publish/mixin',
    title: '发表文章',
    authIds: [7],
    component: MixinPublish,
    exact: true,
  },
  {
    path: '/article/modify/mixin/:articleId',
    title: '修改文章',
    authIds: [6],
    backPath: '/article/manage',
    backTitle: '返回内容管理',
    component: MixinPublish,
    exact: true,
  },
  {
    path: '/article/publish/imgs',
    title: '发表纯图文章',
    authIds: [8],
    component: PureImgPublish,
    exact: true,
  },
  {
    path: '/article/modify/imgs/:articleId',
    title: '修改文章',
    authIds: [6],
    backPath: '/article/manage',
    backTitle: '返回内容管理',
    component: PureImgPublish,
    exact: true,
  },
  {
    path: '/live/onlineLive',
    title: '线上直播',
    authIds: [6],
    component: OnlineLive,
    exact: true,
  },
  {
    path: '/activity/manage',
    title: '活动管理',
    authIds: [17],
    component: ActivityManage,
    exact: true,
  },
  {
    path: '/activity/publish',
    title: '新建活动',
    authIds: [18],
    component: ActivityPublish,
    exact: true,
  },
  {
    path: '/activity/audit/:id',
    title: '报名审核',
    authIds: [17],
    backPath: '/activity/manage',
    backTitle: '返回活动管理',
    component: RegisterAudit,
    exact: true,
  },
  {
    path: '/activity/data/:id',
    title: '活动数据',
    authIds: [17],
    backPath: '/activity/manage',
    backTitle: '返回活动管理',
    component: ActivityData,
    exact: true,
  },
  {
    path: '/activity/modify/:id',
    title: '编辑活动',
    authIds: [17],
    backPath: '/activity/manage',
    backTitle: '返回活动管理',
    component: ActivityPublish,
    exact: true,
  },
  {
    path: '/org/info',
    title: '信息设置',
    authIds: [10],
    component: OrgInfo,
    exact: true,
  },
  {
    path: '/org/account',
    title: '账户管理',
    authIds: [11],
    component: Account,
    exact: true,
  },
  {
    path: '/org/donation',
    title: '捐赠管理',
    authIds: [12],
    component: Donation,
    exact: true,
  },
  {
    path: '/org/notice',
    title: '机构公告',
    authIds: [13],
    component: Notice,
    exact: true,
  },
  // v1.3暂时下掉机构动态模块
  // {
  //   path: '/org/event',
  //   title: '机构动态',
  //   authIds: [14],
  //   component: EventManage,
  //   exact: true,
  // },
  // {
  //   path: '/org/event/publish',
  //   title: '发表机构动态',
  //   authIds: [14],
  //   backPath: '/org/event',
  //   backTitle: '返回机构动态',
  //   component: EventPublish,
  //   exact: true,
  // },
  // {
  //   path: '/org/event/modify/:eventId',
  //   title: '修改动态',
  //   authIds: [14],
  //   backPath: '/org/event',
  //   backTitle: '返回机构动态',
  //   component: EventPublish,
  //   exact: true,
  // },
  {
    path: '/org/FAQ',
    title: '常见问题',
    authIds: [15],
    component: FAQ,
    exact: true,
  },
  {
    path: '/org/message',
    title: '消息通知',
    component: Message,
    exact: true,
  },
  {
    path: '*',
  },
];
