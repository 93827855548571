import React from 'react';
import { Title } from 'components/activity/common';

import styles from './index.less';

function Flow() {
  const flows = [
    {name: '活动信息填写', icon: 'first'},
    {name: '活动设置', icon: 'second'},
    {name: '活动审核', icon: 'third'},
    {name: '发布成功', icon: 'fourth'},
  ];
  return (
    <React.Fragment>
      <Title text="发布流程" />
      <div className={styles.wrap}>
        <div className={styles.itemBox}>
          {flows.map((flow, index) => {
            const isLast = index === flows.length - 1;
            return (
              <React.Fragment key={index}>
                <div className={styles.item}>
                  <span className={styles[`icon_${flow.icon}`]}></span>
                  <span className={styles.name}>{flow.name}</span>
                </div>
                {!isLast && <span className={styles.line}></span>}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Flow;