import { message } from 'antd';
import initStore from 'utils/initStore';
import blobToBase64 from 'utils/blobToBase64';
import { userApi } from 'api';
import history from 'router/history';

const LoginStore = initStore({
  state() {
    return {
      // 判断用户信息是否获取完成
      userInfoIsReady: false,
      userMenu: [],
      menuIds: [],
      graphicCode: null,
      // 用户信息
      // token由前端通过header发送给后端，暂时token不会刷新，保存在localStorage
      userInfo: {},
    };
  },
  actions: {
    /**
     * 获取用户信息及菜单权限
     */
    requestGetUserInfo() {
      return new Promise((resolve, reject) => {
        userApi.getUserInfo()
        .then(resp => {
          this.requestGetUserMenu()
            .then(menus => {
              this.userMenu = menus;
              this.initMenuIds(menus);
              this.userInfo = resp.data.data;
              this.userInfoIsReady = true;
              resolve(resp);
              // 判断账号是否为官方账号
              this.requestGetOfficialAuthors()
                .then(officialAuthors => {
                  this.userInfo.isOfficialAuthor = officialAuthors.includes(this.userInfo.id);
                });
            })
            .catch((err) => {
              reject(err)
            });
        })
        .catch(error => {
          message.error({
            content: '获取用户信息失败，请重新登录',
            onClose: () => {
              history.toLoginPage();
            },
          });
          console.log(error, '<===获取用户信息失败');
          reject(error);
        });
      });
    },
    /**
     * 判断用户是否是官方账号
     */
    requestGetOfficialAuthors() {
      return new Promise((resolve, reject) => {
        userApi.getOfficialAuthors()
          .then(resp => {
            resolve(resp.data.data.officialAuthors);
          })
          .catch(err => {
            console.log(err, '<===获取官方账号失败');
            reject(err);
          });
      })
    },
    /**
     * 判断用户是否已设置支付密码
     */
    requestJudgePayPwd() {
      return new Promise((resolve, reject) => {
        userApi.getUserInfo()
          .then(resp => {
            const { hasPayPwd } = resp.data.data;
            if (hasPayPwd) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    // 用户权限
    requestGetUserMenu() {
      return new Promise((resolve, reject) => {
        userApi.getUserMenu()
          .then(resp => {
            const data = resp.data.data || [];
            resolve(data);
          })
          .catch(err => {
            console.log(err, '<===获取用户角色失败');
            const msg = err.response.data.msg || '获取用户角色失败';
            message.error(msg);
            reject(err);
          });
      });
    },
    // 获取图形验证码
    requestGetGraphicCode(phone) {
      return new Promise((resolve, reject) => {
        const params = {
          mobile: phone,
          smsTypeEnum: 'ORGANIZE_LOGIN',
        };
        userApi.getGraphicCode(params)
        .then(resp => {
          blobToBase64(resp.data, (base64Graphic) => {
            this.graphicCode = base64Graphic;
            resolve();
          });
        })
        .catch(error => {
          console.log(error, '<===获取验证码失败');
          const { msg } = error.response.data;
          message.error(msg || '获取图形验证码失败');
          reject(error);
        });
      });
    },
    // 获取短信验证码
    requestGetCode(phone, pictureCode) {
      // 是否需要弹出图形验证码
      let popup = false;
      return new Promise((resolve, reject) => {
        const postData = {
          phone,
          pictureCode,
          smsTypeEnum: 'ORGANIZE_LOGIN',
        };
        userApi.getCode(postData)
        .then(() => {
          resolve(popup);
        })
        .catch(error => {
          console.log(error, '<===获取验证码失败');
          const { code, msg } = error.response.data;
          // 400111: 发送验证码过于频繁，请稍后再试 -> 需要验证图形验证码
          if (code === 400111) {
            popup = true;
            return resolve(popup);
          // 400127: 图形验证码错误 -> 重新获取图形验证码
          } else if (code === 400127) {
            this.requestGetGraphicCode(phone);
          }
          message.error(msg || '获取验证码失败');
          reject(error);
        });
      });
    },
    /**
     * 登录
     */
    requestLogin(loginWay, data) {
      return new Promise((resolve, reject) => {
        userApi.login(loginWay, data)
          .then(resp => {
            const { token } = resp.data.data;
            localStorage.setItem('token', token);
            this.requestGetUserInfo();
            resolve(resp);
          })
          .catch(error => {
            console.log(error, '<===登录失败');
            reject(error);
          });
      });
    },
    /**
     * 退出
     */
    requestLogout() {
      return new Promise((resolve, reject) => {
        userApi.logout()
          .then(resp => {
            this.resetState();
            localStorage.removeItem('token');
            message.success('退出登录成功');
            resolve(resp);
          })
          .catch(error => {
            console.log(error, '<===退出登录失败');
            reject(error);
          });
      });
    },
    initMenuIds(menus) {
      menus.forEach(menu => {
        this.menuIds.push(menu.id);
        if (menu.menuChildren && menu.menuChildren.length > 0) {
          this.initMenuIds(menu.menuChildren);
        }
      });
    },
    // 权限校验
    checkAuth(authId) {
      // 如果菜单没有配置authId，则默认为不校验权限
      if (!authId) {
        return true;
      }
      return this.menuIds.includes(authId);
    }
  },
});

export default new LoginStore();
