import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Input, DatePicker, Select, Cascader } from 'antd';
import moment from 'moment';
import { Title, FormItem } from 'components/activity/common';
import EmptyControl from '../common/EmptyControl';
import chinaJSON from 'utils/china';

import styles from './index.less';

const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;

function Info({ form, data = {} }) {
  const typeOptions = [
    {label: '志愿者招募类', value: 'VOLUNTEER'},
    {label: '线上课程类', value: 'ON_LINE_CLS'},
    {label: '线下分享类', value: 'OFF_LINE_SHARE'},
    {label: '其他', value: 'OTHER'},
  ];
  const {
    getFieldDecorator,
    getFieldValue,
    getFieldError,
  } = form;
  const handleLocationChange = (val, option) => {
    form.setFieldsValue({
      locationCode: option.map(item => item.code),
    })
  }
  const handleTimeChange = () => {
    const registerTime = getFieldValue('registerTime');
    if (registerTime) {
      setTimeout(() => {
        form.validateFields(['registerTime']);
      })
    }
  }
  return (
    <React.Fragment>
      <Title text="活动信息" />
      <div className={styles.wrapper}>
        <FormItem label="活动标题" error={getFieldError('title')}>
          {getFieldDecorator('title', {
            initialValue: data.title || '',
            rules: [
              { required: true, message: '活动标题必填' },
            ],
          })(<Input
            className={classnames({
              [styles.input]: true,
              [styles.error]: getFieldError('title'),
            })}
            placeholder="请输入活动标题"
          />)}
        </FormItem>
        <FormItem label="活动时间" error={getFieldError('time')}>
          {getFieldDecorator('time', {
            initialValue: data.startTime ? [
              moment(data.startTime * 1000),
              moment(data.expireTime * 1000)
            ] : null,
            rules: [
              { required: true, message: '活动时间必填' },
            ],
          })(<RangePicker
            className={classnames({
              [styles.time]: true,
              [styles.error]: getFieldError('time'),
            })}
            onChange={handleTimeChange}
            disabledDate={currentDate => {
              if (currentDate) {
                return currentDate.isBefore(Date.now(), 'day');
              }
            }}
            showTime={{
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
            }}
            placeholder={['开始时间', '结束时间']}
          />)}
        </FormItem>
        <FormItem label="活动人数" error={getFieldError('limit')}>
          {getFieldDecorator('limit', {
            initialValue: data.applyLimit === undefined ? null : data.applyLimit,
            rules: [
              { required: true, message: '活动人数必填' },
              { validator: (rule, val) => /^\d+$/.test(val), message: '只能输入0或正整数' },
            ],
          })(<Input
            className={classnames({
              [styles.input]: true,
              [styles.error]: getFieldError('limit'),
            })}
            placeholder="请输入限额，0表示不限名额"
          />)}
        </FormItem>
        <FormItem label="活动类型" style={{marginBottom: 0}}>
          {getFieldDecorator('type', {
            initialValue: data.type || 'VOLUNTEER',
          })(<Select style={{width: 300}}>
            {typeOptions.map((option, optionIndex) => (
              <Option key={optionIndex} value={option.value}>{option.label}</Option>
            ))}
          </Select>)}
        </FormItem>
        {getFieldValue('type') === 'OFF_LINE_SHARE' && <div className={styles.addrBox}>
          <div className={styles.addrFormItemBox}>
            <FormItem label="活动地址" error={getFieldError('location')}>
              {getFieldDecorator('location', {
                initialValue: data.province ? [data.province, data.city, data.district] : null,
                rules: [
                  { required: true, message: '地址必填' },
                ]
              })(
                <Cascader
                  className={classnames(styles.cascader, {
                    [styles.error]: getFieldError('location'),
                  })}
                  fieldNames={{label: 'name', value: 'name'}}
                  onChange={handleLocationChange}
                  options={chinaJSON}
                />
              )}
            </FormItem>
            {getFieldDecorator('locationCode', {
              initialValue: data.provinceCode ? [data.provinceCode, data.cityCode, data.districtCode] : null,
            })(<EmptyControl />)}
            <FormItem className={styles.addr} error={getFieldError('addr')}>
              {getFieldDecorator('addr', {
                initialValue: data.street || '',
                rules: [
                  { required: true, message: '详细地址必填' },
                ],
              })(<Input
                className={classnames({
                  [styles.input]: true,
                  [styles.error]: getFieldError('addr'),
                })}
                placeholder="请输入详细地址（XXX街道XX号XXX幢XX层XX号）"
              />)}
            </FormItem>
          </div>
        </div>}
      </div>
    </React.Fragment>
  )
}

export default observer(Info);
