import { observer } from 'mobx-react';
import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Input } from 'antd';

import styles from './index.less';
const { TextArea } = Input;

const Introduce = ({data, requestModifyMyInfo}) => {
  const [type, setType] = useState('info');
  const [value, setValue] = useState(data.introduce);
  const updateType = (domType) =>{
    setType(domType)
    setValue(data.introduce)
  }
  const saveIntroduce = () => {
    requestModifyMyInfo({ introduce: value }, 'introduce')
      .then(()=>{
        setType('info')
      })
  }
  const changeValue = (evt) => {
    setValue(evt.target.value.trim())
  }
  if (type === 'edit') {
    return (
      <React.Fragment>
        <TextArea
          maxLength={200}
          placeholder="请输入自我介绍，字数不超过200个字符"
          className={styles.textarea}
          value={value}
          onChange={changeValue}></TextArea>
        <Button
          className={classnames(styles.btn, styles.save)}
          onClick={saveIntroduce}
        >保存修改</Button>
      </React.Fragment>
    )
  }
  return (
  <React.Fragment>
    <div className={styles.introduce}>
      {data.introduce || '暂无机构介绍'}
    </div>
    <Button className={styles.btn} onClick={updateType.bind(null, 'edit')}>修改介绍</Button>
  </React.Fragment>
  )
}

export default observer(Introduce);
