import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Table as AntdTable } from 'antd';
import { modifyMoney } from 'utils/common'

import styles from './index.less';

function Table({ columns , pagination, ...restProps}) {
  // 表格数据项为空时展示为'/'
  const nullCellText = '/';
  columns.forEach(column => {
    const { render } = column;
    if (render) {
      column.render = (value, record, index) => {
        const renderText = render(value, record, index);
        if (renderText || renderText === 0) {
          if (renderText || renderText ===0) {
            return renderText;
          }
        }
        // 部分字段需要显示空而不是/
        if (renderText === null) {
          return null;
        }
        return nullCellText;
      }
    } else {
      column.render = (value, record, recordIndex) => {
        if (column.renderType === 'money') {
          return modifyMoney(value) || nullCellText
        }
        if (column.renderType === 'index') {
          const { current, pageSize } = pagination;
          return (current - 1) * pageSize + recordIndex + 1;
        }
        if (value || value === 0) {
          return value;
        }
        return nullCellText;
      }
    }
  })
  const finalProps = {
    columns,
    pagination,
    ...restProps,
  };
  const { showHeader, actionAlign = 'center' } = restProps;
  return (
    <div
      className={classnames(styles.wrap, {
        [styles.noHeaderWrap]: !showHeader,
        [styles[`action-${actionAlign}`]]: true,
      })}>
      <AntdTable {...finalProps} />
    </div>
  );
}

Table.defaultProps = {
  showHeader: true,
}

export default observer(Table);
