import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Button } from 'antd';

import styles from './index.less';

function OperateBtn({ show, text, className, ...restProps }) {
  if (!show) {
    return null;
  }
  return (
    <Button
      className={classnames(styles.operateBtn, className)}
      {...restProps}
    >
      {text}
    </Button>
  );
}

OperateBtn.defaultProps = {
  show: false,
}

export default observer(OperateBtn);
