import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

import styles from './index.less';

function CancelAudit(props) {
  return (
    <Modal
      width="41.25rem"
      okText="确定"
      cancelText="取消"
      {...props}
      destroyOnClose
    >
      <h2 className={styles.title}>确定取消审核？</h2>
      <p className={styles.text}>取消后需重新提交审核，确认取消？</p>
      <hr className={styles.horHr} />
    </Modal>
  );
}

export default observer(CancelAudit);
