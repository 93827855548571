import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Radio } from 'antd';

import { Clipper, Modal } from 'components/common';
import FormWrapper from '../common/FormWrapper';
import Previewer from './Previewer';

import styles from './index.less';

/**
 * finished：完成
 * success: 成功
 * failed: 失败
 * process: 上传中
 */
const initPreviewImgs = (defaultImgs) => {
  return defaultImgs.map((img, index) => ({
    id: index,
    status: 'finished',
    step: 100,
    uploadUrl: img,
    previewImg: img,
  }));
}
function Cover({ form, defaultValue = [], multi, ratio }) {
  const [
    previewImgs,
    setPreviewImgs
  ] = useState(initPreviewImgs(defaultValue));
  const [
    clipImg,
    setClipImg,
  ] = useState(null);
  const clipperRef = useRef();
  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
  } = form;
  const handleShowClipper = (src) => {
    return new Promise((resolve) => {
      setClipImg(src);
      window.onClipSuccess = resolve;
    })
  }
  const handleClip = () => {
    window.onClipSuccess(clipperRef.current.clippedResult);
    window.onClipSuccess = undefined;
    setClipImg(null);
  }
  const handleLimitChange = (evt) => {
    const { value } = evt.target;
    // 将删除的图片标记为deleted
    previewImgs.forEach((previewItem, index) => {
      if (index >= value) {
        previewItem.status = 'deleted';
      }
    });
    const nextPreviewImgs = previewImgs.slice(0, value);
    setPreviewImgs(nextPreviewImgs);
    setFieldsValue({
      covers: nextPreviewImgs.map(item => item.uploadUrl),
    });
  }
  const initLimit = (coverLen) => {
    if (!multi) {
      return 1;
    }
    if (coverLen) {
      if (coverLen > 1) {
        return 3;
      }
      return 1;
    }
    return 0;
  }
  const handlePreviewChange = (value) => {
    setPreviewImgs(value.slice());
  }
  const initCoverNumLimit = initLimit(defaultValue && defaultValue.length);
  const coverNumLimit = getFieldValue('coverNumLimit') || initCoverNumLimit;
  return (
    <div className={classnames({
      [styles.singleRow]: !multi,
    })}>
      {getFieldDecorator('covers', {
        initialValue: previewImgs.map(item => item.uploadUrl),
        rules: [
          {validator: (rule, val) => val.length === getFieldValue('coverNumLimit'), message: `封面图片必须为${coverNumLimit}张`},
        ],
      })(
        <FormWrapper />
      )}
      <div className={styles.wrap}>
        <span className={styles.required}>*</span>
        <span className={styles.title}>封面</span>
        {getFieldDecorator('coverNumLimit', {
          initialValue: initCoverNumLimit,
        })(
        <Radio.Group
          onChange={handleLimitChange}
          style={{display: multi ? 'inline-block' : 'none'}}
        >
          <Radio value={1}>单图</Radio>
          <Radio value={3}>三图</Radio>
          <Radio value={0}>无图</Radio>
        </Radio.Group>)}
      </div>
      <Previewer
        form={form}
        className={classnames({
          [styles.single]: !multi,
        })}
        ratio={ratio}
        onChange={handlePreviewChange}
        showClipper={handleShowClipper}
        value={previewImgs}
      />
      <Modal
        visible={!!clipImg}
        title="请剪裁您的图片"
        onCancel={() => setClipImg(null)}
        destroyOnClose
        okText="裁剪"
        onOk={handleClip}
      >
        <Clipper
          ref={clipperRef}
          src={clipImg}
          aspect={ratio.x / ratio.y}
        />
      </Modal>
    </div>
  );
}

export default observer(Cover);
