import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/common';

function RefusedReason({ reason, ...modalProps}) {
  return (
    <Modal
      width="41.25rem"
      title="拒绝原因"
      text={reason}
      {...modalProps}
      footer={null}
      destroyOnClose
    />
  );
}

export default observer(RefusedReason);
