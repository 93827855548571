import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Input, Select, DatePicker } from 'antd';

import { Tab, SearchButton } from 'components/common';
import {
  ARTICLE_TYPE,
  ARTICLE_FILTER_STATE,
} from 'api/enum';

import styles from './index.less';

const { Option } = Select;
const { RangePicker } = DatePicker;

function Filter({
  keyword = '',
  date = ['', ''],
  type = '',
  state = '',
  onSearch = () => {},
}) {
  const [keywordVal, setKeywordVal] = useState(keyword);
  const [dateVal, setDateVal] = useState(date);
  const [typeVal, setTypeVal] = useState(type);
  const [filterState, setFilterState] = useState(state);

  const typeOptions = ARTICLE_TYPE.toOptions();
  const filterStateOptions = ARTICLE_FILTER_STATE.toOptions();
  const tabOptions = [
    {label: '全部', value: ARTICLE_FILTER_STATE.key('全部状态')},
    {label: '未发表', value: ARTICLE_FILTER_STATE.key('未发表')},
    {label: '审核中', value: ARTICLE_FILTER_STATE.key('审核中')},
    {label: '已发表', value: ARTICLE_FILTER_STATE.key('已发表')},
  ];
  const handleKeywordChange = (evt) => {
    const { value } = evt.target;
    setKeywordVal(value);
  }
  const handleDateChange = (date) => {
    setDateVal(date);
  }
  const handleTypeChange = (value) => {
    setTypeVal(value);
  }
  const handleFilterStateChange = (value) => {
    setFilterState(value);
  }
  const handleSearch = () => {
    const formData = {
      keyword: keywordVal,
      date: dateVal,
      type: typeVal,
      state: filterState,
    };
    onSearch(formData);
  }
  const handleTabChange = (value) => {
    setFilterState(value);
    const formData = {
      keyword: keywordVal,
      date: dateVal,
      type: typeVal,
      state: value,
    };
    onSearch(formData);
  }
  // 遍历生成下拉选项
  const renderOptions = (options) => {
    return options.map((option, optionIndex) => {
      return (
        <Option
          key={optionIndex}
          value={option.value}
        >
          {option.label}
        </Option>
      );
    });
  }
  console.log(state, '---state');
  return (
    <React.Fragment>
      <div className={styles.form}>
        <Input
          className={styles.searchInput}
          placeholder="搜索文章关键字"
          onChange={handleKeywordChange}
          value={keywordVal}
        />
        <RangePicker
          className={styles.datePicker}
          placeholder={['发表开始时间', '发表结束时间']}
          onChange={handleDateChange}
          value={dateVal}
        />
        <Select
          className={styles.select}
          onChange={handleTypeChange}
          value={typeVal}
        >
          {renderOptions(typeOptions)}
        </Select>
        <Select
          className={styles.select}
          onChange={handleFilterStateChange}
          value={filterState}
        >
          {renderOptions(filterStateOptions)}
        </Select>
        <SearchButton onClick={handleSearch} />
      </div>
      {/* tab */}
      <div className={styles.tab}>
        <Tab
          options={tabOptions}
          onChange={handleTabChange}
          value={state}
        />
      </div>
    </React.Fragment>
  );
}

export default observer(Filter);
