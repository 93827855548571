import React from 'react';
import { observer } from 'mobx-react';
import { List as CommonList } from 'components/common';
import { Title, RegisterState } from 'components/activity/common';
import {
  formatTimestamp,
}  from 'components/activity/common/utils';

import styles from './index.less';

function List(props) {
  const { actionRender, ...restProps } = props;
  const listProps = {
    columns: [
      {
        key: 'no',
        title: '序号',
        dataIndex: 'no',
        width: '6%',
        renderType: 'index',
      },
      {
        key: 'phone',
        title: '手机号',
        dataIndex: 'phone',
        width: '12%',
      },
      {
        key: 'idcard',
        title: '身份证号',
        dataIndex: 'idcard',
        width: '17%',
      },
      {
        key: 'name',
        title: '姓名',
        dataIndex: 'name',
        width: '10%',
      },
      {
        key: 'ctime',
        title: '报名时间',
        dataIndex: 'ctime',
        width: '12%',
        render: formatTimestamp,
      },
      {
        key: 'state',
        title: '审核状态',
        dataIndex: 'state',
        width: '11%',
        render(value) {
          return <RegisterState value={value} />;
        },
      },
      {
        key: 'auditTime',
        title: '审核时间',
        dataIndex: 'auditTime',
        width: '12%',
        render: formatTimestamp,
      },
      {
        key: 'operation',
        title: '操作',
        dataIndex: 'operation',
        width: '20%',
        render: actionRender,
      },
    ],
    rowKey: 'id',
    actionAlign: 'center',
    ...restProps,
  }
  return (
    <div className={styles.wrapper}>
      <Title className={styles.title} text="报名者信息" />
      <CommonList {...listProps} />
    </div>
  );
}

export default observer(List);