import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import styles from './index.less';

function Tab({ options, onChange, value }) {
  const handleTabChange = (tabVal) => {
    if (tabVal !== value) {
      onChange(tabVal);
    }
  }
  // 遍历生成tab
  const renderTabItems = () => {
    return options.map((tabItem, tabItemIndex) => {
      return (
        <div
          key={tabItemIndex}
          className={classnames(styles.tabItem, {
            [styles.activeTabItem]: tabItem.value === value,
          })}
          onClick={handleTabChange.bind(null, tabItem.value)}
        >
          {tabItem.label}
        </div>
      );
    });
  }
  return (
    <div className={styles.wrap}>
      {renderTabItems()}
    </div>
  );
}

export default observer(Tab);
