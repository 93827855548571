// import { message } from 'antd';
import initStore from 'utils/initStore';
import { storeApi } from 'api';

const OrderStore = initStore({
  state() {
    return {
      filter: {
        startPrice: '',
        endPrice: '',
        goodsName: '',
        orderNo: '',
      },
      list: {
        loading: false,
        error: null,
        data: [],
      },
      pager: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      orderDetail: {},
      orderDetailDict: {},
    };
  },
  actions: {
    requestLoveOrder() {
      const {
        filter,
        pager,
      } = this;
      const { pageNo, pageSize } = pager;
      const params = {
        pageNo,
        pageSize,
        ...filter,
      }
      this.list.loading = true;
      storeApi.getLoveOrder(params)
        .then(({ data })=>{
          this.list.data = data.data;
          this.list.error = null;
          this.pager.total = data.total;
          this.list.loading = false;
        })
        .catch(err => {
          console.log(err, '<===获取爱心订单列表失败');
          this.list.data = [];
          this.list.error = err;
          this.list.loading = false;
        });
    },
    requestOrderDetail(orderId) {
      const params = {
        id: orderId,
      }
      storeApi.getLoveOrderDetail(params)
        .then(({ data }) => {
          console.log(data)
          this.orderDetail = data.data;
          this.orderDetailDict = data.dict;
        })
        .catch(() => {
          this.orderDetail = {};
          this.orderDetailDict = {};
        });
    }
  },
});

export default new OrderStore();
