import React from 'react';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import { OperateBtn } from 'components/common';
import Upload from '../../common/Upload';

import styles from './index.less';

function Content({ onChange, showClipper, onFileChange, value, limit }) {
  let dragIndex = null;
  

  const handleRemoveItem = (itemIndex) => {
    value.splice(itemIndex, 1);
    onChange(value);
  }
  const handleDragStart = (index, evt) => {
    evt.target.classList.add(styles.onDrag);
    dragIndex = index;
  }
  const handleDragEnd = (evt) => {
    evt.target.classList.remove(styles.onDrag);
  }
  const handleDrop = (index, evt) => {
    evt.preventDefault();
    evt.target.classList.remove(styles.onDragEnter);
    if (dragIndex !== null) {
      const source = value[dragIndex];
      value[dragIndex] = value[index];
      value[index] = source;
      onChange(value);
    }
  }
  const handleDragEnter = (evt) => {
    evt.preventDefault();
    evt.target.classList.add(styles.onDragEnter);
  }
  const preventDefault = (evt) => {
    evt.preventDefault();
  }
  const handleDragLeave = (evt) => {
    evt.target.classList.remove(styles.onDragEnter);
  }
  const handleDescChange = (previewItem, evt) => {
    const { value: descVal } = evt.target;
    previewItem.desc = descVal;
    onChange(value);
  }
  return (
    <React.Fragment>
      <div className={styles.content}>
        {value.map((item, itemIndex) => (
          <div
            key={item.id}
            className={styles.itemBox}
            onDragEnter={handleDragEnter}
            onDragOver={preventDefault}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop.bind(null, itemIndex)}
          >
            <div className={styles.infoBox}>
              <span className={styles.index}>{itemIndex + 1}</span>
              <div className={styles.imgBox}>
                {item.status === 'failed' && (
                  <div className={styles.failedBox}>
                    <span className={styles.failedIcon} />
                    <span className={styles.failedText}>上传失败，请重新上传</span>
                    <Upload
                      id={item.id}
                      showClipper={showClipper}
                      onChange={onFileChange}
                    >
                      <Button className={styles.failedBtn}>
                        重新上传
                      </Button>
                    </Upload>
                  </div>
                )}
                <img src={item.previewImg} alt="" />
              </div>
              <div className={styles.textAreaBox}>
                <Input.TextArea
                  className={styles.textArea}
                  maxLength={200}
                  onChange={handleDescChange.bind(null, item)}
                  value={item.desc}
                  placeholder="请输入图片说明（200字内）"
                />
                <span className={styles.byteCount}>{item.desc.length}/200</span>
              </div>
            </div>
            <div className={styles.btnBox}>
              <Upload
                id={item.id}
                showClipper={showClipper}
                onChange={onFileChange}
              >
                <OperateBtn
                  show={true}
                  text="更改"
                  className={styles.mr10}
                />
              </Upload>
              <OperateBtn
                show={true}
                onClick={handleRemoveItem.bind(null, itemIndex)}
                text="删除"
              />
              <OperateBtn
                show={true}
                text="拖动排序"
                onDragStart={handleDragStart.bind(null, itemIndex)}
                onDragEnd={handleDragEnd}
                draggable
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles.incBox}>
        <Upload
        showClipper={showClipper}
          onChange={onFileChange}
          len={value.length}
          limit={limit}
        >
          <Button
            type="primary"
            className={styles.incBtn}
          >
            添加图片
          </Button>
        </Upload>
        <span className={styles.incTip}>支持JPG/PNG格式，大小不要超过1M，最多上传20张</span>
      </div>
    </React.Fragment>
  );
}

export default observer(Content);
