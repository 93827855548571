import { matchPath } from "react-router";

import routes from 'router/routes';


/**
 * 在全局路由中查找匹配pathname的路由项
 * @param {string} pathname 要匹配的路由路径
 * @return {object|null} 匹配的路由项
 */
export const getMatchRoute = (pathname) => {
  for (let idx = 0; idx < routes.length; idx++) {
    if (matchPath(pathname, routes[idx])) {
      return routes[idx];
    }
  }
  return null;
}
