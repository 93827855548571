import React from 'react';
import { observer, inject } from 'mobx-react';

import { Tab } from 'components/common';

import styles from './index.less';

const StatusFilter = ({ goodsStore }) => {
  const {
    updateState,
    requestGetGoodsStock,
    filter
  } = goodsStore;
  const changeStatus = (value) => {
    updateState('filter.status', value);
    updateState('pager.pageNo', 1);
    requestGetGoodsStock()
  }
  const config = [
    {
      label: '全部',
      value: null
    },
    {
      label: '出售中',
      value: 'UP'
    },
    {
      label: '仓库中',
      value: 'DOWN'
    }
  ];
  return (
    <div className={styles.filterBox}>
      <Tab
        options={config}
        onChange={changeStatus}
        value={filter.status}
      />
    </div>
  )
};

export default inject('goodsStore')(observer(StatusFilter));