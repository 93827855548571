import React from 'react';
import { observer } from 'mobx-react';
import KeyValue from './KeyValue';
import styles from './index.less';
function OrderInfo({ data, dict }) {
  const kvConfig = [
    {
      label: '昵称',
      key: 'username',
    },
    {
      label: '用户类型',
      key: 'userAuth',
      render: (value) => {
        const { userAuth = {} } = dict;
        return userAuth[value] || value;
      },
    },
    {
      label: '所在地区',
      key: 'area',
    },
    {
      label: '手机号',
      key: 'phone',
    }
  ]
  const createKVList = () => {
    const output = [];
    kvConfig.forEach((item)=>{
      output.push(
        <KeyValue
          key={item.key}
          label={item.label}
          value={data[item.key]}
          render={item.render}
        />
      )
    })
    return output;
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.orderStatus}>
        <label>当前状态</label>
        <span>交易成功</span>
      </div>
      <div className={styles.info}>
        {createKVList()}
        <hr className={styles.hr}/>
      </div>
    </div>
  )
}
export default observer(OrderInfo)
