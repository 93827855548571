import React from 'react';
import { inject, observer } from 'mobx-react';

import history from 'router/history';
import {
  Content,
} from 'components/common';
import {
  Detail,
} from 'components/article/common';
import {
  Statistics,
  Filter,
  List,
  CancelAudit,
  RefusedReason,
  Offline,
  Delete,
} from 'components/article/articleManage';

import {
  ARTICLE_PUBLISH_STATUS,
  ARTICLE_AUDIT_STATUS,
} from 'api/enum';

@inject('articleManageStore', 'articlePublishStore')
@observer
class ArticleManage extends React.Component {
  state = {
    // 详情弹框
    visibleOfDetail: false,
    // 取消审核弹框
    visibleOfCancelAudit: false,
    // 拒绝原因弹窗
    visibleOfRefusedReason: false,
    // 下线弹框
    visibleOfOffline: false,
    // 删除弹框
    visibleOfDelete: false,
    // 是否是批量操作
    batch: false,
  }
  componentDidMount() {
    const {
      articleManageStore
    } = this.props;
    const {
      requestGetArticleStatistics,
      requestGetArticleList,
    } = articleManageStore;
    // 获取统计
    requestGetArticleStatistics();
    // 获取文章列表
    requestGetArticleList();
  }
  // 勾选文章,checked=true则勾选,否则移除
  onCheck = (checked, record) => {
    const {
      checkedArticles,
      updateState,
    } = this.props.articleManageStore;
    let newCheckedArticles;
    if (checked) {
      newCheckedArticles = [...checkedArticles, record];
    } else {
      newCheckedArticles = checkedArticles.filter(item => item.id !== record.id);
    }
    updateState('checkedArticles', newCheckedArticles);
  }
  // 判断删除的文章是否已勾选,若已勾选,需要从勾选列表移除
  removeDelArticleFromCheck = (article) => {
    const {
      checkedArticles,
    } = this.props.articleManageStore;
    const isChecked = checkedArticles.some(item => item.id === article.id);
    if (isChecked) {
      this.onCheck(false, article);
    }
  }
  // 更新store中当前操作的文章
  updateArticleInStore = (article) => {
    const {
      updateState,
    } = this.props.articleManageStore;
    updateState('currOptArticle', article);
  }
  // 发表文章
  handlePublish = (article) => {
    const {
      articlePublishStore,
      articleManageStore,
    } = this.props;
    const {
      requestPublishArticle,
    } = articlePublishStore;
    const {
      filterParams,
    } = articleManageStore;
    const { id } = article;
    requestPublishArticle(id)
      .then(() => {
        const effectNum = (
          filterParams.publishState === ARTICLE_PUBLISH_STATUS.key('未发表')
        ) ? 1 : 0;
        this.refreshArticleList(effectNum);
      });
  }
  // 更新查看详情弹框
  updateDetailState = (visible = false) => {
    this.setState({
      visibleOfDetail: visible,
    });
  }
  // 点击查看详情
  handleViewDetail = (article) => {
    const {
      requestGetArticleDetail,
    } = this.props.articleManageStore;
    const { id } = article;
    requestGetArticleDetail(id)
      .then(() => {
        this.updateDetailState(true);
      });
  }
  // 更新取消审核弹框
  updateCancelAuditState = (visible = false) => {
    this.setState({
      visibleOfCancelAudit: visible,
    });
  }
  // 点击取消审核
  handleCancelAudit = (article) => {
    this.updateArticleInStore(article);
    this.updateCancelAuditState(true);
  }
  // 文章取消审核
  cancelAuditArticle = () => {
    const {
      filterParams,
      currOptArticle,
      requestCancelAudit,
    } = this.props.articleManageStore;
    const { id } = currOptArticle;
    requestCancelAudit(id)
      .then(() => {
        this.updateCancelAuditState();
        this.updateArticleInStore(null);
        // 如果tab筛选状态为审核中,列表页码需要重新计算
        const effectNum = (
          filterParams.auditState === ARTICLE_AUDIT_STATUS.key('审核中')
        ) ? 1 : 0;
        this.refreshArticleList(effectNum);
      });
  }
  // 编辑文章
  handleModify = (article) => {
    const {
      id,
      newsType,
    } = article;
    // 纯图文章
    if (newsType === 4) {
      history.push(`/article/modify/imgs/${id}`);
    // 图文混排
    } else {
      history.push(`/article/modify/mixin/${id}`);
    }
  }
  // 更新拒绝原因弹框
  updateRefusedReasonState = (visible = false) => {
    this.setState({
      visibleOfRefusedReason: visible,
    });
  }
  // 点击查看拒绝原因
  handleViewReason = (article) => {
    const {
      requestGetRefusedReason,
    } = this.props.articleManageStore;
    const { id } = article;
    requestGetRefusedReason(id)
      .then(() => {
        this.updateRefusedReasonState(true);
      });
  }
  // 更新下线弹框状态
  updateOfflineState = (visible = false) => {
    this.setState({
      visibleOfOffline: visible,
    });
  }
  // 点击下线
  handleOffline = (article) => {
    this.updateArticleInStore(article);
    this.updateOfflineState(true);
  }
  // 文章下线
  offlineArticle = () => {
    const {
      filterParams,
      currOptArticle,
      requestOfflineArticle,
    } = this.props.articleManageStore;
    const { id } = currOptArticle;
    requestOfflineArticle(id)
      .then(() => {
        this.updateOfflineState();
        this.updateArticleInStore(null);
        // 如果tab筛选状态为已发表,列表页码需要重新计算
        const effectNum = (
          filterParams.publishState === ARTICLE_PUBLISH_STATUS.key('已发表')
        ) ? 1 : 0;
        this.refreshArticleList(effectNum);
      });
  }
  // 点击上线
  handleOnline = (article) => {
    this.updateArticleInStore(article);
    this.onlineArticle();
  }
  // 文章上线
  onlineArticle = () => {
    const {
      filterParams,
      currOptArticle,
      requestOnlineArticle,
    } = this.props.articleManageStore;
    const { id } = currOptArticle;
    requestOnlineArticle(id)
      .then(() => {
        this.updateArticleInStore(null);
        // 如果tab筛选状态为已发表,列表页码需要重新计算
        const effectNum = (
          filterParams.publishState === ARTICLE_PUBLISH_STATUS.key('未发表')
        ) ? 1 : 0;
        this.refreshArticleList(effectNum);
      });
  }
  // 更新删除弹框状态
  updateDeleteState = (visible = false, batch = false) => {
    this.setState({
      visibleOfDelete: visible,
      batch,
    });
  }
  // 点击单个删除
  handleSingleDelete = (article) => {
    this.updateArticleInStore(article);
    this.updateDeleteState(true);
  }
  // 点击批量删除
  handleBatchDelete = (article) => {
    this.updateArticleInStore(article);
    this.updateDeleteState(true, true);
  }
  // 文章删除
  deleteArticle = () => {
    const {
      currOptArticle,
      checkedArticles,
      updateState,
      requestDeleteArticle,
    } = this.props.articleManageStore;
    const {
      batch,
    } = this.state;
    requestDeleteArticle(currOptArticle.id)
      .then(() => {
        // 关闭弹框
        this.updateDeleteState();
        if (!batch) {
          this.updateArticleInStore(null);
          this.removeDelArticleFromCheck();
          this.refreshArticleList(1);
        } else {
          // 清空勾选列表
          const delNum = checkedArticles.length;
          updateState('checkedArticles', []);
          this.refreshArticleList(delNum);
        }
      });
  }
  // 如果当前不在第一页,删除/修改状态后需要重新计算当前所在页码
  refreshArticleList = (effectNum) => {
    const {
      articleList,
      articlePager,
      updateState,
      requestGetArticleList,
    } = this.props.articleManageStore;
    const { index, size } = articlePager;
    let prevIndex = index;
    if (index !== 1) {
      while (effectNum >= articleList.length) {
        prevIndex--;
        effectNum -= size;
      }
    }
    updateState('articlePager.index', prevIndex);
    requestGetArticleList();
  }
  onSearch = formData => {
    const {
      updateState,
      resetState,
      requestGetArticleList,
    } = this.props.articleManageStore;
    updateState('filterParams', formData);
    resetState('articlePager');
    requestGetArticleList();
  }
  hasFilterParams = (filter) => {
    return Object.values(filter).some(val => {
      if (typeof val === 'string') {
        return val;
      }
      if (typeof val === 'object') {
        return this.hasFilterParams(val);
      }
      return val !== '';
    });
  }
  render() {
    const {
      visibleOfDetail,
      visibleOfCancelAudit,
      visibleOfRefusedReason,
      visibleOfOffline,
      visibleOfDelete,
      batch,
    } = this.state;
    const {
      articleManageStore,
    } = this.props;
    const {
      statisticsIsReady,
      statistics,
      statisticsError,
      filterParams,
      articleListIsReady,
      articleList,
      articleListError,
      articlePager,
      checkedArticles,
      articleDetail,
      refusedReason,
    } = articleManageStore;
    const statisticsProps = {
      loading: !statisticsIsReady,
      data: statistics,
      error: statisticsError,
    };
    const filterProps = {
      ...filterParams,
      onSearch: this.onSearch,
    };
    const listProps = {
      loading: !articleListIsReady,
      data: articleList,
      checkedData: checkedArticles,
      error: articleListError,
      pager: articlePager,
      params: this.hasFilterParams(filterParams),
      onCheck: this.onCheck,
      onPublish: this.handlePublish,
      onViewDetail: this.handleViewDetail,
      onViewReason: this.handleViewReason,
      onModify: this.handleModify,
      onCancelAudit: this.handleCancelAudit,
      onOffline: this.handleOffline,
      onOnline: this.handleOnline,
      onDelete: this.handleSingleDelete,
      onBatchDel: this.handleBatchDelete,
    };
    const detailProps = {
      visible: visibleOfDetail,
      onCancel: this.updateDetailState.bind(this, false),
      data: articleDetail,
    };
    const cancelAuditProps = {
      visible: visibleOfCancelAudit,
      onCancel: this.updateCancelAuditState.bind(this, false),
      onOk: this.cancelAuditArticle,
    };
    const refusedReasonProps = {
      visible: visibleOfRefusedReason,
      onCancel: this.updateRefusedReasonState.bind(this, false),
      reason: refusedReason,
    };
    const offlineProps = {
      visible: visibleOfOffline,
      onCancel: this.updateOfflineState.bind(this, false),
      onOk: this.offlineArticle,
    };
    const deleteProps = {
      visible: visibleOfDelete,
      onCancel: this.updateDeleteState.bind(this, false),
      onOk: this.deleteArticle,
      batch,
      count: checkedArticles.length,
    }
    return (
      <Content>
        <Statistics {...statisticsProps} />
        <Filter {...filterProps} />
        <List {...listProps} />
        <Detail {...detailProps} />
        <CancelAudit {...cancelAuditProps} />
        <Offline {...offlineProps} />
        <Delete {...deleteProps} />
        <RefusedReason {...refusedReasonProps} />
      </Content>
    );
  }
}
export default ArticleManage;
