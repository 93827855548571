/**
 * 应用级工具方法
 */

import appConfig from 'config/app';

// 判断是否需要先登录
export const routeRequiresLogin = (pathname) => {
  return !appConfig.notNeedLoginRoutes.some(regExp => regExp.test(pathname));
}

// 判断是否显示导航组件
export const routeRequiresNavbar = (pathname) => {
  return !appConfig.notNeedNavbarRoutes.some(regExp => regExp.test(pathname));
}
