import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { message } from 'antd';

import { commonApi } from 'api';
import {
  Upload as UploadFile,
} from 'components/common';

import styles from './index.less';

class Upload extends React.Component {
  uploadId = this.props.id || this.props.len || 0;
  intervalTimer = {};
  timeoutTimer = {};
  componentWillUnmount() {
    Object.values(this.intervalTimer).forEach(timer => {
      clearInterval(timer);
    });
    Object.values(this.timeoutTimer).forEach(timer => {
      clearTimeout(timer);
    });
  }
  onFileChange = (file) => {
    const { len, limit } = this.props;
    if (limit !== undefined && len >= limit) {
      return message.warning('可上传数量已达上限');
    }
    const reader = new FileReader();
    reader.onload = (evt) => {
      const id = this.uploadId;
      if (this.props.id === undefined) {
        this.uploadId++;
      }
      const previewItem = {
        id,
        status: 'process',
        step: 20,
        previewImg: evt.target.result,
        uploadUrl: null,
      };
      this.intervalTimer[previewItem.id] = setInterval(() => {
        if (previewItem.step < 90) {
          previewItem.step++;
          this.props.onChange(previewItem);
        } else {
          clearInterval(this.intervalTimer[previewItem.id]);
        }
      }, 1000 / 60);
      this.props.onChange(previewItem);
      const formData = new FormData();
      formData.append('upfile', file[0]);
      commonApi.uploadImg(formData, 12)
        .then(resp => {
          clearInterval(this.intervalTimer[previewItem.id]);
          const { viewPath } = resp.data.data || {};
          previewItem.uploadUrl = viewPath;
          previewItem.status = 'success';
          previewItem.step = 100;
          this.props.onChange(previewItem);
          // 2s后移除成功状态
          this.timeoutTimer[previewItem.id] = setTimeout(() => {
            if (previewItem.status !== 'deleted') {
              previewItem.status = 'finished';
              this.props.onChange(previewItem);
            }
          }, 2000);
        })
        .catch(() => {
          clearInterval(this.intervalTimer[previewItem.id]);
          previewItem.status = 'failed';
          this.props.onChange(previewItem);
        });
    }
    reader.readAsDataURL(file[0]);
  }
  render() {
    const { size, error, className, children, } = this.props;
    return (
      <UploadFile
        accept="image/*"
        className={className}
        onChange={this.onFileChange}
      >
        {children || (
          <div
            className={classnames(styles.wrap, styles[size], {
              [styles.error]: error,
            })}
          >
            <span className={styles.icon} />
            <span className={styles.info}>点击添加图片</span>
            <span className={styles.tip}>
              {size === 'small' ? (
                <React.Fragment>
                  <span>
                    支持JPG/PNG格式，
                    <br />
                    建议尺寸3:2的比例，大小不要超过1M
                  </span>
                  <br />
                  <span className={styles.warning}>图片底色不要纯白色，否则影响美观</span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  支持JPG/PNG格式，大小不要超过1M，最多上传20张图
                </React.Fragment>
              )}
              </span>
          </div>
        )}
      </UploadFile>
    );
  }
}

Upload.defaultProps = {
  size: 'small',
}

export default observer(Upload);
