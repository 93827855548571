import React from 'react';
import { observer } from 'mobx-react';

@observer
class FormWrapper extends React.Component {
  render() {
    // 该组件仅作为表单容器组件，不具备ui
    return null;
  }
}

export default FormWrapper;