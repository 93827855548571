/**
 * 构建history
 */

import {
  createBrowserHistory,
  createMemoryHistory,
} from 'history';

import appConfig from 'config/app';

const history = typeof window !== 'undefined'
  ? createBrowserHistory()
  : createMemoryHistory();

/**
 * 系统内所有跳转全部通过history实现
 */
Object.assign(history, {
  // 跳转到登录
  toLoginPage() {
    window.location.href = '/login';
  },
  // 跳转到首页
  toHomepage() {
    this.push(appConfig.homepageUrl);
  },
  // 跳转到文章列表
  toArticleList() {
    this.push('/article/manage');
  },
  // 跳转到机构动态
  toEventList() {
    this.push('/org/event');
  },
  // 跳转到发表机构动态
  toAddEventPage() {
    this.push('/org/event/publish');
  },
  // 跳转到消息通知
  toMessagePage() {
    this.push('/org/message');
  },
  // 跳转到活动管理
  toActivityManagePage(id) {
    this.push(`/activity/manage`);
  },
  // 跳转到活动编辑
  toActivityEditPage(id) {
    this.push(`/activity/modify/${id}`);
  },
  // 跳转到活动审核
  toActivityAuditPage(id) {
    this.push(`/activity/audit/${id}`);
  },
  // 跳转到活动数据
  toActivityDataPage(id) {
    this.push(`/activity/data/${id}`);
  },
});

export default history;
