import { message } from 'antd';
import initStore from 'utils/initStore';
import { orgApi } from 'api';

const EventManageStore = initStore({
  state() {
    return {
      // 筛选
      filterParams: {
        keyword: '',
        date: ['', ''],
        publishState: '',
        auditState: '',
      },
      // 列表数据
      eventListIsReady: false,
      eventList: [],
      eventListError: null,
      // 列表分页
      eventPager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.eventPager.index = current;
          this.requestGetEventList();
        },
      },
      // 当前操作的动态
      currOptEvent: null,
      // 已选中的动态
      checkedEvents: [],
      // 动态详情
      eventDetail: {},
      // 拒绝原因
      refusedReason: '',
    };
  },
  actions: {
    requestGetEventList() {
      this.eventListIsReady = false;
      const {
        keyword,
        date,
        publishState,
        auditState,
      } = this.filterParams;
      const {
        index,
        size,
      } = this.eventPager;
      const postData = {
        pageNo: index,
        pageSize: size,
        keyWord: keyword,
        startTime: date[0] && date[0].format('YYYY-MM-DD'),
        endTime: date[1] && date[1].format('YYYY-MM-DD'),
        issueState: publishState,
        approveState: auditState,
      };
      orgApi.getEventList(postData)
        .then(resp => {
          const { data } = resp.data;
          if (!data || data.length === 0) {
            return Promise.reject(new Error('暂无数据'));
          }
          this.eventListIsReady = true;
          this.eventList = data;
          this.eventListError = null;
          this.eventPager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取动态列表失败');
          this.eventListIsReady = true;
          this.eventList = [];
          this.eventListError = err;
        });
    },
    requestGetEventDetail(eventId) {
      return new Promise((resolve, reject) => {
        orgApi.getEventDetail(eventId)
          .then(resp => {
            this.eventDetail = resp.data.data[0];
            resolve();
          })
          .catch(err => {
            console.log(err, '<===获取动态详情失败');
            message.error(err.response.data.msg || '获取动态详情失败');
            reject(err);
          });
      });
    },
    requestCancelAudit(eventId) {
      return new Promise((resolve, reject) => {
        orgApi.cancelEventAudit(eventId)
          .then(() => {
            message.success('动态取消审核成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===动态取消审核失败');
            message.error(err.response.data.msg || '动态取消审核失败');
            reject(err);
          });
      });
    },
    requestGetRefusedReason(eventId) {
      return new Promise((resolve, reject) => {
        orgApi.getEventRefusedReason(eventId)
          .then(resp => {
            this.refusedReason = resp.data.data.msg;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===查看拒绝原因失败');
            message.error(err.response.data.msg || '获取拒绝原因失败');
            reject(err);
          });
      });
    },
    requestOfflineEvent(eventId) {
      return new Promise((resolve, reject) => {
        orgApi.offlineEvent(eventId)
          .then(() => {
            message.success('动态下线成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===动态下线失败');
            message.error(err.response.data.msg || '动态下线失败');
            reject(err);
          });
      });
    },
    requestDeleteEvent(eventIds) {
      return new Promise((resolve, reject) => {
        orgApi.deleteEvent(eventIds)
          .then(() => {
            message.success('动态删除成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===动态删除失败');
            message.error(err.response.data.msg || '动态删除失败');
            reject(err);
          });
      });
    },
  },
});

export default new EventManageStore();
