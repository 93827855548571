import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Input, Button, Icon, Select, DatePicker } from 'antd';

import { Tab, SearchButton } from 'components/common';
import {
  EVENT_PUBLISH_STATUS,
  EVENT_AUDIT_STATUS,
} from 'api/enum';

import styles from './index.less';

const { Option } = Select;
const { RangePicker } = DatePicker;

function Filter({
  keyword = '',
  date = ['', ''],
  publishState = '',
  auditState = '',
  onAdd = () => {},
  onSearch = () => {},
}) {
  const [keywordVal, setKeywordVal] = useState(keyword);
  const [dateVal, setDateVal] = useState(date);
  const [publishStatusVal, setPublishStatusVal] = useState(publishState);
  const [auditStatusVal, setAuditStatusVal] = useState(auditState);

  const publishStatusOptions = EVENT_PUBLISH_STATUS.toOptions();
  const auditStatusOptions = EVENT_AUDIT_STATUS.toOptions();
  const tabOptions = [
    {label: '全部', value: `${EVENT_PUBLISH_STATUS.key('全部状态')}-${EVENT_AUDIT_STATUS.key('全部状态')}`},
    {label: '未发表', value: `${EVENT_PUBLISH_STATUS.key('未发表')}-${EVENT_AUDIT_STATUS.key('全部状态')}`},
    {label: '审核中', value: `${EVENT_PUBLISH_STATUS.key('全部状态')}-${EVENT_AUDIT_STATUS.key('审核中')}`},
    {label: '已发表', value: `${EVENT_PUBLISH_STATUS.key('已发表')}-${EVENT_AUDIT_STATUS.key('全部状态')}`},
  ];
  const handleKeywordChange = (evt) => {
    const { value } = evt.target;
    setKeywordVal(value);
  }
  const handleDateChange = (date) => {
    setDateVal(date);
  }
  const handlePublishStatusChange = (value) => {
    setPublishStatusVal(value);
  }
  const handleAuditStatusChange = (value) => {
    setAuditStatusVal(value);
  }
  const toInt = (value) => {
    if (value) {
      return Number(value);
    }
    return value;
  }
  const handleTabChange = (value) => {
    const [publishStatus, auditStatus] = value.split('-');
    handlePublishStatusChange(toInt(publishStatus));
    handleAuditStatusChange(toInt(auditStatus));
    const formData = {
      keyword: keywordVal,
      date: dateVal,
      publishState: toInt(publishStatus),
      auditState: toInt(auditStatus),
    };
    onSearch(formData);
  }
  const handleSearch = () => {
    const formData = {
      keyword: keywordVal,
      date: dateVal,
      publishState: publishStatusVal,
      auditState: auditStatusVal,
    };
    onSearch(formData);
  }
  // 遍历生成下拉选项
  const renderOptions = (options) => {
    return options.map((option, optionIndex) => {
      return (
        <Option
          key={optionIndex}
          value={option.value}
        >
          {option.label}
        </Option>
      );
    });
  }
  return (
    <React.Fragment>
      <div className={styles.form}>
        <Input
          className={styles.searchInput}
          placeholder="搜索动态关键词"
          onChange={handleKeywordChange}
          value={keywordVal}
        />
        <RangePicker
          className={styles.datePicker}
          placeholder={['发表开始时间', '发表结束时间']}
          onChange={handleDateChange}
          value={dateVal}
        />
        <Select
          className={styles.select}
          onChange={handlePublishStatusChange}
          value={publishStatusVal}
        >
          {renderOptions(publishStatusOptions)}
        </Select>
        <Select
          className={styles.select}
          onChange={handleAuditStatusChange}
          value={auditStatusVal}
        >
          {renderOptions(auditStatusOptions)}
        </Select>
        <SearchButton onClick={handleSearch} />
        <Button
          className={styles.secondaryBtn}
          onClick={onAdd}
        >
          <Icon
            className={styles.plusIcon}
            type="plus-circle"
          />
          <span>添加动态</span>
        </Button>
      </div>
      {/* tab */}
      <div className={styles.tab}>
        <Tab
          options={tabOptions}
          onChange={handleTabChange}
          value={`${publishStatusVal}-${auditStatusVal}`}
        />
      </div>
    </React.Fragment>
  );
}

export default observer(Filter);
