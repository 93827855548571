import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, matchPath } from 'react-router-dom';
import classnames from 'classnames';

import menuConfig from 'config/navbar';
import history from 'router/history';

import styles from './index.less';

@inject('loginStore')
@withRouter
@observer
class AppNavbar extends React.Component {

  onNavRoute(menu) {
    history.push(menu.route);
  }

  isInHighlight(pathname, highlightRoute) {
    if (highlightRoute) {
      return highlightRoute.some(
        route => matchPath(pathname, {
          path: route,
          exact: true,
        })
      );
    }
  }

  // 根据配置遍历生成菜单
  renderMenu(menus) {
    const pathname = this.props.location.pathname;
    const { checkAuth } = this.props.loginStore;
    return menus.map((menu, menuIndex) => {
      const {
        name,
        authId,
        subMenus = [],
      } = menu;
      if (!checkAuth(authId)) {
        return null;
      }
      return (
        <div key={menuIndex} className={styles.menuItem}>
          <div className={styles.menu}>{name}</div>
          {subMenus.length > 0 && (
            <div className={styles.subMenuBox}>
              {subMenus.map((subMenu, subMenuIndex) => {
                if (!checkAuth(subMenu.authId)) {
                  return null;
                }
                return (
                  <div
                    key={subMenuIndex}
                    className={classnames(styles.subMenu, {
                      [styles.active]: matchPath(pathname, {
                        path: subMenu.route,
                        exact: true,
                      }) || this.isInHighlight(pathname, subMenu.highlightRoute),
                    }, styles[`icon_${subMenu.iconCss}`])}
                    onClick={this.onNavRoute.bind(this, subMenu)}
                  >
                    {subMenu.name}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  }
  render() {
    return (
      <div className={styles.wrap}>
        <div className={styles.logo} />
        <div className={styles.greyBg}></div>
        <div className={styles.menuBox}>
          {this.renderMenu(menuConfig.sort((a, b) => a.order - b.order))}
        </div>
      </div>
    );
  }
}

export default AppNavbar;
