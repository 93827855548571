import { observer, inject } from 'mobx-react';
import React, { useState } from 'react';
import { getPathValue } from 'pathval';
import { SmallBlock } from 'components/common';
import { Button, Popover, Icon, message } from 'antd';
import { thousandsSplit } from 'utils/common';
import ApplyModal from './ApplyModal';
import RecipientModal from './RecipientModal';
import SaveRecipientModal from './SaveRecipientModal';
import styles from './index.less';

const Balance = ({accountStore}) => {
  const {
    summary,
    applyStatus,
    requestApplyMoney,
    requestGetRecipientInfo,
    requestSaveRecipientInfo,
    requestGetSummary,
  } = accountStore;
  const data = summary.data;
  const [recipientInfo, setRecipientInfo] = useState({});
  const [applyLoading, setApplyLoading] = useState(false);
  const [visible, setVisible] = useState();
  const [showRecipientModal, setRecipientModalState] = useState(false);
  const [recipientFormData, setRecipientFormData] = useState(null);
  const [showSaveRecipientModal, setSaveRecipientModalState] = useState(false);
  const [saveRecipientLoading, setSaveRecipientLoading] = useState(false);
  const openRecipientModal = () => {
    setRecipientModalState(true);
  }
  const closeRecipientModal = () => {
    setRecipientModalState(false);
  }
  const closeModal = () => {
    setVisible(false);
  }
  const openModal = () => {
    setVisible(true);
  }
  const updateRecipientInfo = (data) => {
    setRecipientInfo(Object.assign({}, recipientInfo, data));
  }
  // 获取收款信息
  const getRecipientInfo = () => {
    setApplyLoading(true);
    requestGetRecipientInfo()
      .then(res => {
        setApplyLoading(false);
        setRecipientInfo(res.data.data || {});
        // 打开体现弹框
        openRecipientModal();
      })
      .catch(err => {
        const msg = getPathValue(err, 'response.data.msg') || '获取收款信息失败';
        message.error(msg);
        setApplyLoading(false);
      });
  }
  // 确认收款信息
  const confirmRecipient = (info) => {
    const isInfoModified = Object.keys(info).some(key => info[key] !== recipientInfo[key]);
    // 判断是否有更改收款信息
    if (isInfoModified) {
      openSaveRecipientModal(info);
    } else {
      closeRecipientModal();
      openModal();
    }
  }
  // 打开保存收款信息弹框
  const openSaveRecipientModal = (info) => {
    setRecipientFormData(info);
    setSaveRecipientModalState(true);
  }
  // 关闭保存收款信息弹框
  const closeSaveRecipientModal = () => {
    setSaveRecipientModalState(false);
  }
  // 保存收款信息
  const confirmSaveRecipient = () => {
    setSaveRecipientLoading(true);
    return requestSaveRecipientInfo(recipientFormData)
      .then(res => {
        updateRecipientInfo({
          ...recipientFormData,
          id: res.data.data,
        });
        setSaveRecipientLoading(false);
        // 关闭收款信息相关弹框
        closeSaveRecipientModal();
        closeRecipientModal();
        // 打开提现弹框
        openModal();
      })
      .catch(err => {
        setSaveRecipientLoading(false);
        const msg = getPathValue(err, 'response.data.msg') || '保存收款信息失败';
        message.error(msg);
      });
  }
  const confirmApply = (params) => {
    params.payee = recipientInfo.id;
    return new Promise((resolve) => {
      requestApplyMoney(params)
        .then(()=>{
          resolve();
          requestGetSummary();
        });
    })
  }
  return (
    <SmallBlock>
      <div className={styles.box}>
        <p className={styles.title}>公益金余额</p>
        <p className={styles.money}><span>￥</span>{thousandsSplit(data.balance)}</p>
        <Button className={styles.button} onClick={getRecipientInfo} loading={applyLoading}>申请提现</Button>
        <Popover
          placement="topLeft"
          content={(
            <div className={styles.tipBox}>
              申请提取公益金前需要先上传上次提取的公益金花费凭证，当提供的凭证金额额度累计达到上次提现金额的95%后，才能申请本次提现
              <br />
              最低申请额度500元
            </div>
          )}
        >
          <Icon
            type="question-circle"
            className={styles.questionIcon}
          />
        </Popover>
        <RecipientModal
          visible={showRecipientModal}
          loading={false}
          dataSource={recipientInfo}
          onCancel={closeRecipientModal}
          onOk={confirmRecipient}
        />
        <SaveRecipientModal
          visible={showSaveRecipientModal}
          loading={saveRecipientLoading}
          onCancel={closeSaveRecipientModal}
          onOk={confirmSaveRecipient}
        />
        <ApplyModal
          visible={visible}
          balance={data.balance}
          onCancel={closeModal}
          loading={applyStatus.loading}
          onOk={confirmApply}
        />
      </div>
    </SmallBlock>
  )
};

export default inject('accountStore')(observer(Balance));
