import { message } from 'antd';
import initStore from 'utils/initStore';
import { articleApi } from 'api';

const ArticleManageStore = initStore({
  state() {
    return {
      // 统计数据
      statisticsIsReady: false,
      statistics: null,
      statisticsError: null,
      // 筛选
      filterParams: {
        keyword: '',
        date: ['', ''],
        type: '',
        state: '',
      },
      // 列表数据
      articleListIsReady: false,
      articleList: [],
      articleListError: null,
      // 列表分页
      articlePager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.articlePager.index = current;
          this.requestGetArticleList();
        },
      },
      // 当前操作的文章
      currOptArticle: null,
      // 已选中的文章
      checkedArticles: [],
      // 文章详情
      articleDetail: {},
      // 拒绝原因
      refusedReason: '',
    };
  },
  actions: {
    requestGetArticleStatistics() {
      articleApi.getArticleStatistics()
        .then(resp => {
          const { data } = resp.data;
          if (!data) {
            return Promise.reject(new Error('暂无数据'));
          }
          this.statisticsIsReady = true;
          this.statistics = data;
          this.statisticsError = null;
        })
        .catch(err => {
          console.log(err, '<===获取文章统计数据失败');
          this.statisticsIsReady = true;
          this.statistics = null;
          this.statisticsError = err;
        });
    },
    requestGetArticleList() {
      this.articleListIsReady = false;
      const {
        keyword,
        date,
        type,
        state,
      } = this.filterParams;
      const {
        index,
        size,
      } = this.articlePager;
      const postData = {
        pageNo: index,
        pageSize: size,
        keyword,
        publishBeginTime: date[0] && (date[0].format('YYYY-MM-DD') + ' 00:00:00'),
        publishEndTime: date[1] && (date[1].format('YYYY-MM-DD') + ' 23:59:59'),
        state,
        types: type,
      };
      articleApi.getArticleList(postData)
        .then(resp => {
          const { data } = resp.data;
          if (!data || data.length === 0) {
            return Promise.reject(new Error('暂无数据'));
          }
          this.articleListIsReady = true;
          this.articleList = data;
          this.articleListError = null;
          this.articlePager.total = resp.data.total;
        })
        .catch(err => {
          console.log(err, '<===获取文章列表失败');
          this.articleListIsReady = true;
          this.articleList = [];
          this.articleListError = err;
          this.articlePager.total = 0;
        });
    },
    requestGetArticleDetail(articleId) {
      return new Promise((resolve, reject) => {
        articleApi.getArticleDetail(articleId)
          .then(resp => {
            this.articleDetail = resp.data.data;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===获取文章详情失败');
            message.error(err.response.data.msg || '获取文章详情失败');
            reject(err);
          });
      });
    },
    requestCancelAudit(articleId) {
      return new Promise((resolve, reject) => {
        articleApi.cancelAudit(articleId)
          .then(() => {
            message.success('文章取消审核成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===文章取消审核失败');
            message.error(err.response.data.msg || '文章取消审核失败');
            reject(err);
          });
      });
    },
    requestGetRefusedReason(articleId) {
      return new Promise((resolve, reject) => {
        articleApi.getRefusedReason(articleId)
          .then(resp => {
            this.refusedReason = resp.data.data.msg;
            resolve();
          })
          .catch(err => {
            console.log(err, '<===查看拒绝原因失败');
            message.error(err.response.data.msg || '获取拒绝原因失败');
            reject(err);
          });
      });
    },
    requestOfflineArticle(articleId) {
      return new Promise((resolve, reject) => {
        articleApi.offlineArticle(articleId)
          .then(() => {
            message.success('文章下线成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===文章下线失败');
            message.error(err.response.data.msg || '文章下线失败');
            reject(err);
          });
      });
    },
    requestOnlineArticle(articleId) {
      return new Promise((resolve, reject) => {
        articleApi.onlineArticle(articleId)
          .then(() => {
            message.success('文章上线成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===文章上线失败');
            message.error(err.response.data.msg || '文章上线失败');
            reject(err);
          });
      });
    },
    requestDeleteArticle(articleId) {
      return new Promise((resolve, reject) => {
        articleApi.deleteArticle(articleId)
          .then(() => {
            message.success('文章删除成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===文章删除失败');
            message.error(err.response.data.msg || '文章删除失败');
            reject(err);
          });
      });
    },
  },
});

export default new ArticleManageStore();
