import React from 'react';
import { ImageUpload } from 'components/common';
import { Title } from '../common'
import styles from './index.less';

export default class Cover extends React.Component {
  // 可上传数量
  number = 3;
  addCover = (index, url) => {
    const covers = this.props.covers.slice();
    covers[index] = url;
    const { requestModifyCovers } = this.props.orgInfoStore;
    return requestModifyCovers(covers);
  }
  removeCover = (removeIndex) => {
    const covers = this.props.covers.slice();
    covers[removeIndex] = null;
    const { requestModifyCovers } = this.props.orgInfoStore;
    return requestModifyCovers(covers);
  }
  render() {
    const { covers } = this.props;
    return (
      <div className={styles.box}>
        <Title>机构封面</Title>
        <div className={styles.content}>
          {new Array(this.number).fill(1).map((item, index) => {
            return (
              <div
                key={index}
                className={styles.uploadBox}>
                <ImageUpload
                  uploadType={17}
                  preview={covers[index]}
                  onSuccess={this.addCover.bind(this, index)}
                  onRemove={this.removeCover.bind(this, index)}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
