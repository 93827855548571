import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { Modal } from 'components/common';
import Upload from '../../common/Upload';

import styles from './index.less';

function Previewer({ form, onChange, value }) {
  const [
    currImg,
    setCurrImg,
  ] = useState(null);
  const {
    getFieldValue,
    getFieldError,
    setFieldsValue,
  } = form;
  const covers = getFieldValue('covers') || [];
  const handleRemove = (imgIndex) => {
    value[imgIndex].status = 'deleted';
    value.splice(imgIndex, 1);
    onChange(value);
    covers.splice(imgIndex, 1);
    setFieldsValue({
      covers,
    });
  }
  const handleChange = (previewItem) => {
    const { id, uploadUrl } = previewItem;
    const index = value.findIndex(item => item.id === id);
    if (index > -1) {
      value[index] = previewItem;
    } else {
      value.push(previewItem);
    }
    onChange(value);
    if (uploadUrl) {
      if (index > -1) {
        covers[index] = uploadUrl;
      } else {
        covers.push(uploadUrl);
      }
      setFieldsValue({
        covers,
      });
    }
  }
  return (
    <div className={styles.wrap}>
      <Upload
        form={form}
        limit={1}
        onChange={handleChange}
        len={value.length}
        error={getFieldError('covers')}
      />
      {value.map((item, imgIndex) => (
        <div key={imgIndex} className={styles.imgItem}>
          {item.status === 'process' && (
            <div
              className={styles.processBox}
              style={{height: `${item.step}%`}}
            >
              {item.step < 100 && <span className={styles.processStep}>{item.step}%</span>}
            </div>
          )}
          {item.status === 'success' && (
            <div className={styles.successBox}>
              <span className={styles.successIcon} />
              <span className={styles.successText}>上传成功</span>
            </div>
          )}
          {item.status === 'failed' && (
            <div className={styles.failedBox}>
              <span className={styles.failedIcon} />
              <span className={styles.failedText}>上传失败，请重新上传</span>
              <Upload
                id={item.id}
                onChange={handleChange}
              >
                <Button className={styles.failedBtn}>
                  重新上传
                </Button>
              </Upload>
            </div>
          )}
          {item.status === 'finished' && (
            <div
              className={styles.previewFooter}
              onClick={() => setCurrImg(item.previewImg)}
            >预览封面</div>
          )}
          <span
            className={styles.closeIcon}
            onClick={handleRemove.bind(null, imgIndex)}
          />
          <img src={item.previewImg} alt="" />
        </div>
      ))}
      <Modal
        visible={!!currImg}
        width="auto"
        className={styles.previewModal}
        footer={null}
        onCancel={() => setCurrImg(null)}
        centered
      >
        <img className={styles.previewImg} src={currImg} alt="" />
      </Modal>
    </div>
  );
}

export default observer(Previewer);
