import { observer, inject } from 'mobx-react';
import React from 'react';
import { Button } from 'antd';
import history from 'router/history';
import styles from './index.less';

const Info = ({ exchangeStore }) => {
  const beans = exchangeStore.remainBeans
  const gotoExHistory = () => {
    history.push('/store/exchange/history')
  }
  return (
    <div className={styles.infoBox}>
    <div className={styles.info}>
      我的咖啡豆
      <span className={styles.number}>{beans || 0}<span>粒</span></span>
    </div>
    <Button type="primary" className={styles.button} onClick={gotoExHistory}>查看咖啡豆兑换明细</Button>
  </div>
  )
};

export default inject('exchangeStore')(observer(Info));
