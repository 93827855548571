import React from 'react';
import { inject, observer } from 'mobx-react';

import { Content } from 'components/common';
import {
  Filter,
  List,
  AddNotice,
  Detail,
  Delete,
} from 'components/org/notice';

@inject('noticeStore')
@observer
class Notice extends React.Component {
  state = {
    visibleOfAddNotice: false,
    visibleOfDetail: false,
    optId: null,
    visibleOfDelete: false,
  }
  componentDidMount() {
    const {
      noticeStore,
    } = this.props;
    const {
      requestGetNoticeList,
    } = noticeStore;
    requestGetNoticeList();
  }
  updateAddNoticeState = (visible = false) => {
    this.setState({
      visibleOfAddNotice: visible,
    });
  }
  handleAddNotice = (formData) => {
    const {
      noticeStore,
    } = this.props;
    const {
      requestAddNotice,
      requestGetNoticeList,
    } = noticeStore;
    requestAddNotice(formData)
      .then(() => {
        this.updateAddNoticeState();
        requestGetNoticeList();
      });
  }
  handleSearch = (formData) => {
    const {
      noticeStore,
    } = this.props;
    const {
      updateState,
      resetState,
      requestGetNoticeList,
    } = noticeStore;
    updateState('filterParams', Object.assign({}, formData));
    resetState('noticePager');
    requestGetNoticeList();
  }
  handleStatusChange = (notice) => {
    const {
      noticeStore,
    } = this.props;
    const {
      requestModifyNoticeStatus,
      requestGetNoticeList,
    } = noticeStore;
    const putData = {
      id: notice.id,
      state: notice.state ^ 1,
    };
    requestModifyNoticeStatus(putData)
      .then(() => {
        requestGetNoticeList();
      });
  }
  updateDetailState = (visible = false) => {
    this.setState({
      visibleOfDetail: visible,
    });
  }
  handleViewDetail = (noticeId) => {
    const {
      noticeStore,
    } = this.props;
    const {
      requestGetNoticeDetail,
    } = noticeStore;
    requestGetNoticeDetail(noticeId)
      .then(() => {
        this.updateDetailState(true);
      });
  }
  updateDeleteState = (visible = false, noticeId = null) => {
    this.setState({
      visibleOfDelete: visible,
      optId: noticeId,
    });
  }
  handleDelete = (noticeId) => {
    this.updateDeleteState(true, noticeId);
  }
  deleteNotice = () => {
    const noticeId = this.state.optId;
    const {
      noticeStore,
    } = this.props;
    const {
      requestDeleteNotice,
    } = noticeStore;
    requestDeleteNotice(noticeId)
      .then(() => {
        this.updateDeleteState();
        this.refreshNoticeList(1);
      });
  }
  refreshNoticeList = (effectNum) => {
    const {
      noticeList,
      noticePager,
      updateState,
      requestGetNoticeList,
    } = this.props.noticeStore;
    const { index, size } = noticePager;
    let prevIndex = index;
    if (index !== 1) {
      while (effectNum >= noticeList.length) {
        prevIndex--;
        effectNum -= size;
      }
    }
    updateState('noticePager.index', prevIndex);
    requestGetNoticeList();
  }
  hasFilterParams = (filter) => {
    return Object.values(filter).some(val => {
      if (typeof val === 'string') {
        return val;
      }
      if (typeof val === 'object') {
        return this.hasFilterParams(val);
      }
      return val !== '';
    });
  }
  render() {
    const {
      visibleOfAddNotice,
      visibleOfDetail,
      visibleOfDelete,
    } = this.state;
    const {
      filterParams,
      noticeListIsReady,
      noticeList,
      noticeError,
      noticePager,
      noticeDetail,
    } = this.props.noticeStore;
    const filterProps = {
      onAdd: this.updateAddNoticeState.bind(this, true),
      onSearch: this.handleSearch,
    };
    const listProps = {
      loading: !noticeListIsReady,
      data: noticeList,
      error: noticeError,
      pager: noticePager,
      params: this.hasFilterParams(filterParams),
      onStatusChange: this.handleStatusChange,
      onViewDetail: this.handleViewDetail,
      onDelete: this.handleDelete,
    };
    const addNoticeProps = {
      visible: visibleOfAddNotice,
      onOk: this.handleAddNotice,
      onCancel: this.updateAddNoticeState.bind(this, false),
    };
    const detailProps = {
      visible: visibleOfDetail,
      onCancel: this.updateDetailState.bind(this, false),
      content: noticeDetail.contents,
    };
    const deleteProps = {
      visible: visibleOfDelete,
      onOk: this.deleteNotice,
      onCancel: this.updateDeleteState.bind(this, false),
    };
    return (
      <Content>
        <Filter {...filterProps} />
        <List {...listProps} />
        <AddNotice {...addNoticeProps} />
        <Detail {...detailProps} />
        <Delete {...deleteProps} />
      </Content>
    );
  }
}
export default Notice;
