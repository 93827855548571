import React from 'react';
import { observer } from 'mobx-react';
import { Content } from 'components/common'
import {
  Filter,
  List
} from 'components/store/exHistory'

@observer
class ExHistory extends React.Component {
  render() {
    return (
      <Content>
        <Filter />
        <List />
      </Content>
    );
  }
}
export default ExHistory;