import { message } from 'antd';
import initStore from 'utils/initStore';
import { toJS } from 'mobx';
import { storeApi } from 'api';
import { getPathValue } from 'pathval';
import moment from 'moment';

const GoodsStore = initStore({
  state() {
    return {
      list: {
        loading: true,
        error: false,
        data: [],
      },
      pager: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        goodsName: undefined,
        startCTime: undefined,
        endCTime: undefined,
        startPrice: undefined,
        endPrice: undefined,
        status: 'UP',
      },
      // 上架下架loading
      loadingObj: {
      }
    };
  },
  actions: {
    setNoData () {
      this.list.loading = false;
      this.list.error = true;
      this.list.data = [];
      this.pager.total = 0;
    },
    // 查询自由在售商品
    requestGetGoodsStock() {
      const { pageNo, pageSize } = this.pager;
      const params = {
        ...this.filter,
        pageNo,
        pageSize
      }
      params.startCTime = params.startCTime ? moment(params.startCTime).format('YYYY-MM-DD HH:MM:SS') : undefined;
      params.endCTime = params.endCTime ? moment(params.endCTime).format('YYYY-MM-DD HH:MM:SS') : undefined;
      this.list.loading = true;
      storeApi.getGoodsStock(params)
      .then(({data})=>{
        if (data.data.length < 1) {
          this.setNoData()
        } else {
          this.list.loading = false;
          this.list.error = false;
          this.list.data = data.data;
          this.pager.total = data.total;
        }
      })
      .catch(() => {
        this.setNoData()
      })
    },
    getGoodsAfter() {
      if (this.filter.status) {
        const itemLen = this.list.data.length;
        const { pageNo } = this.pager;
        const pageIndex = itemLen === 1 && pageNo > 1 ? pageNo - 1 : pageNo
        this.pager.pageNo = pageIndex;
      }
      this.requestGetGoodsStock();
    },
    //　上架下架商品
    requestUpOrDownGoods({goodsId, status}) {
      // set(this.loadingObj, {[`goods${goodsId}`]: true})
      // this.loadingObj.set(`goods${goodsId}`, true)
      this.loadingObj = Object.assign(toJS(this.loadingObj), {
        [`goods${goodsId}`]: true 
      })
      storeApi.upOrDownGoods({goodsId, status})
        .then(()=>{
          message.info('操作成功！')
          this.getGoodsAfter();
          this.loadingObj = Object.assign(toJS(this.loadingObj), {
            [`goods${goodsId}`]: false 
          })
        })
        .catch((error)=>{
          this.loadingObj = Object.assign(toJS(this.loadingObj), {
            [`goods${goodsId}`]: false 
          })
          const msg = getPathValue(error, 'response.data.msg') || '操作失败';
          message.error(msg)
        })
    }
  },
});

export default new GoodsStore();
