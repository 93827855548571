import { observer, inject } from 'mobx-react';
import React from 'react';
import { loadingAndError } from 'components/hoc'
import styles from './index.less';
import NearestApply from './NearestApply';
import Balance from './Balance';
import ExtractTotal from './ExtractTotal';
import Beans from './Beans';

const Summary = ({accountStore, onTabChange}) => {
  const data = accountStore.summary.data;
  const {
    openPublicApplyModal
  } = accountStore;
  return (
    <div className={styles.box}>
      <NearestApply
        data={data}
        openPublicApplyModal={openPublicApplyModal}
        onTabChange={onTabChange}
      />
       {/* 公益金余额 */}
      <Balance
        data={data}
        onTabChange={onTabChange}
      />
       {/* 累计提取公益金 */}
      <ExtractTotal
        data={data}
      />
      <Beans
        onTabChange={onTabChange}
      />
    </div>
  )
};

export default 
  inject('accountStore')(
    loadingAndError((props)=> ({
      loading: props.accountStore.summary.loading,
      error: props.accountStore.summary.error,
      className: styles.msgBox
    }))(
      observer(Summary)
    )
  );