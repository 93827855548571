import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Content, OperateBtn } from 'components/common';
import { HorizontalHr } from 'components/activity/common';
import {
  Info,
  List,
  Refuse,
} from 'components/activity/registerAudit';

@withRouter
@inject('activityManageStore')
@observer
class RegisterAudit extends React.Component {
  state = {
    visibleOfRefuse: false,
    registerInfo: null,
  }
  componentDidMount() {
    this.getData();
  }
  componentWillUnmount() {
    this.props.activityManageStore.resetState('registerAudit');
  }
  getActivityId = () => {
    // 获取地址栏带过来的活动id
    const {
      match,
    } = this.props;
    const { id } = match.params;
    if (id) {
      return Number(id);
    }
    return null;
  }
  getData = () => {
    const {
      requestGetAuditInfo,
      requestGetAuditList,
    } = this.props.activityManageStore;
    const activityId = this.getActivityId();
    // 获取统计信息
    requestGetAuditInfo(activityId);
    // 获取列表信息
    requestGetAuditList(activityId);
  }
  /**
   * 审核通过
   * @param registerInfo 报名者
   */
  handlePassAudit = (registerInfo) => {
    const { id } = registerInfo;
    const {
      requestPassAudit,
    } =  this.props.activityManageStore;
    requestPassAudit(id)
      .then(() => {
        // 刷新数据
        this.getData();
      })
  }
  /**
   * 审核驳回
   * @param registerInfo 报名信息
   */
  handleRefuseAudit = (registerInfo) => {
    // 打开驳回弹框并保存驳回的报名信息
    this.setState({
      visibleOfRefuse: true,
      registerInfo,
    });
  }
  /**
   * 关闭驳回弹框
   */
  closeRefuse = () => {
    this.setState({
      visibleOfRefuse: false,
      registerInfo: null,
    });
  }
  /**
   * 确定驳回
   */
  confirmRefuse = (formData) => {
    const { id } = this.state.registerInfo;
    const {
      requestGetAuditList,
      requestRefuseAudit,
    } =  this.props.activityManageStore;
    return new Promise((resolve, reject) => {
      requestRefuseAudit(id, formData)
        .then(() => {
          // 刷新列表并关闭弹框

          requestGetAuditList();
          this.closeRefuse();
          resolve();
        })
        .catch(reject);
    });
  }
  render() {
    const {
      visibleOfRefuse,
    } = this.state;
    const {
      registerAudit,
    } = this.props.activityManageStore;
    const {
      infoLoading,
      infoData,
      infoError,
      listLoading,
      listData,
      listError,
      listPager,
    } = registerAudit;
    const infoProps = {
      loading: infoLoading,
      data: infoData,
      error: infoError,
    };
    const listProps = {
      loading: listLoading,
      data: listData,
      error: listError,
      pager: listPager,
      actionRender: (val, record) => {
        const { state } = record;
        const waitAudit = [1].includes(state);
        if (!waitAudit) {
          return '/';
        }
        return (
          <React.Fragment>
            <OperateBtn
              text="审核通过"
              show={waitAudit}
              onClick={this.handlePassAudit.bind(this, record)}
            />
            <OperateBtn
              text="驳回"
              show={waitAudit}
              onClick={this.handleRefuseAudit.bind(this, record)}
            />
          </React.Fragment>
        );
      }
    };
    const refuseProps = {
      visible: visibleOfRefuse,
      onCancel: this.closeRefuse,
      onOk: this.confirmRefuse,
    };
    return (
      <Content>
        <Info {...infoProps} />
        <HorizontalHr />
        <List {...listProps} />
        <Refuse {...refuseProps} />
      </Content>
    );
  }
}

export default RegisterAudit;