import { message } from 'antd';
import initStore from 'utils/initStore';
import { activityApi } from 'api';

const ActivityManageStore = initStore({
  state() {
    return {
      filterParams: null,

      activityListLoading: false,
      activityList: [],
      activityListError: null,

      activityListPager: {
        index: 1,
        size: 10,
        total: 0,
        onChange: (current) => {
          this.activityListPager.index = current;
          this.requestGetActivityList(this.filterParams);
        },
      },
      // 报名审核
      registerAudit: {
        id: null,
        infoLoading: true,
        infoData: null,
        infoError: null,

        listLoading: true,
        listData: [],
        listError: null,

        listPager: {
          index: 1,
          size: 10,
          total: 0,
          onChange: (current) => {
            this.registerAudit.listPager.index = current;
            this.requestGetAuditList(this.registerAudit.id);
          },
        },
      },
      // 活动数据
      activityData: {
        id: null,
        infoLoading: true,
        infoData: null,
        infoError: null,

        listLoading: true,
        listData: [],
        listError: null,

        listPager: {
          index: 1,
          size: 10,
          total: 0,
          onChange: (current) => {
            this.activityData.listPager.index = current;
            this.requestGetDataList(this.activityData.id);
          },
        },
      }
    };
  },
  actions: {
    /**
     * 请求获取活动列表
     * @param {object|null} filterParams 筛选参数
     * @return 
     */
    requestGetActivityList(filterParams = null) {
      this.activityListLoading = true;
      this.filterParams = filterParams;
      const { index, size } = this.activityListPager;
      const requestParams = Object.assign({}, this.filterParams, {
        pageNo: index,
        pageSize: size,
      })
      activityApi.getActivityList(requestParams)
        .then(res => {
          const { data } = res.data;
          if (!data || data.length === 0) {
            return Promise.reject(new Error('暂无数据'));
          }
          this.activityList = data;
          this.activityListPager.total = res.data.total;
          this.activityListError = null;
          this.activityListLoading = false;
        })
        .catch(err => {
          this.activityList = [];
          this.activityListPager.total = 0;
          this.activityListError = err;
          this.activityListLoading = false;
          console.log(err);
        });
    },
    /**
     * 请求取消审核
     * @param {number} id 
     * @return {Promise}
     */
    requestCancelAudit(id) {
      return new Promise((resolve, reject) => {
        activityApi.cancelAudit(id)
          .then(() => {
            message.success('活动取消审核成功');
            resolve();
          })
          .catch(err => {
            console.log(err, '<===活动取消审核失败');
            message.error(err.response.data.msg || '活动取消审核失败');
            reject(err);
          });
      });
    },
    /**
     * 获取报名审核信息
     * @param {number} id 
     */
    requestGetAuditInfo(id) {
      this.registerAudit.infoLoading = true;
      if (id) {
        this.registerAudit.id = id;
      }
      activityApi.getDataInfo(this.registerAudit.id)
        .then(res => {
          this.registerAudit.infoData = res.data.data;
          this.registerAudit.infoError = null;
          this.registerAudit.infoLoading = false;
        })
        .catch(err => {
          this.registerAudit.infoData = null;
          this.registerAudit.infoError = err;
          this.registerAudit.infoLoading = false;
        });
    },
    /**
     * 获取报名审核列表
     * @param {number} id 
     */
    requestGetAuditList(id) {
      this.registerAudit.listLoading = true;
      if (id) {
        this.registerAudit.id = id;
      }
      const {
        index,
        size,
      } = this.registerAudit.listPager;
      const params = {
        pageNo: index,
        pageSize: size,
      };
      activityApi.getRegisterList(this.registerAudit.id, params)
        .then(res => {
          this.registerAudit.listData = res.data.data;
          this.registerAudit.listPager.total = res.data.total;
          this.registerAudit.listError = null;
          this.registerAudit.listLoading = false;
        })
        .catch(err => {
          this.registerAudit.listData = [];
          this.registerAudit.listError = err;
          this.registerAudit.listLoading = false;
        });
    },
     /**
     * 获取活动数据信息
     * @param {number} id 
     */
    requestGetDataInfo(id) {
      this.activityData.infoLoading = true;
      if (id) {
        this.activityData.id = id;
      }
      activityApi.getDataInfo(this.activityData.id)
        .then(res => {
          this.activityData.infoData = res.data.data;
          this.activityData.infoError = null;
          this.activityData.infoLoading = false;
        })
        .catch(err => {
          this.activityData.infoData = null;
          this.activityData.infoError = err;
          this.activityData.infoLoading = false;
        });
    },
    /**
     * 获取活动数据列表
     * @param {number} id 
     */
    requestGetDataList(id) {
      this.activityData.listLoading = true;
      if (id) {
        this.activityData.id = id;
      }
      const {
        index,
        size,
      } = this.activityData.listPager;
      const params = {
        pageNo: index,
        pageSize: size,
      };
      activityApi.getRegisterList(this.activityData.id, params)
        .then(res => {
          this.activityData.listData = res.data.data;
          this.activityData.listPager.total = res.data.total;
          this.activityData.listError = null;
          this.activityData.listLoading = false;
        })
        .catch(err => {
          this.activityData.listData = null;
          this.activityData.listError = err;
          this.activityData.listLoading = false;
        });
    },
    /**
     * 审核通过
     * @param {number} id 
     */
    requestPassAudit(id) {
      return new Promise((resolve, reject) => {
        activityApi.passAudit({
          id,
          pass: true,
        })
          .then(() => {
            message.success('审核通过成功');
            resolve();
          })
          .catch(err => {
            console.log(err);
            message.error(err.response.data.msg || '审核通过失败');
            reject();
          });
      });
    },
    /**
     * 审核拒绝
     * @param {number} id 
     * @param {Object} data 驳回的原因
     */
    requestRefuseAudit(id, data) {
      return new Promise((resolve, reject) => {
        const postData = Object.assign({
          id,
          pass: false,
        }, data)
        activityApi.passAudit(postData)
          .then(() => {
            message.success('驳回成功');
            resolve();
          })
          .catch(err => {
            console.log(err);
            message.error(err.response.data.msg || '驳回失败');
            reject();
          });
      });
    }
  },
});

export default new ActivityManageStore();