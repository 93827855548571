import React from 'react';
import { observer } from 'mobx-react';

import { thousandsSplit } from 'utils/common';
import {
  List as CommonList,
} from 'components/common';

function List({
  loading,
  data,
  error,
  pager={},
}) {
  const listProps = {
    columns: [
      {
        key: 'username',
        title: '昵称',
        dataIndex: 'username',
        width: '25%',
      },
      {
        key: 'mobile',
        title: '手机号',
        dataIndex: 'mobile',
        width: '25%',
      },
      {
        key: 'ctime',
        title: '捐赠时间',
        dataIndex: 'ctime',
        width: '25%',
      },
      {
        key: 'amount',
        title: '捐赠数额',
        dataIndex: 'amount',
        width: '25%',
        render(val) {
          return thousandsSplit(val, 0);
        }
      },
    ],
    rowKey: (record, index) => index,
    loading,
    dataSource: data,
    error,
    pager,
  };
  return (
    <CommonList
      {...listProps}
    />
  );
}

export default observer(List);
